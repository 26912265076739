import React from 'react';

const StoreLoaderPopup = ({ open, onClose, onSubmit, title, value, onValueChange, placeholder, buttonText,onNameChange,name }:any) => {
  if (!open) return null;

  return (

     <dialog
      open={open}
      className="auto scroll modal large-padding blue-grey1"
      style={{
        width: "40%",
      }}
    >
      <div className="credential-header">
        <h5 className="small"> {title}</h5>
        <button className="transparent square"        onClick={onClose} 
>
          <i>close</i>
        </button>
      </div>
<div className='top-padding'>
       <div className="field border label">
        <input
          type="text"
          value={name}
          onChange={onNameChange} 
          style={{ width: '100%' }}
        />
       <label className='small-opacity'> Name</label>

      </div>
      <div className="field border label">
        <input
          type="text"
          value={value}
          onChange={onValueChange}
          
          style={{ width: '100%' }}
        />
       <label className='small-opacity'> {placeholder}</label>

      </div>
     

       <div className="flex right-align">
        <button onClick={onSubmit} disabled={!value}>
          {buttonText}
        </button>
      </div>
      </div>
    </dialog>
  );
};

export default StoreLoaderPopup;