import { useEffect, useState } from "react";
import { getComponentCredentials } from "./CredentialData";

interface CredentialModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleCredentialDetails: (credential: Credential) => void;
}

interface CredentialInput {
  label: string;
  name: string;
  type: string;
  placeholder?: string;
  description?: string;
  optional?: boolean;
}

interface Credential {
  label: string;
  name: string;
  version: number;
  description?: string;
  inputs: CredentialInput[];
  icon: string;
}

const CredentialModal = ({
  isOpen,
  onClose,
  handleCredentialDetails,
}: CredentialModalProps) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [data, setData] = useState<Credential[]>([]);
  const [filteredData, setFilteredData] = useState<Credential[]>([]);

  useEffect(() => {
    const getAllComponentCredentials = async () => {
      const response = await getComponentCredentials();
      setData(response);
      setFilteredData(response);
    };

    getAllComponentCredentials();
  }, []);

  useEffect(() => {
    const filteredData = data.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(filteredData);
  }, [searchQuery]);

  const handleCredential = (credential: Credential) => {
    onClose();
    handleCredentialDetails(credential!);
  };

  return (
    <>
      <dialog
        open={isOpen}
        onClose={onClose}
        className="auto surface"
        style={{ width: "50%", overflow: "hidden" }}
      >
        <div className="credential-header" style={{ alignItems: "center" }}>
          <h5 className="small">Add New Credential</h5>
          <button className="transparent square" onClick={onClose}>
            <i>close</i>
          </button>
        </div>
        <div className="px-10">
          <div className="field prefix border small">
            <i>search</i>
            <input
              type="text"
              placeholder="Search Credentials"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.currentTarget.value)}
            />
          </div>
        </div>
        <div style={{ height: "60vh", overflow: "auto", borderRadius: "0px" }}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "10px",
              borderRadius: "10px",
            }}
          >
            {filteredData.map((credential: Credential, index: number) => (
              <>
                <article
                  key={index}
                  onClick={() => handleCredential(credential)}
                  className="env-card-wrapper cursor-pointer"
                >
                  <div
                    className="group align-items-center"
                    style={{
                      gap: "10px",
                      padding: "10px",
                    }}
                  >
                    <img
                      className="small"
                      src={credential?.icon!}
                      style={{
                        objectFit: "contain",
                      }}
                    />
                    <h6
                      className="small align-items-center"
                      style={{
                        marginBlockStart: "0px",
                      }}
                    >
                      {credential.label}
                    </h6>
                  </div>
                </article>
              </>
            ))}
          </div>
        </div>

        {/* </div> */}
      </dialog>
    </>
  );
};

export default CredentialModal;
