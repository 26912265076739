import React, { useCallback, useEffect, useRef, useState } from "react";
import { Handle, Position, useUpdateNodeInternals } from "reactflow";
import { IconChevronDown, IconInfoCircle } from "@tabler/icons-react";
import { isValidConnection } from "./helper";
import { CustomWidthTooltip } from "./CustomTooltip";
import { documentStoresAPI, getAllEnvs } from "../../lib/APIService";
import { getAllStores } from "../../lib/CanvasData";

interface InputAnchor {
  type?: string;
  id: string;
  label: string;
  optional?: boolean;
  description?: string;
}

interface InputParam {
  additionalParams: any;
  name: string;
  type: string;
  label?: string;
  placeholder?: string;
  description?: string;
  options?: { label: string; value: string }[];
  default?: any;
  warning?: string;
  acceptVariable?: boolean;
  hidden?: boolean;
  optional?: boolean;
  loadMethod?: string;
  min?: number;
  max?: number;
  step?: any;
}

interface NodeInputHandlerProps {
  inputAnchor?: InputAnchor;
  inputParam?: InputParam;
  data: any;
  disabled?: boolean;
  viewContent: boolean;
  setIsValid: (valid: boolean) => void;
}

interface LoadMethods {
  [key: string]: () => Promise<any>;
}

const loadMethods: LoadMethods = {
  listStores: async () => {
    const response = await documentStoresAPI();
    const data = response.map((item: any) => ({
      label: item.name,
      value: item.id,
    }));
    return data;
  },
  getEnvs: async () => {
    const response = await getAllEnvs();
    const data = response.map((item: any) => ({
      label: item.env_name,
      value: item.env_name,
    }));
    return data;
  },
};

const NodeInputHandler: React.FC<NodeInputHandlerProps> = ({
  inputAnchor,
  inputParam,
  data,
  disabled,
  viewContent,
  setIsValid,
}) => {
  const updateNodeInternals = useUpdateNodeInternals();
  const [position, setPosition] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [inputValue, setInputValue] = useState<any>(
    data.inputs[inputParam?.name ?? ""] ?? ""
  );
  const [options, setOptions] = useState<any[]>([]);
  const isViewerMode =
    (data?.category?.toLowerCase() === "agents" ||
      data?.category?.toLowerCase() === "supervisors") ??
    false;
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      const offsetTop = ref.current.offsetTop;
      const clientHeight = ref.current.clientHeight;
      setPosition(offsetTop + clientHeight / 2);
      updateNodeInternals(data.id);
    }
  }, [data.id, ref, updateNodeInternals]);

  useEffect(() => {
    setInputValue(data.inputs[inputParam?.name ?? ""] ?? "");
  }, [data.inputs, inputParam?.name]);

  const validateFields = useCallback(() => {
    let isValid = true;

    if (data && data.inputParams) {
      data.inputParams.forEach((input: any) => {
        if (!input.optional && !data.inputs[input.name]) {
          isValid = false;
        }
      });
    }
    setIsValid(isValid);
  }, [data.inputs, data.inputParams]);

  const loadData = useCallback(async (loadMethod: string) => {
    if (loadMethods[loadMethod]) {
      return await loadMethods[loadMethod]();
    }
    throw new Error(`Load method ${loadMethod} not found`);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (inputParam?.loadMethod) {
        try {
          const response = await loadData(inputParam.loadMethod);
          setOptions(response);
        } catch (error) {
          console.error("Failed to fetch options:", error);
        }
      }
    };

    fetchData();
  }, [inputParam?.loadMethod, loadData]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setInputValue(value);
    data.inputs[inputParam?.name ?? ""] = value;
    updateNodeInternals(data.id);
  };

  const handleChange = (value: string) => {
    const list =
      inputParam?.type === "credential" || inputParam?.type === "asyncOptions"
        ? options
        : inputParam?.options;
    const selectedOption = list?.find((option) => option.value === value);
    if (selectedOption) {
      data.inputs[inputParam?.name!] = selectedOption.value;
    } else {
      data.inputs[inputParam?.name!] = "";
    }
    setInputValue(value);
    if (inputParam?.type === "credential") {
      data[inputParam?.name ?? ""] = value;
    }
    updateNodeInternals(data.id);
    validateFields();
  };

  const handleNumberChange = (value: number | "") => {
    const stringValue = value === "" ? "" : value.toString();
    setInputValue(stringValue);
    data.inputs[inputParam?.name!] = stringValue;
    updateNodeInternals(data.id);
    validateFields();
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const CustomHandler = ({ inputAnchor }: any) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          position: "relative",
          marginBottom: "10px",
        }}
      >
        <CustomWidthTooltip title={inputAnchor.type}>
          <Handle
            type="target"
            position={Position.Left}
            id={inputAnchor.id}
            style={{
              left: "-20px",
              height: 10,
              width: 10,
              backgroundColor: "rgb(33, 150, 243)",
            }}
            isValidConnection={(connection: any) =>
              isValidConnection(connection, data?.reactFlowInstance)
            }
          />
        </CustomWidthTooltip>
        <div style={{ padding: "2px" }}>
          <strong>
            {inputAnchor.label}
            {!inputAnchor.optional && <span style={{ color: "red" }}>*</span>}
            {inputAnchor.description && (
              <a>
                <i className="canvas-tooltip">info</i>
                <div className="tooltip top">{inputAnchor.description}</div>
              </a>
            )}
          </strong>
        </div>
      </div>
    );
  };

  return (
    <div
      ref={ref}
      style={{ width: "100%", whiteSpace: "normal", wordBreak: "break-word" }}
    >
      {inputAnchor && <CustomHandler inputAnchor={inputAnchor} />}
      {inputParam && viewContent && (
        <>
          <div style={{ padding: "2px" }}>
            <strong>
              {inputParam.label}
              {!inputParam.optional && <span style={{ color: "red" }}>*</span>}
              {inputParam.description && (
                <a>
                  <i className="canvas-tooltip">info</i>
                  <div className="tooltip top">{inputParam.description}</div>
                </a>
              )}
            </strong>

            {isViewerMode ? (
              <div>
                {inputParam?.name === "tools" ||
                inputParam?.name === "tool_groups" ? (
                  data.inputs[inputParam.name] &&
                  !!data.inputs[inputParam.name].length ? (
                    data.inputs[inputParam.name].length > 3 ? (
                      <>
                        {data.inputs[inputParam.name]
                          .slice(0, 3)
                          .map((item: any, index: number) => (
                            <p key={index}>{item.name}</p>
                          ))}
                        <div onClick={openModal}>
                          <a style={{ color: "#6750a4" }}>+ show more</a>
                        </div>
                      </>
                    ) : (
                      data.inputs[inputParam.name].map(
                        (item: any, index: number) => (
                          <p key={index}>{item.name}</p>
                        )
                      )
                    )
                  ) : (
                    <p style={{ color: "gray" }}>No Data Found</p>
                  )
                ) : (
                  <>
                    <p className="menu-description">
                      {data.inputs[inputParam.name] || "0"}
                    </p>
                    {data.inputs[inputParam.name] &&
                      !!data.inputs[inputParam.name].length &&
                      data.inputs[inputParam.name].length > 75 && (
                        <div onClick={openModal}>
                          <a style={{ color: "#6750a4" }}>+ show more</a>
                        </div>
                      )}
                  </>
                )}
              </div>
            ) : (
              <>
                {inputParam.type === "boolean" && (
                  <label className="switch">
                    <input
                      type="checkbox"
                      disabled={disabled}
                      checked={data.inputs[inputParam.name] || false}
                      onChange={(event) =>
                        (data.inputs[inputParam.name] =
                          event.currentTarget.checked)
                      }
                    />
                    <span></span>
                  </label>
                )}

                {["string", "password"].includes(inputParam.type) && (
                  <div className="field border">
                    <input
                      disabled={disabled}
                      value={inputValue}
                      placeholder={inputParam.label}
                      defaultValue={inputParam.default || ""}
                      type={
                        inputParam.type === "password" ? "password" : "text"
                      }
                    />
                  </div>
                )}

                {inputParam.type === "number" && (
                  <div className="field border">
                    <input
                      type="number"
                      disabled={disabled}
                      min={inputParam?.min || 0}
                      max={inputParam?.max || 1}
                      step={inputParam.step}
                      placeholder={inputParam.placeholder || ""}
                      value={inputValue}
                      onChange={(event) =>
                        handleNumberChange(Number(event.currentTarget.value))
                      }
                    />
                  </div>
                )}

                {inputParam.type === "options" && (
                  <div className="field suffix border">
                    <select
                      disabled={disabled}
                      value={data.inputs[inputParam.name] ?? ""}
                      onChange={(event) =>
                        handleChange(event.currentTarget.value)
                      }
                    >
                      <option value="" disabled>
                        Please Select
                      </option>
                      {(inputParam.options ?? []).map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <i>arrow_drop_down</i>
                  </div>
                )}

                {["credential", "asyncOptions"].includes(inputParam.type) && (
                  <div className="field suffix border">
                    <select
                      className="select"
                      disabled={disabled}
                      value={data.inputs[inputParam.name] ?? ""}
                      onChange={(event) => {
                        handleChange(event.target.value);
                      }}
                    >
                      <option value="" disabled>
                        Please Select
                      </option>
                      {options &&
                        !!options.length &&
                        options.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                    </select>
                    <i>arrow_drop_down</i>
                  </div>
                )}
              </>
            )}
          </div>
          {isModalOpen && (
            <dialog className={`modal auto ${isModalOpen ? "active" : ""}`}>
              <h5>{data.label}</h5>
              <div style={{ padding: "5px 0px", marginTop: "1rem" }}>
                <strong>{inputParam.label}</strong>
                {inputParam?.name === "tools" ||
                inputParam?.name === "tool_groups" ? (
                  data.inputs[inputParam.name].map(
                    (item: any, index: number) => <p key={index}>{item.name}</p>
                  )
                ) : (
                  <p style={{ fontSize: "0.875rem", textAlign: "justify" }}>
                    {inputValue}
                  </p>
                )}
              </div>
              <nav className="right-align no-space">
                <button className="transparent link" onClick={closeModal}>
                  Cancel
                </button>
              </nav>
            </dialog>
          )}
        </>
      )}
    </div>
  );
};

export default NodeInputHandler;
