import React, { useState, useRef, useEffect } from "react";
import {
  ReactFlow,
  addEdge,
  Node,
  Edge,
  FitViewOptions,
  ReactFlowProvider,
  Controls,
  MiniMap,
  ReactFlowInstance,
  DefaultEdgeOptions,
  Background,
  useNodesState,
  useEdgesState,
} from "reactflow";
import "reactflow/dist/style.css";
import {
  ActionIcon,
  Accordion,
  Avatar,
  Group,
  Menu,
  Text,
  Modal,
  TextInput,
  Button,
  Box,
} from "@mantine/core";
import {
  IconArrowLeft,
  IconCheck,
  IconDeviceFloppy,
  IconMinus,
  IconPlus,
  IconX,
} from "@tabler/icons-react";
import CanvasNode from "./ReactCanvas/CanvasNode";
import { getDocumentStore, getLLM, getSupervisors } from "../lib/CanvasData";
import {
  convertToDesiredFormat,
  getUniqueNodeId,
  initNode,
} from "./ReactCanvas/helper";
import { createFlow, getAgents, getFlow, updateFlow } from "../lib/APIService";
import { notifications } from "@mantine/notifications";
import ChatInterface from "./ReactCanvas/ChartInterface";
import { useNavigate, useParams } from "react-router-dom";

const initialNodes: Node[] = [];
const initialEdges: Edge[] = [];
const fitViewOptions: FitViewOptions = {};
const defaultEdgeOptions: DefaultEdgeOptions = { animated: true };
const nodeTypes = { canvasNode: CanvasNode };
interface NodeData {
  id: string;
  name: string;
  description: string;
  icon: string;
  category: string;
}

interface Categories {
  [category: string]: NodeData[];
}

interface CreateFlow {
  id?: any;
  name: string;
  flowData: string;
  deployed: boolean;
  isPublic: boolean;
  createdDate?: Date;
  updatedDate?: Date;
}

export default function Canvas() {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [categories, setCategories] = useState<Categories>({});
  const [error, setError] = useState<Error | null>(null);
  const [flowData, setFlowData] = useState<CreateFlow>();
  const [reactFlowInstance, setReactFlowInstance] =
    useState<ReactFlowInstance | null>(null);
  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const [modalOpened, setModalOpened] = useState(false);
  const [flowName, setFlowName] = useState("");
  const navigate = useNavigate();
  const { flowId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          agentsResponse,
          llmResponse,
          documentStoreResponse,
          supervisorsResponse,
        ] = await Promise.all([
          getAgents(),
          getLLM(),
          getDocumentStore(),
          getSupervisors(),
        ]);

        const formattedAgents = agentsResponse?.map((agent: any) =>
          convertToDesiredFormat(agent)
        );

        const combinedData: any[] = [
          ...formattedAgents,
          ...llmResponse.data,
          ...documentStoreResponse.data,
          ...supervisorsResponse,
        ];

        const categorizedData = combinedData.reduce<Categories>((acc, node) => {
          const { category } = node;
          if (!acc[category]) {
            acc[category] = [];
          }
          acc[category].push(node);
          return acc;
        }, {});

        setCategories(categorizedData);
      } catch (error) {
        setError(error as Error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchChatflowData = async () => {
      if (flowId && reactFlowInstance && !flowData) {
        try {
          const chatflow = await getFlow(flowId);
          if (chatflow) {
            setFlowName(chatflow.name);
            const { flowData } = chatflow;
            const parsedFlowData = JSON.parse(flowData);

            const nodesWithInstance =
              parsedFlowData.nodes?.map((node: Node) => ({
                ...node,
                data: {
                  ...node.data,
                  reactFlowInstance,
                },
              })) || [];

            setNodes(nodesWithInstance);
            setEdges(parsedFlowData.edges || []);
            setFlowData(chatflow);
          }
        } catch (error) {
          console.error("Failed to parse chatflow data:", error);
        }
      }
    };

    fetchChatflowData();
  }, [flowId, reactFlowInstance, flowData, setEdges, setNodes]);

  const onConnect = (params: any) => {
    if (!params.source || !params.target) {
      console.error("Source or target is null", params);
      return;
    }

    const newEdge: Edge = {
      ...params,
      id: `${params.source}-${params.sourceHandle}-${params.target}-${params.targetHandle}`,
    };

    const targetNodeId = params.target;
    const sourceNodeId = params.source;
    const targetInput = params.targetHandle?.split("-")[2];

    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === targetNodeId) {
          let value;
          const inputAnchor = node.data.inputAnchors?.find(
            (ancr: any) => ancr.name === targetInput
          );
          const inputParam = node.data.inputParams?.find(
            (param: any) => param.name === targetInput
          );

          if (inputAnchor && inputAnchor.list) {
            const newValues = [...(node.data.inputs[targetInput!] || [])];
            newValues.push(`{{${sourceNodeId}.data.instance}}`);
            value = newValues;
          } else if (inputParam) {
            value = `{{${sourceNodeId}.data.instance}}`;
          } else {
            value = `{{${sourceNodeId}.data.instance}}`;
          }

          node.data = {
            ...node.data,
            inputs: {
              ...node.data.inputs,
              [targetInput!]: value,
            },
          };
        }
        return node;
      })
    );
    setEdges((eds) => addEdge(newEdge, eds));
  };

  const handleDragStart = (
    e: React.DragEvent<HTMLDivElement>,
    item: NodeData
  ) => {
    e.dataTransfer.setData("application/node", JSON.stringify(item));
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const data = e.dataTransfer.getData("application/node");
    if (!data || !reactFlowInstance) return;

    const nodeData = JSON.parse(data);
    const rect = reactFlowWrapper.current!.getBoundingClientRect();
    const xPos = e.clientX - rect.left;
    const yPos = e.clientY - rect.top;

    const newNodeId = getUniqueNodeId(nodeData, reactFlowInstance.getNodes());
    const newNode: Node = {
      id: newNodeId,
      type: "canvasNode",
      position: { x: xPos, y: yPos },
      data: {
        ...initNode(nodeData, newNodeId, reactFlowInstance),
      },
    };

    setNodes((nds) => nds.concat(newNode));
    setDropdownOpen(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const openSaveModal = () => {
    flowId ? handleSave() : setModalOpened(true);
  };

  const handleSave = async () => {
    if (!reactFlowInstance) return;

    try {
      const nodes = reactFlowInstance.getNodes().map((node) => ({
        ...node,
        data: { ...node.data, selected: false },
      }));
      const edges = reactFlowInstance.getEdges();
      const chatFlow = JSON.stringify({ nodes, edges });

      let response;
      if (flowId) {
        const updatedChatflow = {
          ...flowData,
          name: flowName,
          flowData: chatFlow,
        };
        response = await updateFlow(flowId, updatedChatflow);
        notifications.show({
          message: "Updated the flow successfully!",
        });
      } else {
        const newFlow: CreateFlow = {
          name: flowName,
          flowData: chatFlow,
          deployed: false,
          isPublic: false,
        };
        response = await createFlow(newFlow);
        notifications.show({
          message: "Created the flow successfully!",
        });
      }
      setModalOpened(false);
      if (response) {
        setModalOpened(false);
        navigate(`/home/flows/${response.id}`);
      }
    } catch (error) {
      console.error("Failed to save chatflow:", error);
    }
  };
  const [isEditing, setIsEditing] = useState(false);
  const [flowTitle, setFlowTitle] = useState<any>(flowName);

  const handleEditClick = () => {
    if (flowId) {
      setFlowTitle(flowName);
      setIsEditing(!isEditing);
    }
  };

  const handleFlowName = () => {
    setFlowName(flowTitle);
    setIsEditing(false);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "92%",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "10px",
          backgroundColor: "#f5f5f5",
          borderBottom: "1px solid #ddd",
          gap: "10px",
        }}
      >
        <a
          className="small chip action-icon"
          onClick={() => {
            localStorage.removeItem("chatConversationId");
            navigate("/home/flows");
          }}
        >
          <i>arrow_back</i>
        </a>
        <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
          <div style={{ flex: "0 0 50%" }}>
            {isEditing ? (
              <div
                className="field border small"
                style={{ display: "contents" }}
              >
                <input
                  type="text"
                  value={flowTitle}
                  onChange={(e) => setFlowTitle(e.currentTarget.value)}
                  disabled={!flowId}
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    backgroundColor: flowId ? "white" : "transparent",
                    borderColor: flowId ? "#d0d0d0" : "transparent",
                    borderRadius: "8px",
                    width: "100%",
                  }}
                />
              </div>
            ) : (
              <h2
                style={{ fontSize: "20px", fontWeight: "bold" }}
                onClick={handleEditClick}
              >
                {flowId ? flowName : "Untitled"}
              </h2>
            )}
          </div>
          {isEditing && (
            <div className="group" style={{ display: "flex" }}>
              <a className="small chip action-icon" onClick={handleFlowName}>
                <i>check</i>
              </a>
              <a
                className="small chip action-icon"
                onClick={() => setIsEditing(false)}
              >
                <i>close</i>
              </a>
            </div>
          )}
        </div>

        <a className="small chip action-icon" onClick={openSaveModal}>
          <i>save</i>
        </a>
      </div>

      <div style={{ position: "relative", flex: 1, overflow: "hidden" }}>
        <div
          style={{
            position: "absolute",
            left: "10px",
            top: "10px",
            zIndex: 10,
          }}
        >
          <button
            className="circle small"
            onClick={() => setDropdownOpen((o) => !o)}
          >
            {isDropdownOpen ? <i>remove</i> : <i>add</i>}
            {isDropdownOpen && (
              <menu
                className="right no-wrap"
                style={{ width: "300px" }}
                onClick={(event: any) => event.stopPropagation()}
              >
                {Object.entries(categories).map(([category, items]) => (
                  <details style={{ padding: "6px" }} key={category}>
                    <summary>{category}</summary>
                    <p>
                      {items.map((item, index) => (
                        <div
                          key={index}
                          draggable
                          onDragStart={(e) => handleDragStart(e, item)}
                        >
                          <p className="row" style={{ padding: "0px 15px" }}>
                            <img className="circle tiny" src={item?.icon} />
                            <div className="max">
                              <label className="menu-label">{item.name}</label>
                              <label className="menu-description">
                                {item.description}
                              </label>
                            </div>
                          </p>
                        </div>
                      ))}
                    </p>
                  </details>
                ))}
              </menu>
            )}
          </button>
        </div>
        <div
          style={{
            position: "absolute",
            right: "0px",
            top: "8px",
            zIndex: 10,
          }}
        >
          <ChatInterface nodes={nodes} chatFlowId={flowData?.id!} />
        </div>
        <ReactFlowProvider>
          <div
            className="reactflow-wrapper"
            ref={reactFlowWrapper}
            style={{
              width: "100%",
              height: "100%",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              fitViewOptions={fitViewOptions}
              defaultEdgeOptions={defaultEdgeOptions}
              nodeTypes={nodeTypes}
              onInit={setReactFlowInstance}
              defaultViewport={{ x: 0, y: 0, zoom: 0 }}
            >
              <Controls />
              <MiniMap />
              <Background />
            </ReactFlow>
          </div>
        </ReactFlowProvider>
        <Modal
          opened={modalOpened}
          onClose={() => setModalOpened(false)}
          title="Save Flow"
        >
          <TextInput
            label="Flow Name"
            placeholder="Enter flow name"
            value={flowName}
            onChange={(e) => setFlowName(e.currentTarget.value)}
          />
          <Group position="right" mt="md">
            <Button variant="outline" onClick={() => setModalOpened(false)}>
              Cancel
            </Button>
            <Button onClick={handleSave}>Save</Button>
          </Group>
        </Modal>
      </div>
    </div>
  );
}
