// src/ForceGraph.tsx
//@ts-nocheck

import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

interface Node {
  id: string;
}

interface Link {
  source: string;
  target: string;
}

interface ForceGraphProps {
  nodes: Node[];
  relationships: Link[];
}

type GraphType = "document" | "chunks" | "entities";

function getTextSize(text, fontSize, fontFamily) {
  // Create a temporary span element
  const span = document.createElement("span");
  span.textContent = text;

  // Set the font for the text measurement
  span.style.fontSize = fontSize;
  span.style.fontFamily = fontFamily;

  // Append the span to the document body
  document.body.appendChild(span);

  // Measure the width and height of the text
  const width = span.offsetWidth;
  const height = span.offsetHeight;

  // Clean up the temporary span
  document.body.removeChild(span);

  return { width, height };
}

function splitLongText(text: string, maxLineLength: number) {
  const words = text ? text.split(" ") : [];
  const lines = [];
  let currentLine = "";

  words.forEach(function (word) {
    if (currentLine.length + word.length <= maxLineLength) {
      currentLine += word + " ";
    } else {
      lines.push(currentLine.trim());
      currentLine = word + " ";
    }
  });

  if (currentLine.trim() !== "") {
    lines.push(currentLine.trim());
  }

  return lines;
}

function generatePath(d: any, excludeRadius: boolean) {
  const dx = d.target.x - d.source.x;
  const dy = d.target.y - d.source.y;
  const gamma = Math.atan2(dy, dx); // Math.atan2 returns the angle in the correct quadrant as opposed to Math.atan

  let sourceNewX, sourceNewY, targetNewX, targetNewY;
  if (excludeRadius) {
    sourceNewX = d.source.x + Math.cos(gamma) * d.source.radius;
    sourceNewY = d.source.y + Math.sin(gamma) * d.source.radius;
    targetNewX = d.target.x - Math.cos(gamma) * d.target.radius;
    targetNewY = d.target.y - Math.sin(gamma) * d.target.radius;
  } else {
    sourceNewX = d.source.x;
    sourceNewY = d.source.y;
    targetNewX = d.target.x;
    targetNewY = d.target.y;
  }

  // Coordinates of mid point on line to add new vertex (for arrow placement).
  const midX = (targetNewX - sourceNewX) / 2 + sourceNewX;
  const midY = (targetNewY - sourceNewY) / 2 + sourceNewY;
  return (
    "M" +
    sourceNewX +
    "," +
    sourceNewY +
    "L" +
    midX +
    "," +
    midY +
    "L" +
    targetNewX +
    "," +
    targetNewY
  );
}

export const ForceGraph: React.FC<any> = ({ nodes, relationships }) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const zoomRef = useRef<d3.ZoomBehavior<Element, unknown>>();
  const tooltipRef = useRef<Element | null>(null);

  const width = 1600;
  const height = 1000;
  const maxLineLength = 10;

  const tooltipStyles = {
    display: "block",
    position: "absolute",
    width: "300px",
    height: "auto",
    padding: "8px",
    "background-color": "#ffffff",
    color: "#000000",
    border: "1px solid #ddd",
    "z-index": 10,
  };

  useEffect(() => {
    const getFontSize = (d) => d.radius / 3.2;

    nodes.forEach((n, i) => {
      const substrings = splitLongText(n.properties.id, maxLineLength);

      const texts = [];
      substrings.forEach((string) => {
        const text = getTextSize(string, getFontSize(n) + "px", "Inter");
        texts.push({ text: string, width: text.width, height: text.height });
      });

      n.width = d3.max(texts, (d) => d.width);
      n.height = d3.max(texts, (d) => d.height) * substrings.length;
    });

    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    const g = svg.append("g").attr("class", "main-group");
    const linkG = g.append("g").attr("class", "links");
    const linkTextG = g.append("g").attr("class", "linkTexts");
    const nodeG = g.append("g").attr("class", "nodes");
    const textG = g.append("g").attr("class", "labels");

    const tooltipDiv = d3.select(tooltipRef.current);
    for (const prop in tooltipStyles) {
      tooltipDiv.style(prop, tooltipStyles[prop]);
    }

    const simulation = d3
      .forceSimulation(nodes)
      .force(
        "link",
        d3
          .forceLink(relationships)
          .id((d: any) => d.id)
          //.distance(90)
          .strength(0.2)
      )
      .force("charge", d3.forceManyBody().strength(-500))
      .force("center", d3.forceCenter(width / 2, height / 2))
      .force(
        "x",
        d3.forceX((d) => d.x)
      )
      .force(
        "y",
        d3.forceY((d) => d.y)
      );

    const updateLinkFunc = (update) => {
      return update;
    };

    function updateLinkTextFunc(selection) {
      selection.select("text").text((d) => d.type);
    }

    const updateNodeFunc = (update) => {
      update.select("circle").attr("r", (d) => d.radius);
    };

    function updateTextFunc(selection) {
      selection
        .select("text")
        .attr(
          "transform",
          (d) => `translate(${0}, ${d.height === 9 ? 0 : -d.height / 4})`
        )
        .attr("font-size", (d) => getFontSize(d));

      selection.each(function (d) {
        const text = d3.select(this).select("text");

        const tspans = text
          .selectAll("tspan")
          .data(splitLongText(d.properties.id, maxLineLength));

        tspans
          .enter()
          .append("tspan")
          .attr("x", 0)
          .attr("y", (_, i) => getFontSize(d) * i)
          .merge(tspans) // Merge the enter and update selections
          .text((d) => d);

        tspans.exit().remove();
      });
    }

    // Update existing links
    const link = linkG
      .selectAll("path.link")
      .data(relationships, (d) => d.source.id + "_" + d.target.id)
      .join(
        (enter) =>
          enter
            .append("path")
            .attr("class", "link")
            .attr("id", (d) => d.source.id + "_" + d.target.id)
            .attr("stroke", "black")
            .attr("stroke-width", "2px")
            .attr("d", (d) => generatePath(d, true)),

        (update) => update.call(updateLinkFunc),
        (exit) => exit
      )
      .attr("pointer-events", "auto")
      .attr("cursor", "pointer")
      .attr("fill", "none");

    // Update existing link labels
    const linkTexts = linkTextG
      .selectAll("text.link")
      .data(relationships, (d) => d.source.id + "_" + d.target.id)
      .join(
        (enter) =>
          enter
            .append("text")
            .attr("class", "link")
            .attr("x", (d) => (d.target.x - d.source.x) / 2 + d.source.x)
            .attr("y", (d) => (d.target.y - d.source.y) / 2 + d.source.y)
            .attr("transform", (d) => {
              const angle =
                Math.atan2(d.target.y - d.source.y, d.target.x - d.source.x) *
                (180 / Math.PI);
              const x = (d.target.x - d.source.x) / 2 + d.source.x;
              const y = (d.target.y - d.source.y) / 2 + d.source.y;
              if (angle > 90 || angle < -90) {
                return `rotate(${angle + 180}, ${x}, ${y})`;
              } else {
                return `rotate(${angle}, ${x}, ${y})`;
              }
            })
            .attr("dy", -5)
            .text((d) => d.type),

        (update) => update.call(updateLinkTextFunc),
        (exit) => exit
      )
      .attr("text-anchor", "middle")
      .attr("fill", "black")
      .attr("font-size", "6px");

    // Update existing nodes
    const updatedNode = nodeG.selectAll(".node").data(nodes, (d) => d.id);

    updatedNode.join(
      (enter) => {
        const newNode = enter
          .append("g")
          .attr("class", "node")
          .attr("pointer-events", "auto")
          .attr("cursor", "pointer")
          .attr("transform", (d) => `translate(${d.x}, ${d.y})`)
          .call(drag(simulation))
          .on("dblclick.zoom", null);

        newNode
          .append("circle")
          .attr("r", (d) => d.radius)
          .attr("fill", (d) => d.color)
          .attr("fill-opacity", 1);

        return newNode;
      },
      (update) => update.call(updateNodeFunc),
      (exit) => exit
    );

    // Update existing node labels
    const updatedText = textG.selectAll(".label").data(nodes, (d) => d.id);

    updatedText.join(
      (enter) => {
        const newText = enter
          .append("g")
          .attr("class", (d) => "label label-" + d.id.replaceAll(" ", "-"))
          .attr("transform", (d) => `translate(${d.x}, ${d.y})`)
          .attr("pointer-events", "none");

        const text = newText
          .append("text")
          .attr(
            "transform",
            (d) => `translate(${0}, ${d.height === 9 ? 0 : -d.height / 4})`
          ) // position label
          .attr("font-size", (d) => getFontSize(d)) // label size is proportionate to node size
          .attr("fill", "black")
          //.attr('stroke', 'white')
          //.attr('stroke-width', '0.3px')
          .attr("font-weight", "bold")
          .attr("dominant-baseline", "middle")
          .attr("text-anchor", "middle");

        text
          .selectAll("tspan")
          .data((d) =>
            splitLongText(d.properties.id, maxLineLength).map((t) => [
              t,
              getFontSize(d),
            ])
          )
          .enter()
          .append("tspan")
          .attr("x", 0)
          .attr("y", (d, i) => d[1] * i)
          .text((d) => d[0]);

        return newText;
      },
      (update) => update.call(updateTextFunc),
      (exit) => exit
    );

    nodeG
      .selectAll(".node")
      .on("mouseover", function (event, dd) {
        updateTooltip(event, dd); // show tooltip on mouseover any node
      })
      .on("mouseleave", function () {
        tooltipDiv.style("visibility", "hidden");
      });

    simulation.on("tick", () => {
      linkG.selectAll("path.link").attr("d", (d) => generatePath(d, true));
      nodeG
        .selectAll(".node")
        .attr("transform", (d) => `translate(${d.x}, ${d.y})`);
      textG
        .selectAll(".label")
        .attr("transform", (d) => `translate(${d.x}, ${d.y})`);
      linkTextG
        .selectAll(".link")
        .attr("x", (d) => (d.target.x - d.source.x) / 2 + d.source.x)
        .attr("y", (d) => (d.target.y - d.source.y) / 2 + d.source.y)
        .attr("transform", (d) => {
          const angle =
            Math.atan2(d.target.y - d.source.y, d.target.x - d.source.x) *
            (180 / Math.PI);
          const x = (d.target.x - d.source.x) / 2 + d.source.x;
          const y = (d.target.y - d.source.y) / 2 + d.source.y;
          if (angle > 90 || angle < -90) {
            return `rotate(${angle + 180}, ${x}, ${y})`;
          } else {
            return `rotate(${angle}, ${x}, ${y})`;
          }
        });
    });

    function updateTooltip(event, d) {
      if (!tooltipDiv.innerHTML) {
        const content = [];
        content.push(
          `<div style='padding-bottom: 4px; font-size: 18px; font-weight: bold; color: ${d.color}'>${d.properties.id}</div>`
        ); // tooltip title
        content.push(
          `<div><b>Description: </b><span>${d.properties["description"]}</span></div>`
        );

        let contentStr = "";
        content.map((d) => (contentStr += d));

        tooltipDiv.html(`${contentStr}`);
      }

      // const bbox = d3.select(".tooltip").node().getBoundingClientRect()
      const [x, y] = d3.pointer(event, svg);

      tooltipDiv
        .style("visibility", "visible")
        .style("left", (x + d.radius).toString() + "px")
        .style("top", (y + d.radius + 10).toString() + "px");
    }

    function drag(simulation: d3.Simulation<Node, undefined>) {
      function dragstarted(event: any, d: any) {
        if (!event.active) simulation.alphaTarget(0.3).restart();
        d.fx = d.x;
        d.fy = d.y;
      }

      function dragged(event: any, d: any) {
        d.fx = event.x;
        d.fy = event.y;
      }

      function dragended(event: any, d: any) {
        if (!event.active) simulation.alphaTarget(0);
        d.fx = null;
        d.fy = null;
      }

      return d3
        .drag<SVGCircleElement, Node>()
        .on("start", dragstarted)
        .on("drag", dragged)
        .on("end", dragended);
    }

    zoomRef.current = d3
      .zoom<Element, unknown>()
      .scaleExtent([0.1, 10])
      .on("zoom", (event) => {
        g.attr("transform", event.transform);
      });

    svg.call(zoomRef.current);
  }, [nodes, relationships]);

  const handleZoomIn = () => {
    d3.select(svgRef.current).transition().call(zoomRef.current.scaleBy, 1.2);
  };

  const handleZoomOut = () => {
    d3.select(svgRef.current).transition().call(zoomRef.current.scaleBy, 0.8);
  };

  return (
    <div>
      <div>
        <button onClick={handleZoomIn}>+</button>
        <button onClick={handleZoomOut}>-</button>
      </div>
      <svg ref={svgRef} width={width} height={height}></svg>
    </div>
  );
};

export default ForceGraph;
