import { Node, Relationship } from "@neo4j-nvl/base";
import { logout } from "./APIService";
import { calcWordColor } from "@neo4j-devtools/word-color";

function serialize(obj: any) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

export function callAuthAPI(props: any) {
  const { path, method, params, body } = props;
  const options = {
    method: method || "GET",
    headers: {
      "Content-Type": "application/json",
    },
    body: body ? JSON.stringify(body) : null,
  };
  const endpoint = params ? path + "?" + serialize(params) : path;
  return fetch(endpoint, options)
    .then((response) => {
      if (response.status === 401) {
        logout();
      }
      return response.json();
    })
    .then(async (responseJSON) => responseJSON)
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export function callAPI(props: any) {
  const { path, method, query, body } = props;
  const accessToken = localStorage.getItem("accessToken");
  const options = {
    method: method || "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: body ? JSON.stringify(body) : null,
  };
  const endpoint = query ? path + "?" + serialize(query) : path;
  return fetch(endpoint, options)
    .then((response) => {
      return response.json();
    })
    .then(async (responseJSON) => responseJSON)
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export function callFileAPI(props: any) {
  const { path, method, query, files } = props;
  const accessToken = localStorage.getItem("accessToken");
  var data = new FormData();
  files.forEach((file: any) => {
    data.append("files", file);
  });
  const options = {
    method: method || "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: data,
  };
  const endpoint = query ? path + "?" + serialize(query) : path;
  return fetch(endpoint, options)
    .then((response) => {
      return response.json();
    })
    .then(async (responseJSON) => responseJSON)
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export function callSingleFileAPI(props: any) {
  const { path, method, query, file } = props;
  const accessToken = localStorage.getItem("accessToken");
  var data = new FormData();
  data.append("file", file, file.name);
  const options = {
    method: method || "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: data,
  };
  const endpoint = query ? path + "?" + serialize(query) : path;
  return fetch(endpoint, options)
    .then((response) => {
      return response.json();
    })
    .then(async (responseJSON) => responseJSON)
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export function camelCaseToWords(s: string) {
  if(s && s.length!==0){
  const result = s.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
  }
}

export const getRole = (role: any) => {
  switch (role) {
    case "awsexpert":
      return "Cloud Analyst";
    case "rag-analyst":
      return "Trust Advisor";
    case "trust-expert":
      return "Trust Expert";
    case "incident-analyst":
      return "Incident Analyst";
    case "problem-management":
      return "Problem Management";
    case "data-analyst":
      return "Data Analyst";
    case "executive-assistant":
      return "Security Analytics & Reporting";
    case "k8s-analyst":
      return "Trust Assurance";
    case "anypoint-trust":
      return "Anypoint Trust";
    case "trust-expert-graph-rag":
      return "Trust Advisor (Graph)";
    default:
      return "Trust Advisor";
  }
};

export const getRoleIcon = (role: any) => {
  switch (role) {
    case "awsexpert":
      return "/cloud.png";
    case "rag-analyst":
      return "/genie_small.png";
    case "trust-expert":
      return "/skill.png";
    case "incident-analyst":
      return "/incident.svg";
    case "problem-management":
      return "/rca.png";
    case "data-analyst":
      return "/analyst.svg";
    case "executive-assistant":
      return "/dashboard.png";
    case "k8s-analyst":
      return "/k8s.svg";
    case "anypoint-trust":
      return "/anypoint.svg";
    case "trust-expert-graph-rag":
      return "/genie_small.png";
    default:
      return "/skill.png";
  }
};

export const getRoleIconColored = (role: any) => {
  switch (role) {
    case "cloud-analyst":
      return "/cloud_analyst.svg";
    case "security-analyst":
      return "/security_analyst.svg";
    case "trust-expert":
      return "/trust_assurance.svg";
    case "incident-analyst":
      return "/incidentanalyst.svg";
    case "custom-agent":
      return "/graph.svg";
    case "data-analyst":
      return "/dataanalyst.svg";
    case "trust-expert-graph-rag":
      return "/skill.svg.png";
    case "anypoint-trust":
      return "/anypoint.svg";
    case "multi-agent":
      return "/multi-agent.svg";
    default:
      return "/skill.svg";
  }
};

export const getSize = (node: any) => {
  if (node.labels[0] == "Document") {
    return 40;
  }
  if (node.labels[0] == "Chunk") {
    return 30;
  }
  return undefined;
};

export const getNodeCaption = (node: any) => {
  if (node.properties.name) {
    return node.properties.name;
  }
  if (node.properties.text) {
    return node.properties.text;
  }
  if (node.properties.fileName) {
    return node.properties.fileName;
  }
  return node.properties.id;
};

export const getIcon = (node: any) => {
  if (node.labels[0] == "Document") {
    return "paginate-filter-text.svg";
  }
  if (node.labels[0] == "Chunk") {
    return "paragraph-left-align.svg";
  }
  return undefined;
};

export type Scheme = Record<string, string>;
export const processGraphData = (neoNodes: Node[], neoRels: Relationship[]) => {
  const schemeVal: Scheme = {};
  let iterator = 0;
  const labels: string[] = neoNodes.map((f: any) => f.labels);
  labels.forEach((label: any) => {
    if (schemeVal[label] == undefined) {
      schemeVal[label] = calcWordColor(label[0]);
      iterator += 1;
    }
  });
  const newNodes: Node[] = neoNodes.map((g: any) => {
    return {
      id: g.element_id,
      size: getSize(g),
      captionAlign: "bottom",
      iconAlign: "bottom",
      caption: getNodeCaption(g),
      color: schemeVal[g.labels[0]],
      icon: getIcon(g),
      labels: g.labels,
    };
  });
  const finalNodes = newNodes.flat();

  const newRels: Relationship[] = neoRels.map((relations: any) => {
    return {
      id: relations.element_id,
      from: relations.start_node_element_id,
      to: relations.end_node_element_id,
      caption: relations.type,
    };
  });
  const finalRels = newRels.flat();
  return { finalNodes, finalRels, schemeVal };
};
