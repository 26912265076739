import {
  Alert,
  Button,
  Flex,
  Group,
  Paper,
  rem,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { Dropzone, FileWithPath, MIME_TYPES } from "@mantine/dropzone";
import {
  IconAlertSquare,
  IconPhoto,
  IconPointFilled,
  IconUpload,
  IconX,
} from "@tabler/icons-react";
import { useState } from "react";
import { sendConversationQuery } from "../lib/APIService";

function getMimeType(fileType: string) {
  switch (fileType) {
    case "pdf":
      return MIME_TYPES.pdf;
    case "csv":
      return MIME_TYPES.csv;
    case "png":
      return MIME_TYPES.png;
    case "jpg":
    case "jpeg":
      return MIME_TYPES.jpeg;
    default:
      return MIME_TYPES.pdf;
  }
}

export default function UploadFile(props: any) {
  const fileMimeType = getMimeType(props.type);
  const [fileType, setFileType] = useState(props.type);
  const [files, setFiles] = useState<FileWithPath[]>([]);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploading, setUploading] = useState(false);
  const theme = useMantineTheme();

  const uploadFiles = async () => {
    if (files.length) {
      const response = await props.uploadFileFunction(
        files,
        props.conversationId
      );
      setUploading(true);
       
      if (response.status === "success") {
        setFiles([]);
        props.sendQuery("Give me a Detailed Summary of Data.");
        setUploading(false);
        setUploadSuccess(true);
      }
    }
  };

  return (
    <>
      <Paper mt={16} p={"md"} shadow={"xs"}>
        <Stack align={"stretch"}>
          <Dropzone
            onDrop={(files) => setFiles(files)}
            onReject={(files) => console.log("rejected files", files)}
            maxSize={10 * 1024 ** 2}
            accept={[MIME_TYPES.csv, "application/json"]}
            loading={uploading}
          >
            <Group
              position="center"
              spacing="xl"
              style={{ minHeight: rem(160), pointerEvents: "none" }}
            >
              <Dropzone.Accept>
                <IconUpload
                  size="3.2rem"
                  stroke={1.5}
                  color={
                    theme.colors[theme.primaryColor][
                      theme.colorScheme === "dark" ? 4 : 6
                    ]
                  }
                />
              </Dropzone.Accept>
              <Dropzone.Reject>
                <IconX
                  size="3.2rem"
                  stroke={1.5}
                  color={theme.colors.red[theme.colorScheme === "dark" ? 4 : 6]}
                />
              </Dropzone.Reject>
              <Dropzone.Idle>
                {/* <IconPhoto size="3.2rem" stroke={1.5} /> */}
              </Dropzone.Idle>

              <div>
                <Text size="l" inline>
                  Drag {fileType} files here or click to select files
                </Text>
                <Group spacing={4}>
                  <Text size="sm" color="dimmed" inline mt={7}>
                    Limit 5mb per file
                  </Text>
                  <IconPointFilled size={12} style={{ marginTop: 6 }} />
                  <Text size="sm" color="dimmed" inline mt={7}>
                    {fileType}
                  </Text>
                </Group>
                {!!files?.length && (
                  <Flex mt={16} direction={"column"}>
                    {files.map((file) => {
                      return (
                        <Text size={"xs"} fs={"italic"} key={file.name}>
                          {file.name}
                        </Text>
                      );
                    })}
                  </Flex>
                )}
              </div>
            </Group>
          </Dropzone>
          {!!uploadSuccess && (
            <Alert
              icon={<IconAlertSquare size="1rem" />}
              title="Success!"
              color="blue"
              withCloseButton
              onClose={() => setUploadSuccess(false)}
            >
              Document was uploaded successfully!
            </Alert>
          )}
          {!!uploading && (
            <Alert
              icon={<IconAlertSquare size="1rem" />}
              title="Upload in Progress!"
              color="green"
              withCloseButton
              // onClose={() => setUploadSuccess(false)}
            >
              Document is uploaded and being summarized
            </Alert>
          )}
          <Group position="right">
            <Button onClick={() => uploadFiles()}>Upload</Button>
          </Group>
        </Stack>
      </Paper>
    </>
  );
}
