import { useParams } from "react-router-dom";
import ConversationMD from "../../components/material-design/components/Conversation";

export default function SharedConversation() {
  const { conversationId } = useParams();

  return (
    <>
      {!!conversationId && (
        <ConversationMD
          hideSend={true}
          conversation_id={conversationId}
          isShared={true}
        />
      )}
    </>
  );
}
