import React, { useEffect, useState } from "react";

import { AddMultipleEnv, UpdateMultipleEnv } from "../../lib/APIService";

interface CredentialInput {
  label: string;
  name: string;
  type: "string" | "password" | "number";
  placeholder: string;
  description?: string;
  optional?: boolean;
  value?: string;
  id?: any;
  created_at?: any;
  updated_at?: any;
}

interface CredentialData {
  isEdit?: any;
  id?: any;
  label: string;
  name: string;
  version: number;
  description?: string;
  optional?: boolean;
  inputs: CredentialInput[];
  icon: string;
  created_at?: any;
  updated_at?: any;
  credential_name?: any;
}

interface PayloadItem {
  env_name: string;
  value: string;
}

interface SavedCredential {
  credentialName: string;
  payload_id: string;
  payload: PayloadItem[];
}

interface CredentialFormProps {
  credential: CredentialData;
  isOpen: boolean;
  onClose: () => void;
}

const CredentialForm: React.FC<CredentialFormProps> = ({
  credential,
  isOpen,
  onClose,
}) => {
  const [credentialState, setCredentialState] = useState<any>(credential);
  const handleInputChange = (inputName: string, inputValue: string): void => {
    if (inputName === "credential_name") {
      setCredentialState((prevCredential: any) => ({
        ...prevCredential,
        credential_name: inputValue,
      }));
    } else {
      const updatedInputs = credentialState.inputs.map((input: any) =>
        input.name === inputName ? { ...input, value: inputValue } : input
      );
      setCredentialState((prevCredential: any) => ({
        ...prevCredential,
        inputs: updatedInputs,
      }));
    }
  };

  const createEnvData = (
    input: any,
    credential_name: string,
    credentialState: any,
    envId: any
  ) => {
    const { name, created_at, updated_at } = credentialState;
    return {
      env_name: input.name,
      value: input.value,
      userid: 4,
      ...(credential_name && {
        credential_name: `${name}__${credential_name}`,
      }),
      ...(envId && { id: envId }),
      ...(created_at && { created_at }),
      ...(updated_at && { updated_at }),
    };
  };

  const handleSave = async () => {
    const { inputs, credential_name, label } = credentialState;
    let data = [];
    const credential =
      credential_name &&
      createEnvData(
        {
          name: "CREDENTIAL_NAME",
          value: credential_name,
        },
        credential_name,
        credentialState,
        credentialState.credential_id
      );

    if (credential_name || label.toLowerCase() !== "otherapi") {
      data = inputs
        .filter((input: any) => input.value)
        .map((input: any) =>
          createEnvData(input, credential_name, credentialState, input.id)
        );
    } else if (label.toLowerCase() === "otherapi") {
      const env_name = inputs.find(
        (input: any) => input.name === "env_name"
      )?.value;
      const value = inputs.find((input: any) => input.name === "value")?.value;

      if (value) {
        data = [
          createEnvData(
            { name: env_name, value },
            "",
            credentialState,
            credentialState.id
          ),
        ];
      }
    }

    if (credential) {
      data = [...data, credential];
    }
    if (data.length) {
      const response = credentialState.isEdit
        ? await UpdateMultipleEnv(data)
        : await AddMultipleEnv(data);

      if (response) {
        onClose();
      }
    }
  };

  const isButtonDisabled =
    credentialState.inputs.some(
      (input: any) => !input.optional && !input.value?.trim()
    ) ||
    (!credentialState.optional && !credentialState.credential_name?.trim());

  return (
    <dialog
      open={isOpen}
      onClose={onClose}
      className="auto credential-form-wrapper blue-grey1"
    >
      <div className="credential-header">
        <div className="group align-items-center" style={{ gap: "10px" }}>
          <img
            className="tiny"
            src={credentialState?.icon}
            style={{ objectFit: "contain" }}
          />
          <h6
            className="small"
            style={{
              marginBlockStart: "0px",
            }}
          >
            {credentialState?.label}
          </h6>
        </div>
        <button className="transparent square" onClick={onClose}>
          <i>close</i>
        </button>
      </div>
      {credentialState?.description && (
        <p
          style={{
            background: "rgb(254, 252, 191)",
            borderRadius: "10px",
            padding: "10px",
            color: "rgb(116, 66, 16)",
            marginBlockStart: "0px",
          }}
          dangerouslySetInnerHTML={{ __html: credentialState.description }}
        />
      )}
      {credentialState?.label.toLowerCase() !== "otherapi" && (
        <div className="field border small" style={{ marginBlockStart: "0px" }}>
          <label className="label">CREDENTIAL NAME </label>
          {!credentialState.optional && <span style={{ color: "red" }}>*</span>}
          <input
            type="text"
            onChange={(e) =>
              handleInputChange("credential_name", e.currentTarget.value)
            }
            value={credentialState.credential_name}
            placeholder="Enter Credential Name"
            name="credential_name"
            required
          />
        </div>
      )}
      {credentialState?.inputs.map((input: any) => (
        <div key={input.name} style={{ width: "100%" }}>
          {input.type === "string" && (
            <div className="field border small">
              <label className="label">{input.label}</label>
              {!input.optional && <span style={{ color: "red" }}>*</span>}
              {input.description && (
                <span>
                  <i style={{ fontSize: "12px" }}>info</i>
                  <div className="tooltip top">{input.description}</div>
                </span>
              )}
              <input
                type="text"
                placeholder={input.placeholder}
                value={input.value}
                onChange={(e) =>
                  handleInputChange(input.name, e.currentTarget.value)
                }
                style={{ width: "100%" }}
                name={input.name}
                required={input.optional}
              />
            </div>
          )}
          {input.type === "password" && (
            <div className="field border small">
              <label className="label">{input.label}</label>
              {!input.optional && <span style={{ color: "red" }}>*</span>}
              {input.description && (
                <span>
                  <i style={{ fontSize: "12px" }}>info</i>
                  <div className="tooltip top">{input.description}</div>
                </span>
              )}
              <input
                type="password"
                placeholder={input.placeholder}
                value={input.value}
                onChange={(e) =>
                  handleInputChange(input.name, e.currentTarget.value)
                }
                name={input.name}
                required={input.optional}
              />
            </div>
          )}
        </div>
      ))}
      <div className="flex right-align">
        <button onClick={handleSave} disabled={isButtonDisabled}>
          Add
        </button>
      </div>
    </dialog>
  );
};

export default CredentialForm;
