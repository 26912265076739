import MdMultiSelect from "./material-design/components/MdMultiselect";
import { useEffect, useState } from "react";
import { createToolGroup, getTools } from "../lib/APIService";
interface Tool {
  id: any;
  name: string;
  description?: string;
  iconSrc?: string;
  createdDate?: any;
  updatedDate?: any;
}
export default function AddToolGroup({ popup, setPopup, onAddToolgroup }: any) {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    tools: [],
  });
  const [toolsList, setToolsList] = useState<Tool[]>([]);

  const handleChange = (field: any, value: any, list: any[] = []) => {
    let selectedValue = value;
    if (field === "tools" && list && !!list.length) {
      selectedValue = list?.filter((tool) => value.includes(tool.id));
    }
    setFormData((prevState) => ({
      ...prevState,
      [field]: selectedValue,
    }));
  };

  useEffect(() => {
    const getToolsList = async () => {
      const response = await getTools();
      const cleanedTools = response?.map((tool: Tool) => {
        const { createdDate, updatedDate, ...rest } = tool;
        return rest;
      });
      setToolsList(cleanedTools);
    };

    getToolsList();
  }, []);

  const handleSubmit = async () => {
    const newToolgroup = {
      name: formData.name,
      description: formData.description,
      model: "gpt-4o",
      tools: formData.tools,
      credential: "9e2a01e2-ceeb-4da2-81e4-8a70627f23b1",
    };

    const response = await createToolGroup(newToolgroup);
    if (response) {
      onAddToolgroup(response);
      setPopup(false);
    }
  };

  return (
    <dialog
      open={popup}
      className="auto surface"
      style={{
        minWidth: "40%",
        overflow: "hidden",
      }}
    >
      <div className="credential-header">
        <h5 className="small">Add ToolGroup</h5>
        <button className="transparent square" onClick={() => setPopup(false)}>
          <i>close</i>
        </button>
      </div>

      <div className="top-padding">
        <div className="field border label">
          <input
            type="text"
            value={formData.name}
            onChange={(e) => handleChange("name", e.target.value)}
          />
          <label>Name</label>
        </div>

        <div className="field textarea border label">
          <textarea
            value={formData.description}
            onChange={(e) => handleChange("description", e.target.value)}
          ></textarea>
          <label> Description</label>
        </div>

        <MdMultiSelect
          label="Tools"
          options={
            toolsList && !!toolsList.length
              ? toolsList.map((tool: Tool) => ({
                  label: tool.name,
                  value: tool.id,
                }))
              : []
          }
          selectedValues={formData.tools.map((tool: Tool) => tool.id)}
          onChange={(value: any) => handleChange("tools", value, toolsList)}
        />
      </div>

      <div className="flex right-align">
        <button onClick={handleSubmit}>Add</button>
      </div>
    </dialog>
  );
}
