import React, { useState } from "react";

interface Option {
  label: string;
  value: any;
}

interface MultiSelectProps {
  label: string;
  options: Option[];
  selectedValues: any[];
  onChange: (values: any[]) => void;
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  options,
  selectedValues,
  onChange,
  label,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState("");

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleCheckboxChange = (value: any) => {
    if (selectedValues.includes(value)) {
      onChange(selectedValues.filter((v) => v !== value));
    } else {
      onChange([...selectedValues, value]);
    }
  };

  const handleChipRemove = (value: any) => {
    onChange(selectedValues.filter((v) => v !== value));
  };

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchText.toLowerCase())
  );

  
 const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        <div className="field suffix border label" onClick={toggleDropdown}>
          <input
             type="text"
           placeholder={`Tools`}
            value={searchText}
            onChange={handleInputChange}
           onFocus={() => setIsOpen(true)}
          />
           <label className="small-opacity">{label}</label>
          <i className="arrow_drop_down">arrow_drop_down</i>
     </div>
        {isOpen && (
          <div
          className="surface-bright border"
            style={{
              position: "absolute",
              top: "100%",
              left: 0,
              right: 0, 
              borderRadius:"0",
              maxHeight: "200px",
              overflowY: "auto",
              zIndex: 2,
              padding: "8px 0",
            }}
          >
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <label
                  key={option.value}
                  className="checkbox"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "4px 8px",
                    cursor: "pointer",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={selectedValues.includes(option.value)}
                    onChange={() => handleCheckboxChange(option.value)}
                    style={{ marginRight: "8px" }}
                  />
                  <span>{option.label}</span>
                </label>
              ))
            ) : (
              <div style={{ padding: "8px", textAlign: "center" }}>
                No results found
              </div>
            )}
          </div>
        )}
      </div>
     {selectedValues.length > 0 && (
     
       <div
       className="border"
        style={{
          marginTop: "1rem",
          marginBottom: "2rem",
          maxHeight: "100px",
          overflowY: "auto",
          padding: "0.5rem",
          borderRadius:"5px"
        }}
      >
        {selectedValues.map((value) => {
          const selectedOption = options.find((option) => option.value === value);
          return (
            selectedOption && (
              <a
                className="chip border"
                key={value}
                onClick={() => handleChipRemove(value)}
                style={{
                  marginBottom: "1rem",
                  cursor: "pointer",
                }}
              >
                {selectedOption.label}
                <i>close</i>
              </a>
            )
          );
        })}
      </div> 
      )}
      
    </>
  );
};

export default MultiSelect;