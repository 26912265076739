import React from "react";
import { Outlet } from "react-router-dom";
import Navigation from "../components/material-design/components/Navigation";

const AdminLayout: React.FC = () => {
  return (
    <div
      style={{ display: "flex", flexDirection: "row", height: "100vh" }}
      className="surface"
    >
      <Navigation setSideBar={() => {}} />
      <div
        className="surface"
        style={{
          display: "flex",
          flexDirection: "column",
          flex: "1 1 auto",
        }}
      >
        <header
          className="fixed no-padding surface"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <i className="extra tiny-margin">
              <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 332.3 392.83"
              >
                <path
                  className="cls-1"
                  d="M467.22,431.53c9.63-2,17,1.2,20.11,10.59,2.44,7.3.19,14.16-6.87,18.13-8.8,4.94-15.65,3.62-21.92-5.55-.4-.58-.65-1.3-1.07-1.8-15.47-18.47-24.64-19.46-36.82-9.57-12.6,10.23-7.55,27.1,1,39.42,7.6,11,10.53,21.39,3.94,33.16a25.59,25.59,0,0,1-36.67,8.44c-13.71-9.54-16.67-22.59-7.76-36.64,4.49-7.1,4.72-9.54,9.92-22.2,2.9-7,8-18-3.9-22.15-10.74-3.76-16.28.26-24.49,12.42-2.43,3.59-3.34,9.15-6,13.09-5.3,7.76-13.22,10.78-22.14,7.47-9.77-3.62-14.13-11.49-12.63-21.78,1.37-9.47,7.29-14.79,17-15.91,6.62-.76,13.52,3.24,19.86-3.13,8-8,18.66-15.29,14.76-28-3.72-12.08-12.08-21.76-25.85-24.49-13.61-2.7-20.29-9.93-14.09-23.89,2.35-5.27,7.56-8.34,13.82-8.08,8,.34,12.18,5.19,15.26,12.32C374,389.84,400.63,398.48,426,384.63c7.82-4.26,12.05-9.34,13.38-19.18,2-14.74,13-21.46,27.74-18.87,12.88,2.26,19.75,11.65,19,24.14-.82,13.43-9.06,21.67-23.22,22.69-4.79.35-10.28-2.43-14.24,1.49C442.5,401,432,404.54,433.87,416,435.2,424,453.81,432.38,467.22,431.53Z"
                  transform="translate(-238.77 -241.02)"
                />
                <path
                  className="cls-1"
                  d="M404.92,633.85a50.77,50.77,0,0,1-29.14-9.15,321,321,0,0,1-137-262.8,327,327,0,0,1,1.78-34,51.36,51.36,0,0,1,33.38-42.68l113.63-41.14a51.22,51.22,0,0,1,34.71,0l113.64,41.14a51.37,51.37,0,0,1,33.37,42.68,325.81,325.81,0,0,1,1.78,34,321,321,0,0,1-137,262.8A50.74,50.74,0,0,1,404.92,633.85Zm0-369.22a27.4,27.4,0,0,0-9.33,1.63L282,307.39a27.63,27.63,0,0,0-17.93,23,301.36,301.36,0,0,0-1.65,31.55A297.39,297.39,0,0,0,389.31,605.36a27.34,27.34,0,0,0,31.21,0A297.38,297.38,0,0,0,547.45,361.9a300.37,300.37,0,0,0-1.65-31.55,27.61,27.61,0,0,0-17.93-23L414.23,266.26A27.25,27.25,0,0,0,404.92,264.63Z"
                  transform="translate(-238.77 -241.02)"
                />
              </svg>
            </i>
            <h6 className="no-margin"> Fusion Security</h6>
            {/* <i className="extra small-margin">
              <img src="/TrustGenie.png"></img>
            </i>
            <h6 className="no-margin">Trust Genie</h6>
            <span
              style={{
                marginTop: "15px",
                marginLeft: "5px",
              }}
            >
              Powered by Agentforce on Einstein 1
            </span> */}
          </div>
        </header>
        <main
          className="small-round height-max right-margin bottom-margin vertical-padding surface-container-lowest"
          style={{
            height: "90vh",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
          }}
        >
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default AdminLayout;
