import { useEffect, useMemo, useRef, useState } from "react";
import {
  Avatar,
  Card,
  Container,
  createStyles,
  Flex,
  Group,
  Image,
  Paper,
  SimpleGrid,
  Stack,
  Text,
  Title,
  useMantineTheme,
  Anchor,
  Badge,
  Collapse,
  Tabs,
  Blockquote,
} from "@mantine/core";
import { useDisclosure, useScrollIntoView } from "@mantine/hooks";

import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
  Label,
} from "recharts";
import {
  createNewConversation,
  getConversationChat,
  getSharedConversationPublic,
  infoAPI,
  sendConversationQuery,
  uploadCSVFileToConversation,
} from "../../../lib/APIService";
import { DefaultQuestions } from "../../../lib/Data";
import UploadFile from "../../UploadFile";
import { useNavigate, useParams } from "react-router-dom";
import Dashboard from "../../../modules/dashboard/Dashboard";
import { Typography } from "@neo4j-ndl/react";
import { IconFileText } from "@tabler/icons-react";
import axios from "axios";
import { calcWordColor } from "@neo4j-devtools/word-color";
import MarkdownText from "../../MarkdownText";
import ChatInput from "../../material-design/components/ChatInput";
import "../../Conversation.css";
import CsvTable from "./CsvTable";
interface Source {
  title: string;
  url: string;
}
interface MessageData {
  type: string;
  content: string;
  showGraph?: boolean;
  graphType?: string;
  newReply?: boolean;
  sources?: Source[];
  additional_kwargs?: any;
  chartData?: any;
  chat_metadata?: any;
  image?: string;
  chat_html?: string;
}

export type Entity = {
  element_id: string;
  labels: string[];
  properties: {
    id: string;
  };
};

export type GroupedEntity = {
  texts: Set<string>;
  color: string;
};

const CHAT_ENDPOINT =
  process.env.REACT_APP_CHAT_BACKEND_URL || "http://localhost:4002";
const USER_ENDPOINT =
  process.env.REACT_APP_BACKEND_URL || "http://localhost:4000";

const useStyles = createStyles((theme) => ({
  text: {
    fontWeight: 400,
    size: 16,
    fontSize: 14,
  },
}));

const PdfViewer = (pdfName: any) => {
  const handleOpenPdf = () => {
    const accessToken = localStorage.getItem("accessToken");
    let pdfUrl = `${USER_ENDPOINT}/api/v1/docs/${pdfName["pdfName"]}?role=${pdfName["role"]}`;
    if (pdfUrl.includes("/tmp/")) {
      pdfUrl = pdfUrl.replace("/tmp/", "");
    }
    if (pdfUrl.includes("/data/documents/")) {
      pdfUrl = pdfUrl.replace("/data/documents/", "");
    }
    console.log(pdfUrl);
    axios
      .get(pdfUrl, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], { type: "application/pdf" });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        const pdfWindow = window.open();
        if (pdfWindow) {
          pdfWindow.location.href = fileURL;
        }
      });
  };

  return (
    <div>
      <Anchor onClick={handleOpenPdf}>
        {pdfName["index"]}. {pdfName["pdfName"].replace("/tmp/", "")}
      </Anchor>
    </div>
  );
};

const ConversationMD = (props: any) => {
  const [conversation, setConversation] = useState({} as any);
  const [conversationId, setConversationId] = useState(props.conversation_id);
  const [messages, setMessages] = useState<MessageData[]>([]);
  const [replyLoading, setReplyLoading] = useState(false);
  const loaderRef = useRef<HTMLDivElement>(null);
  const [streamData, setStreamData] = useState("");
  const [jsonData, setJsonData] = useState([]);
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const [streamingComplete, setStreamingComplete] = useState(false);
  const [newConvId, setNewConvId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [toolStart, setToolStart] = useState("");
  const [toolEnd, setToolEnd] = useState(false);
  const [intermediateSteps, setIntermediateSteps] = useState([""]);
  const [k8sSteps, setK8sStep] = useState([] as any);

  const navigate = useNavigate();

  const newConversation = async (message: string, _role: string) => {
    if (!message) return;
    setMessages([...messages, { type: "human", content: message }]);
    setReplyLoading(true);
    const res = await createNewConversation(_role);
    if (res.status === "ok") {
      if (_role === "trust-expert") {
        setNewConvId(res.conversation_id);
        await fetchData(message, res.conversation_id);
      } else {
        await sendConversationQuery(message, res.conversation_id, props.model);
        if (props.refreshConversations) {
          props.refreshConversations();
        }
        props.setNewChat(false);
        navigate(`/home/chat/conversation/${res.conversation_id}`);
      }
    }
    setReplyLoading(false);
  };
  const fetchData = async (message: any, convId?: any) => {
    const url = `${CHAT_ENDPOINT}/${localStorage.getItem(
      "workspace_id"
    )}/conversations/${
      conversationId || convId
    }/chat?message=${message}&model=${props.model}`;
    const bearerToken = localStorage.getItem("bearerToken");

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    if (response.body) {
      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let leftover = "";
      let json_data = false;

      return new ReadableStream({
        async start(controller) {
          while (true) {
            const { done, value } = await reader.read();
            setReplyLoading(false);
            if (done) {
              if (!!leftover) {
                try {
                  const parsed_json = JSON.parse(leftover);
                  setJsonData(parsed_json);
                } catch (error) {
                  console.log(error);
                }
              }
              setStreamingComplete(true);
              setToolEnd(false);
              setToolStart("");
              setK8sStep((k8sSteps: any) => []);

              break;
            }
            const text = decoder.decode(value);
            controller.enqueue(text);
            const fullText = leftover + text;
            const parts = fullText.split("ENDOFTEXT");
            if (text.includes("TOOLSTART")) {
              setToolStart(text.replace("TOOLSTART", ""));
              setIntermediateSteps((intermediateSteps) => [
                ...intermediateSteps,
                text,
              ]);
              scrollToLoader(0);

              continue;
            }
            if (text.includes("TOOLEND")) {
              // setToolStart("");
              setToolEnd(true);

              setIntermediateSteps((intermediateSteps) => [
                ...intermediateSteps,
                text,
              ]);
              continue;
            }
            if (text.includes("K8SAGENT")) {
              setIntermediateSteps((intermediateSteps) => [
                ...intermediateSteps,
                text,
              ]);
              setK8sStep((k8sSteps: any) => [...k8sSteps, text]);
              continue;
            }
            if (text && text.includes("$$$$$$$$$$$$$$$$$$$$$$$$$$$")) {
              setReplyLoading(true);
            }
            if (parts.length > 1) {
              // Render all text chunks received before the delimiter
              try {
                leftover = parts[1]; // Anything after the delimiter is considered as leftover for JSON

                setReplyLoading(false);
                setJsonData(JSON.parse(leftover));
              } catch (error) {
                console.log(error);
              }

              json_data = true;
            } else if (json_data) {
              leftover += text;
            } else if (!json_data) {
              // No delimiter found, render the text as it comes
              if (text && !text.includes("$$$$$$$$$$$$$$$$$$$$$$$$$$$")) {
                // setToolEnd(false);
                setStreamData((prevData) => prevData + text);
                scrollToLoader(0);
                leftover = ""; // Clear leftover since it's all rendered as text
              }
            }
          }
          controller.close();
          reader.releaseLock();
        },
      });
    } else {
      setReplyLoading(false);
      console.error("Response body is null");
    }
  };

  const sendQuery = async (message: string) => {
    if (!message) return;
    scrollToLoader();
    setStreamingComplete(false);
    setReplyLoading(true);
    if (!conversationId) {
      return newConversation(message, "trust-expert");
    }
    setMessages([...messages, { type: "human", content: message }]);

    if (
      conversation.role === "data-analyst" ||
      conversation.role === "executive-assistant" ||
      conversation.role === "trust-expert-graph-rag"
    ) {
      const response = await sendConversationQuery(
        message,
        conversationId,
        props.model
      );
      setReplyLoading(false);
      return setMessages([
        ...messages,
        { type: "human", content: message },
        {
          type: "ai",
          content: response.message,
          newReply: true,
          sources: response.sources.slice(0, 1),
          chartData: response.chartData,
          showGraph: !!response.chartData?.length,
          graphType: "bar",
          image: response.image,
          chat_html: response.chat_html,
          chat_metadata: response.chat_metadata,
        },
      ]);
    } else {
      fetchData(message);
    }
  };

  const scrollToLoader = (timeout = 500) => {
    setTimeout(() => {
      if (loaderRef?.current) {
        const elem = loaderRef.current as Element;
        elem.scrollIntoView({ behavior: "smooth" });
      }
    }, timeout);
  };

  const fetchChat = async () => {
    if (!!conversationId) {
      setLoading(true);
      const response = props.isShared
        ? await getSharedConversationPublic(conversationId)
        : await getConversationChat(conversationId);
      if (response?.conversation) {
        setConversation(response.conversation);
        if (props.setChatName) {
          props.setChatName(response?.conversation?.title! || "Untitled");
        }
        if (props.setCurrentRole) {
          props.setCurrentRole(response.conversation.role);
        }
      }
      setLoading(false);
      if (response?.chat_history?.length) {
        setMessages(
          response.chat_history.reverse().map((item: MessageData) => {
            item.content = item.content.replace(/\[\$\{[0-9]\}\]+/g, "\n");
            item.sources =
              item.content.includes("Hmm, I'm not sure") ||
              item.content.includes("how can I help you") ||
              item.content.includes("hello") ||
              item.content.includes("Hello")
                ? []
                : item.additional_kwargs?.sources?.slice(0, 1);
            item.chartData =
              (item.chat_metadata && item.chat_metadata["graph_data"]) ||
              item.chat_metadata;
            item.showGraph = item.chartData?.length > 0;
            item.graphType = "bar";
            // item.chat_metadata = item?.chat_metadata;
            item.chat_metadata = {
              ...item.chat_metadata,
              sources: item.additional_kwargs?.sources?.slice(0, 1) || [],
            };

            return item;
          })
        );
      } else {
        setMessages([]);
      }
    }
  };

  useEffect(() => {
    if (!!streamingComplete) {
      if (!conversationId) {
        if (props.refreshConversations) {
          props.refreshConversations();
        }
        props.setNewChat(false);
        navigate(`/home/chat/conversation/${newConvId}`);
      } else {
        setMessages([
          ...messages,
          {
            type: "ai",
            content: streamData.replaceAll("\\n", "\n"),
            newReply: true,
            sources:
              conversation.role === "executive-assistant" ? [] : jsonData,
            chartData:
              conversation.role === "executive-assistant" ||
              conversation.role === "awsexpert" ||
              conversation.role === "anypoint-trust" ||
              conversation.role === "k8s-analyst" ||
              conversation.role === "multiagent-graph"
                ? jsonData
                : [],
            showGraph:
              (conversation.role === "awsexpert" ||
                conversation.role === "k8s-analyst" ||
                conversation.role === "multiagent-graph") &&
              jsonData.length > 0
                ? true
                : false,
            graphType:
              (conversation.role === "awsexpert" ||
                conversation.role === "k8s-analyst" ||
                conversation.role === "multiagent-graph") &&
              jsonData.length > 0
                ? "bar"
                : "",
            chat_metadata: { steps: intermediateSteps },
          },
        ]);
        setIntermediateSteps([""]);
      }
    }
    setStreamData("");
    setJsonData([]);
  }, [streamingComplete]);

  useEffect(() => {
    fetchChat();
  }, [conversationId]);

  useEffect(() => {
    scrollToLoader();
  }, [messages]);

  useEffect(() => {
    setConversationId(props.conversation_id);
  }, [props.conversation_id]);

  return (
    <div
      className=""
      style={{
        display: "flex",
        position: "relative",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <div
        className="small-padding"
        style={{
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          height: "92%",
          width: "100%",
          overflowY: "scroll",
          scrollBehavior: "smooth",
        }}
      >
        {!!messages.length ? (
          <></>
        ) : (
          <div
            className=""
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignContent: "center",
              alignItems: "center",
              alignSelf: "center",
              flexDirection: "column",
              height: "100%",
              width: "80%",
            }}
          >
            <h6 className="meidum bottom-margin secondary-text padding margin">
              Welcome {localStorage.getItem("firstName")}, how can I assist you
              today?
            </h6>
            <img
              src="/md-fs.svg"
              alt="Logo"
              style={{
                width: "40px",
                height: "40px",
                opacity: 0.8,
                marginTop: "80px",
                marginBottom: "40px",
              }}
            ></img>

            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                alignContent: "center",
                alignItems: "center",
                alignSelf: "center",
                flexDirection: "row",
                gap: "20px",
                width: "100%",
                paddingBottom: "20px",
              }}
            >
              {DefaultQuestions.map((question, index) => {
                return (
                  <div
                    key={index}
                    className="cursor-pointer"
                    style={{ flex: "1 1 calc(33.333% - 20px)" }}
                    onClick={() => {
                      if (props.setCurrentRole) {
                        props.setCurrentRole(question.role);
                      }
                      newConversation(question.query, question.role);
                    }}
                  >
                    <article className="surface medium-padding">
                      <div
                        className="row"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          className="max"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <i className="surface circle medium tiny-padding large">
                            <img src={question.icon}></img>
                          </i>
                          <h6 className="small strong small-margin">
                            {question.label}
                          </h6>
                          {/* <p className="tiny-line">{question.text}</p> */}
                        </div>
                      </div>
                    </article>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {!!messages?.length &&
          messages.map((message, index) => {
            return (
              <div key={index}>
                <ConversationChat
                  message={message}
                  index={index}
                  model={props.model}
                  isShared
                  conversation={conversation}
                  messages={messages}
                  sendQuery={sendQuery}
                />
              </div>
            );
          })}
        {(!!streamData.length ||
          !!jsonData.length ||
          toolStart ||
          toolEnd ||
          k8sSteps.length > 0) && (
          <div
            className="vertical-margin"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              alignContent: "centre",
            }}
          >
            <i className="extra align-center tiny-margin">
              <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 332.3 392.83"
              >
                <path
                  className="cls-1"
                  d="M467.22,431.53c9.63-2,17,1.2,20.11,10.59,2.44,7.3.19,14.16-6.87,18.13-8.8,4.94-15.65,3.62-21.92-5.55-.4-.58-.65-1.3-1.07-1.8-15.47-18.47-24.64-19.46-36.82-9.57-12.6,10.23-7.55,27.1,1,39.42,7.6,11,10.53,21.39,3.94,33.16a25.59,25.59,0,0,1-36.67,8.44c-13.71-9.54-16.67-22.59-7.76-36.64,4.49-7.1,4.72-9.54,9.92-22.2,2.9-7,8-18-3.9-22.15-10.74-3.76-16.28.26-24.49,12.42-2.43,3.59-3.34,9.15-6,13.09-5.3,7.76-13.22,10.78-22.14,7.47-9.77-3.62-14.13-11.49-12.63-21.78,1.37-9.47,7.29-14.79,17-15.91,6.62-.76,13.52,3.24,19.86-3.13,8-8,18.66-15.29,14.76-28-3.72-12.08-12.08-21.76-25.85-24.49-13.61-2.7-20.29-9.93-14.09-23.89,2.35-5.27,7.56-8.34,13.82-8.08,8,.34,12.18,5.19,15.26,12.32C374,389.84,400.63,398.48,426,384.63c7.82-4.26,12.05-9.34,13.38-19.18,2-14.74,13-21.46,27.74-18.87,12.88,2.26,19.75,11.65,19,24.14-.82,13.43-9.06,21.67-23.22,22.69-4.79.35-10.28-2.43-14.24,1.49C442.5,401,432,404.54,433.87,416,435.2,424,453.81,432.38,467.22,431.53Z"
                  transform="translate(-238.77 -241.02)"
                />
                <path
                  className="cls-1"
                  d="M404.92,633.85a50.77,50.77,0,0,1-29.14-9.15,321,321,0,0,1-137-262.8,327,327,0,0,1,1.78-34,51.36,51.36,0,0,1,33.38-42.68l113.63-41.14a51.22,51.22,0,0,1,34.71,0l113.64,41.14a51.37,51.37,0,0,1,33.37,42.68,325.81,325.81,0,0,1,1.78,34,321,321,0,0,1-137,262.8A50.74,50.74,0,0,1,404.92,633.85Zm0-369.22a27.4,27.4,0,0,0-9.33,1.63L282,307.39a27.63,27.63,0,0,0-17.93,23,301.36,301.36,0,0,0-1.65,31.55A297.39,297.39,0,0,0,389.31,605.36a27.34,27.34,0,0,0,31.21,0A297.38,297.38,0,0,0,547.45,361.9a300.37,300.37,0,0,0-1.65-31.55,27.61,27.61,0,0,0-17.93-23L414.23,266.26A27.25,27.25,0,0,0,404.92,264.63Z"
                  transform="translate(-238.77 -241.02)"
                />
              </svg>
              {/* <img src="/einsteinblue.svg" alt="" /> */}
            </i>
            <div className="">
              {toolStart ? (
                <div className="tiny-margin">
                  <span className="tiny-line rounded tiny-padding  primary-container">
                    {toolStart}
                    {!toolEnd ? (
                      <progress
                        className="circle small horizontal-margin tiny-padding"
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      ></progress>
                    ) : (
                      <i className="tiny fill">check</i>
                    )}
                  </span>
                </div>
              ) : null}
              {toolEnd ? (
                <div className="tiny-margin">
                  <span className="tiny-line rounded tiny-padding  primary-container">
                    Summarizing response
                    {toolEnd && !streamData ? (
                      <progress
                        className="circle small horizontal-margin tiny-padding"
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      ></progress>
                    ) : (
                      <i className="tiny fill">check</i>
                    )}
                  </span>
                </div>
              ) : null}

              {streamData?.length ? (
                <>
                  <MarkdownText text={streamData.replaceAll("\\n", "\n")} />
                </>
              ) : null}

              {(conversation.role === "executive-assistant" ||
                conversation.role === "anypoint-trust" ||
                conversation.role === "multigraph-agent") &&
                jsonData.length > 0 && (
                  <Dashboard
                    graphData={jsonData}
                    lastConversation={1 === messages.length - 1}
                  />
                )}
              {conversation?.role === "trust-expert" && jsonData.length > 0 && (
                <Stack>
                  <Text size={"sm"}>Sources: </Text>
                  {jsonData.map((source: any, index) => {
                    return source["url"].includes("http") ? (
                      <Anchor key={index} href={source.url} target="_blank">
                        {index + 1}. {source.title}
                      </Anchor>
                    ) : (
                      <PdfViewer
                        pdfName={source.title}
                        role={conversation.role}
                        index={index + 1}
                      ></PdfViewer>
                    );
                  })}
                </Stack>
              )}
            </div>
          </div>
        )}

        {!!replyLoading && (
          <div className="group center-align">
            <progress className="circle small"></progress>
          </div>
        )}
        <div ref={loaderRef}></div>
      </div>
      {!props.hideSend && (
        <ChatInput disabled={replyLoading} sendQuery={sendQuery} />
      )}
    </div>
  );
};

const ConversationChat = (props: {
  message: MessageData;
  index: number;
  model: string;
  isShared: boolean;
  conversation: any;
  messages: MessageData[];
  sendQuery: any;
}): JSX.Element => {
  const { message, index, conversation, messages, sendQuery } = props;
  const [opened, { toggle }] = useDisclosure(false);

  const [chunkdata, setChunkData] = useState<any>("");
  const { conversationId } = useParams();
  const { classes } = useStyles();
  const [infoEntities, setInfoEntities] = useState<Entity[]>([]);

  const parseEntity = (entity: Entity) => {
    const { labels, properties } = entity;
    const label = labels[0];
    const text = properties.id;
    return { label, text };
  };
  console.log("Role --------->", conversation?.role);

  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>({
    offset: 60,
  });

  const groupedEntities = useMemo<{ [key: string]: GroupedEntity }>(() => {
    return infoEntities?.reduce((acc, entity) => {
      const { label, text } = parseEntity(entity);
      if (!acc[label]) {
        const newColor = calcWordColor(label);
        acc[label] = { texts: new Set(), color: newColor };
      }
      acc[label].texts.add(text);
      return acc;
    }, {} as Record<string, { texts: Set<string>; color: string }>);
  }, [infoEntities]);

  const labelCounts = useMemo(() => {
    const counts: { [label: string]: number } = {};
    infoEntities?.forEach((entity) => {
      const { labels } = entity;
      const label = labels[0];
      counts[label] = counts[label] ? counts[label] + 1 : 1;
    });
    return counts;
  }, [infoEntities]);

  const sortedLabels = useMemo(() => {
    return Object.keys(labelCounts).sort(
      (a, b) => labelCounts[b] - labelCounts[a]
    );
  }, [labelCounts]);

  let fileName = "";
  // const chartData = message.chartData || [];
  const chat_metadata = message?.chat_metadata || [];
  const steps = chat_metadata["steps"] || [];
  const csv_data = chat_metadata["csv_data"] || [];
  // chartData.forEach((item: any) => {
  //   const amount = parseFloat(item.amount);
  //   if (amount < 0) {
  //     item.amount = (-amount).toString();
  //   }
  // });
  if (!!conversation?.csv_docs) {
    let names = conversation.csv_docs.split(",");
    names.forEach((element: string) => {
      let name = element.split("/");
      fileName += name[name.length - 1] + ", ";
    });
  }

  const chartDetailQuery = (question: string) => {
    sendQuery(question);
  };

  const handleDetailsClick = async () => {
    const chunkids_arr = message?.chat_metadata?.info?.chunkids;
    let chunkId = "";
    chunkids_arr.forEach((chunkid: string) => {
      chunkId += chunkid + ",";
    });

    if (chunkId) {
      const response = infoAPI(chunkId, conversation.role);

      const data = await response;
      setChunkData(data?.data?.chunk_data);
      setInfoEntities(data?.data?.nodes);
    }
  };

  return message.type === "human" ? (
    <div
      key={index}
      className="vertical-margin"
      style={{
        display: "flex",
        justifyContent: "flex-end",
        flexDirection: "row",
      }}
    >
      <div
        className="message user small-padding "
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {message.content === "Give a detailed summary of the data"
          ? "Find the summary below"
          : message.content}
      </div>
      <img
        height={"38px"}
        width={"38px"}
        src={localStorage.getItem("image")!}
        alt="Avatar"
      />
    </div>
  ) : (
    <div
      key={index}
      className="vertical-margin"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        alignContent: "centre",
      }}
    >
      {/* <Avatar mt={8} size={"md"} src={"/genie-icon.png"} /> */}
      <i className="extra align-center tiny-margin">
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 332.3 392.83"
        >
          <path
            className="cls-1"
            d="M467.22,431.53c9.63-2,17,1.2,20.11,10.59,2.44,7.3.19,14.16-6.87,18.13-8.8,4.94-15.65,3.62-21.92-5.55-.4-.58-.65-1.3-1.07-1.8-15.47-18.47-24.64-19.46-36.82-9.57-12.6,10.23-7.55,27.1,1,39.42,7.6,11,10.53,21.39,3.94,33.16a25.59,25.59,0,0,1-36.67,8.44c-13.71-9.54-16.67-22.59-7.76-36.64,4.49-7.1,4.72-9.54,9.92-22.2,2.9-7,8-18-3.9-22.15-10.74-3.76-16.28.26-24.49,12.42-2.43,3.59-3.34,9.15-6,13.09-5.3,7.76-13.22,10.78-22.14,7.47-9.77-3.62-14.13-11.49-12.63-21.78,1.37-9.47,7.29-14.79,17-15.91,6.62-.76,13.52,3.24,19.86-3.13,8-8,18.66-15.29,14.76-28-3.72-12.08-12.08-21.76-25.85-24.49-13.61-2.7-20.29-9.93-14.09-23.89,2.35-5.27,7.56-8.34,13.82-8.08,8,.34,12.18,5.19,15.26,12.32C374,389.84,400.63,398.48,426,384.63c7.82-4.26,12.05-9.34,13.38-19.18,2-14.74,13-21.46,27.74-18.87,12.88,2.26,19.75,11.65,19,24.14-.82,13.43-9.06,21.67-23.22,22.69-4.79.35-10.28-2.43-14.24,1.49C442.5,401,432,404.54,433.87,416,435.2,424,453.81,432.38,467.22,431.53Z"
            transform="translate(-238.77 -241.02)"
          />
          <path
            className="cls-1"
            d="M404.92,633.85a50.77,50.77,0,0,1-29.14-9.15,321,321,0,0,1-137-262.8,327,327,0,0,1,1.78-34,51.36,51.36,0,0,1,33.38-42.68l113.63-41.14a51.22,51.22,0,0,1,34.71,0l113.64,41.14a51.37,51.37,0,0,1,33.37,42.68,325.81,325.81,0,0,1,1.78,34,321,321,0,0,1-137,262.8A50.74,50.74,0,0,1,404.92,633.85Zm0-369.22a27.4,27.4,0,0,0-9.33,1.63L282,307.39a27.63,27.63,0,0,0-17.93,23,301.36,301.36,0,0,0-1.65,31.55A297.39,297.39,0,0,0,389.31,605.36a27.34,27.34,0,0,0,31.21,0A297.38,297.38,0,0,0,547.45,361.9a300.37,300.37,0,0,0-1.65-31.55,27.61,27.61,0,0,0-17.93-23L414.23,266.26A27.25,27.25,0,0,0,404.92,264.63Z"
            transform="translate(-238.77 -241.02)"
          />
        </svg>
        {/* <img src="/einsteinblue.svg" alt="" /> */}
      </i>
      <div className="padding surface">
        {steps.length > 1 &&
          steps.map((text: any, index: any) => {
            if (
              text.includes("Starting tool") ||
              text.includes("Done tool") ||
              text.includes("K8SAGENT")
            ) {
              return (
                <div className="tiny-margin" key={index}>
                  <span className="tiny-line rounded tiny-padding  primary-container">
                    {text.includes("Starting tool")
                      ? text.replace("TOOLSTART", "")
                      : text.includes("Done tool")
                      ? text.replace("TOOLEND", "")
                      : text.replace("K8SAGENT", "")}
                    <i className="tiny">check</i>
                  </span>
                </div>
              );
            }
            return null;
          })}
        {message.content?.length && (
          <>
            {message.content ? (
              <MarkdownText
                text={message.content?.replaceAll(
                  "sanjay-alvyl",
                  "ashok-yannam"
                )}
              />
            ) : null}
          </>
        )}

        {message.chat_metadata?.info?.sources?.length && (
          <span
            style={{ color: "#228be6", cursor: "pointer" }}
            onClick={() => {
              toggle();
              handleDetailsClick();
              scrollIntoView({
                alignment: "center",
              });
            }}
          >
            Details
          </span>
        )}
        {!!message.sources?.length && conversation?.role === "trust-expert" && (
          <span
            style={{ color: "#228be6", cursor: "pointer" }}
            onClick={() => {
              toggle();
              scrollIntoView({
                alignment: "center",
              });
            }}
          >
            Details
          </span>
        )}
        {csv_data.length > 0 && <CsvTable data={csv_data} />}
        {conversation?.role === "data-analyst" && index === 1 ? (
          conversation?.csv_docs?.length ? (
            <h6 className="small">Uploaded CSV,JSON file: {fileName}</h6>
          ) : (
            <UploadFile
              type={"csv,json"}
              uploadFileFunction={uploadCSVFileToConversation}
              conversationId={conversationId}
              sendQuery={sendQuery}
              model={props.model}
            />
          )
        ) : null}
        {conversation?.role === "data-analyst" ||
        conversation?.role === "executive-assistant" ||
        conversation?.role === "anypoint-trust" ||
        conversation?.role === "k8s-analyst" ||
        conversation?.role === "multiagent-graph"
          ? message.chartData &&
            message.chartData.length > 0 && (
              <Dashboard
                chartDetailQuery={chartDetailQuery}
                graphData={message.chartData}
                lastConversation={index === messages.length - 1}
                isShared
              />
            )
          : null}

        {(conversation?.role === "multiagent-graph" ||
          conversation?.role === "awsexpert") &&
        message?.chartData.length > 0 ? (
          message.graphType === "bar" ? (
            <BarChart
              width={1000}
              height={400}
              data={message.chartData}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis tick={{ fontSize: 10 }} dataKey="timeperiod">
                <Label value="Date" offset={-4} position="insideBottom" />
              </XAxis>
              <YAxis tick={{ fontSize: 10 }}>
                <Label value="USD" angle={-90} position="insideLeft" />
              </YAxis>
              <Tooltip />
              <Bar isAnimationActive={false} dataKey="amount" fill="#8884d8" />
            </BarChart>
          ) : null
        ) : null}

        <Collapse
          in={opened}
          transitionDuration={1000}
          transitionTimingFunction="linear"
          ref={targetRef}
        >
          <Blockquote color="blue" mt="xs">
            <Text c="dimmed" className={classes.text}>
              To generate this response, in{" "}
              {message?.chat_metadata?.info?.response_time} seconds we used 1182
              tokens with the model {message?.chat_metadata?.info?.model}.
            </Text>
          </Blockquote>
          <Tabs defaultValue="sources">
            <Tabs.List>
              <Tabs.Tab value="sources">Sources used</Tabs.Tab>
              {conversation.role === "trust-expert-graph-rag" ||
              message.chat_metadata?.info?.sources.length > 0 ? (
                <>
                  <Tabs.Tab value="entities">Top Entities used</Tabs.Tab>
                  <Tabs.Tab value="chunks">Chunks</Tabs.Tab>
                </>
              ) : null}
            </Tabs.List>

            <Tabs.Panel value="sources">
              <div
                style={{
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {(conversation.role === "trust-expert-graph-rag" ||
                  conversation.role === "anypoint-trust") &&
                message.chat_metadata?.info?.sources.length > 0
                  ? Array.from(
                      new Set(
                        Array.from(chunkdata).map(
                          (chunk: any) => chunk.fileName
                        )
                      )
                    )
                      .map((fileName: any) => {
                        return chunkdata.find(
                          (chunk: any) => chunk.fileName === fileName
                        );
                      })
                      .map((chunk: any, index) => (
                        <div
                          key={index}
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <IconFileText />
                          {chunk.fileName.includes("http") ? (
                            <Anchor
                              key={index}
                              href={chunk["fileName"]}
                              target="_blank"
                              className={classes.text}
                            >
                              {index + 1}. {chunk["fileName"]}
                            </Anchor>
                          ) : (
                            <PdfViewer
                              pdfName={chunk && chunk["fileName"]}
                              role={conversation.role}
                              index={index + 1}
                              className={classes.text}
                            ></PdfViewer>
                          )}
                        </div>
                      ))
                  : message.chat_metadata?.sources?.length > 0 &&
                    Array.from(
                      new Set(
                        (message.sources || []).map((source) => source.url)
                      )
                    )
                      .map((url) => {
                        return (message.sources || []).find(
                          (source) => source.url === url
                        );
                      })
                      .map((source: any, index: any) => {
                        return source["url"]?.includes("http") ? (
                          <Anchor
                            key={index}
                            href={source["url"]}
                            target="_blank"
                            className={classes.text}
                          >
                            {index + 1}. {source["title"]}
                          </Anchor>
                        ) : (
                          <PdfViewer
                            pdfName={source["title"]}
                            role={conversation.role}
                            index={index + 1}
                          ></PdfViewer>
                        );
                      })}
              </div>
            </Tabs.Panel>

            {conversation.role === "trust-expert-graph-rag" ||
            message.chat_metadata?.info?.sources.length > 0 ? (
              <>
                <Tabs.Panel value="entities">
                  {Object.keys(groupedEntities).length > 0 ? (
                    <ul className="list-none max-h-80 overflow-auto">
                      {sortedLabels.map((label, index) => (
                        <li key={index}>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <div className="legend mr-2">
                              {label} ({labelCounts[label]}) -
                            </div>
                            <div className="flex flex-wrap">
                              {Array.from(groupedEntities[label].texts)
                                .slice(0, 3)
                                .map((text, idx) => (
                                  <Typography
                                    key={idx}
                                    // className="entity-text text-ellipsis whitespace-nowrap max-w-[calc(100%-120px)] overflow-hidden"
                                    variant="body-medium"
                                    className={classes.text}
                                  >
                                    {text + ", "}
                                  </Typography>
                                ))}
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No entities available</p>
                  )}
                </Tabs.Panel>

                <Tabs.Panel value="chunks" style={{ padding: "10px" }}>
                  {Array.from(chunkdata).map((chunk: any, index: any) => (
                    <div
                      key={index}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <span
                        style={{ marginBottom: "10px" }}
                        className={classes.text}
                      >
                        page_number: {chunk.page_number}
                      </span>
                      <span className={classes.text}>{chunk.text}</span>
                      <br></br>
                    </div>
                  ))}
                </Tabs.Panel>
              </>
            ) : null}
          </Tabs>
        </Collapse>
      </div>
    </div>
  );
};

export default ConversationMD;
