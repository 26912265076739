import {
    ResponsiveContainer,
    Treemap,
  } from "recharts";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

export default function TGTreeMap(props: any){
    const {data, height, allowAnimation} = props
    return (
        <ResponsiveContainer width="100%" height={height || 300}>
          <Treemap
            isAnimationActive={allowAnimation || false}
            width={400}
            height={200}
            data={data}
            dataKey="size"
            // ratio={4 / 3}
            stroke="#fff"
            fill="#8884d8"
          />
        </ResponsiveContainer>
    )
}