import { useEffect, useState } from "react";
import { getTools } from "../lib/APIService";
import Card from "./material-design/components/Card";

interface Tool {
  id: any;
  name: string;
  description?: string;
  iconSrc?: string;
  createdDate?: any;
  updatedDate?: any;
}

const inputStyles: React.CSSProperties = {
  padding: "8px",
  boxSizing: "border-box",
  width: "500px",
};

const textareaStyles: React.CSSProperties = {
  padding: "8px",
  height: "150px",
  boxSizing: "border-box",
  width: "500px",
};

export default function Tools() {
  const [toolsList, setToolsList] = useState<Tool[]>([]);
  const [selectedTool, setSelectedTool] = useState<Tool | null>(null);

  useEffect(() => {
    const getToolsList = async () => {
      const response = await getTools();
      const cleanedTools = response?.map((tool: Tool) => {
        const { createdDate, updatedDate, name, ...rest } = tool;
        return {
          ...rest,
          name: name.replace(/_/g, " "), // Replace underscores with spaces
        };
      });
      setToolsList(cleanedTools);
    };

    getToolsList();
  }, []);

  const handleToolClick = (tool: Tool) => {
    setSelectedTool(tool);
  };

  const handleBackClick = () => {
    setSelectedTool(null);
  };

  const getIconSrc = (toolName: string) => {
    if (toolName === "K8s Analyst") {
      return "/trivy.svg";
    }
    if (toolName.includes("K8s") || toolName.includes("K8")) {
      return "/kubernetes.svg";
    }
    if (toolName.includes("EC2")) {
      return "/EC2.svg";
    }
    if (toolName.includes("Aws Loadbalancer")) {
      return "/ELB.svg";
    }
    if (toolName.includes("Aws Cost explorer boto client")) {
      return "/cost_explorer.svg";
    }
    if (toolName.includes("Aws Forecast")) {
      return "/forecast.svg";
    }
    if (toolName.includes("Incident Analyser")) {
      return "/cloudtrail.svg";
    }
    if (toolName.includes("RCA")) {
      return "/rca.svg";
    }
    if (toolName.includes("Cost Optimiser")) {
      return "/cost-optimiser.svg";
    }
    if (toolName.includes("Aws")) {
      return "/aws-com.svg";
    }

    if (toolName.includes("Anypoint")) {
      return "/salesforce.svg";
    }
    if (toolName.includes("Data analyst visualization tool")) {
      return "/data-visualization.svg";
    }
    if (toolName.includes("Executive Dashboard helper")) {
      return "/executive-dashboard.svg";
    }
    if (toolName.includes("Perplexity")) {
      return "/perplexity_ai.svg";
    }
    if (toolName.includes("Snyk")) {
      return "/snyk.svg";
    }

    if (toolName.includes("Wiz")) {
      return "/wiz.svg";
    }
    if (toolName.includes("CheckPoint")) {
      return "/checkpoint.png";
    }
    if (toolName.includes("CrowdStrike")) {
      return "/crowdstrike.svg";
    }
    if (toolName.includes("Lacework")) {
      return "/lacework.svg";
    }
    if (toolName.includes("Microsoft")) {
      return "/microsoft.svg";
    }
    if (toolName.includes("Orca")) {
      return "/orca.png";
    }
    if (toolName.includes("PaloAlto")) {
      return "/paloalto.svg";
    }
    if (toolName.includes("PingSafe")) {
      return "/PingSafe.jpeg";
    }
    if (toolName.includes("Sysdig")) {
      return "/sysdig.svg";
    }
    if (toolName.includes("JIRA")) {
      return "/jira-1.svg";
    }
  };

  return (
    <>
      {selectedTool ? (
        <div>
          <nav>
            <button className="circle transparent" onClick={handleBackClick}>
              <i>arrow_back</i>
            </button>
            <div className="chat-name">View Tool</div>
          </nav>

          <div style={{ margin: "3rem" }}>
            <div className="field border">
              <label>Tool Name:</label>
              <input
                type="text"
                value={selectedTool.name}
                readOnly
                style={inputStyles}
              />
            </div>
            <div className="field border">
              <label>Tool Description:</label>
              <textarea
                value={selectedTool.description || "No description available"}
                readOnly
                style={textareaStyles}
              />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div style={{ overflowY: "auto", height: "100vh" }}>
            <div>
              <nav
                className="left-padding bottom-padding right-padding"
                style={{
                  borderBottom: "1px solid #ddd",
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "8px 0px 0px 0px",
                }}
              >
                <div className="chat-name" style={{ marginBottom: "8px" }}>
                  Tools
                </div>
              </nav>
            </div>

            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                alignItems: "flex-start",
                alignContent: "flex-start",
                scrollBehavior: "smooth",
                paddingBottom: "100px",
                justifyContent: "center",
              }}
            >
              {toolsList.map((tool) => (
                <Card
                  key={tool.id}
                  id={tool.id}
                  name={tool.name}
                  iconSrc={getIconSrc(tool.name)}
                  description={tool.description}
                  onClick={() => handleToolClick(tool)}
                  align="center"
                />
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
}
