import "./App.css";
import TrustGenieRouter from "./Routes";
import { GoogleOAuthProvider } from "@react-oauth/google";

import "beercss";
import "material-dynamic-colors";

function App() {
  const googleClientId =
    process.env.GOOGLE_OAUTH_CLIENT_ID ||
    "1094668557964-inc03esr29kf20t6pvnp83kearv89f47.apps.googleusercontent.com";

  return (
    <div>
      <GoogleOAuthProvider clientId={googleClientId}>
        <TrustGenieRouter />
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
