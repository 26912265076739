import "../../../App.css"; // Custom styling if needed

export default function CsvTable(props: any) {
  const { data } = props;

  const convertTexttoCVE = (text: string) => {
    const text_array = text.split(";");
    const cve_array = (
      <div>
        {text_array.map((cvedata: string) => {
          const cveMatch = cvedata.match(/CVE-[0-9]{4}-[0-9]+/g);
          const cve = cveMatch ? cveMatch[0] : cvedata;
          const urlMatch = cvedata.match(
            /\(learn more at: (https?:\/\/[^\s)]+)\)/
          );
          const url = urlMatch ? urlMatch[1] : null;
          return cve && url ? (
            <>
              <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "pink",
                }}
              >
                {cve}
              </a>
              <br />
            </>
          ) : (
            cve
          );
        })}
      </div>
    );
    return cve_array;
  };

  return (
    <div className="container">
      <h5 className="margin">Detailed Report</h5>
      <div className="scrollable-table">
        <table className="table hover striped">
          <thead className="fill">
            <tr>
              <th>Kind</th>
              <th>Name</th>
              <th>Error Messages</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row: any, index: any) => (
              <tr key={index}>
                <td>{row.kind}</td>
                <td>{row.name}</td>
                <td>{convertTexttoCVE(row.error_messages)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
