import { useState } from "react";
import { createDocumentStoreAPI } from "../lib/APIService";

export default function AddDocumentStore({
  popup,
  setPopup,
  handleAddDocumentStore,
}: any) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("system"); 
  const [subType, setSubType] = useState(""); 

  const handleSubmit = async () => {
    if (name && type) {
      try {
        const payload :any= { name, type, description };
        if (type === "system" && subType) {
          payload["store_subtype"] = subType;
        }
        await createDocumentStoreAPI(payload);
        handleAddDocumentStore();
      } catch (error) {
        console.error("Error creating document store:", error);
      }
    }
  };

  return (
    <dialog
      open={popup}
      className="auto scroll modal large-padding surface"
      style={{
        width: "40%",
      }}
    >
      <div className="credential-header">
        <h5 className="small"> Add Document Store</h5>
        <button className="transparent square" onClick={() => setPopup(false)}>
          <i>close</i>
        </button>
      </div>

      <div className="top-padding">
        <div className="field border label">
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <label className="small-opacity">Name</label>
        </div>

        <div className="field textarea border label">
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
          <label className="small-opacity">Description</label>
        </div>

        <div className="field label suffix border">
          <select
            value={type}
            onChange={(e) => {
              setType(e.target.value);
              setSubType("");
            }}
          >
            <option value="system">System</option>
            <option value="s3">S3</option>
          </select>
          <label>Type</label>
          <i>arrow_drop_down</i>
        </div>

        {type === "system" && (
          <div className="field label suffix border">
            <select
              value={subType}
              onChange={(e) => setSubType(e.target.value)}
            >
              <option value="">Select Subtype</option>
              <option value="vector store">Vector Store</option>
              <option value="graph store">Graph Store</option>
            </select>
            <label>Subtype</label>
            <i>arrow_drop_down</i>
          </div>
        )}
      </div>

      <div className="flex right-align">
        <button onClick={handleSubmit} disabled={!name || (type === "system" && !subType)}>
          Add
        </button>
      </div>
    </dialog>
  );
}