export const getLLM = () => {
  return {
    data: [
      {
        loadMethods: {},
        label: "OpenAI",
        name: "openAI",
        version: 4,
        type: "OpenAI",
        icon: "/openai-svgrepo-com.svg",
        category: "LLMs",
        description: "Wrapper around OpenAI large language models",
        baseClasses: ["OpenAI", "BaseLLM", "BaseLanguageModel", "Runnable"],
        credential: {
          label: "Connect Credential",
          name: "credential",
          type: "credential",
          loadMethod: "getEnvs",
          credentialNames: ["openAIApi"],
        },
        inputs: [
          {
            label: "Model Name",
            name: "modelName",
            type: "options",
            default: "gpt-4-turbo",
            options: [
              {
                label: "gpt-3.5-turbo",
                value: "gpt-3.5-turbo",
              },
              {
                label: "gpt-4",
                value: "gpt-4",
              },
              {
                label: "gpt-4-turbo",
                value: "gpt-4-turbo",
              },
              {
                label: "gpt-4o",
                value: "gpt-4o",
              },

              {
                label: "gpt-4o-mini",
                value: "gpt-4o-mini",
              },
            ],
          },
          {
            label: "Temperature",
            name: "temperature",
            type: "number",
            min: 0,
            max: 1,
            step: 0.1,
            default: 0.7,
            optional: true,
          },
        ],
        filePath: "",
      },
      {
        loadMethods: {},
        label: "Anthropic",
        name: "Anthropic",
        version: 4,
        type: "Anthropic",
        icon: "/head-side-gear.svg",
        category: "LLMs",
        description:
          "Most intelligent model & Powerful model for highly complex tasks",
        baseClasses: ["Anthropic", "BaseLLM", "BaseLanguageModel", "Runnable"],
        inputs: [
          {
            label: "Model Name",
            name: "modelName",
            type: "options",
            options: [
              {
                label: "Claude 3.5 Sonnet",
                value: "claude-3-5-sonnet",
              },
              {
                value: "claude-3-opus	",
                label: "Claude 3 Opus	",
              },
              {
                value: "claude-3-sonnet",
                label: "Claude 3 Sonnet",
              },
              {
                value: "claude-3-haiku",
                label: "Claude 3 Haiku",
              },
            ],
          },
          {
            label: "Temperature",
            name: "temperature",
            type: "number",
            min: 0,
            max: 1,
            step: 0.1,
            default: 0.7,
            optional: true,
          },
        ],
        filePath: "",
      },
      {
        loadMethods: {},
        label: "Einstein",
        name: "Einstein",
        version: 4,
        type: "Einstein",
        icon: "/einstein.svg",
        category: "LLMs",
        description: "Einstein LLM AI language model",
        baseClasses: ["Einstein", "BaseLLM", "BaseLanguageModel", "Runnable"],
        inputs: [
          {
            label: "Model Name",
            name: "modelName",
            type: "options",
            options: [
              {
                label: "Einstein",
                value: "einstein",
              },
            ],
          },
          {
            label: "Temperature",
            name: "temperature",
            type: "number",
            min: 0,
            max: 1,
            step: 0.1,
            default: 0.7,
            optional: true,
          },
        ],
        filePath: "",
      },
    ],
  };
};

export const getDocumentStore = () => {
  return {
    data: [
      {
        loadMethods: {},
        label: "Document Store",
        name: "documentStore",
        version: 1,
        type: "Document",
        icon: "/document-viewer-svgrepo-com.svg",
        category: "Document Loaders",
        description: "Load data from pre-configured document stores",
        baseClasses: ["Document"],
        inputs: [
          {
            label: "Select Store",
            name: "selectedStore",
            type: "asyncOptions",
            loadMethod: "listStores",
          },
        ],
        outputs: [
          {
            label: "Document",
            name: "document",
            description:
              "Array of document objects containing metadata and pageContent",
            baseClasses: ["Document", "json"],
          },
          {
            label: "Text",
            name: "text",
            description: "Concatenated string from pageContent of documents",
            baseClasses: ["string", "json"],
          },
        ],
        filePath:
          "/Users/priyankamurahari/Documents/work/Flowise/packages/server/node_modules/flowise-components/dist/nodes/documentloaders/DocumentStore/DocStoreLoader.js",
      },
    ],
  };
};

export const getAllStores = () => {
  return [
    {
      name: "test",
      type: "vector store",
      id: "66a91b9be08fe9485ff8585b",
    },
    {
      name: "test-1",
      type: "vector store",
      id: "66a91bfe559ed33650467111",
    },
    {
      name: "test",
      type: "vector store",
      id: "66ab3326e57edc68d6d98b38",
    },
    {
      name: "test_graphstore",
      type: "graph store",
      id: "66ab3c87e57edc68d6d98b3a",
    },
  ];
};

export const getSupervisors = () => {
  return [
    {
      label: "Supervisor",
      name: "Supervisor".toLowerCase().replace(/ /g, ""),
      version: 2,
      type: "Supervisor",
      category: "Supervisors",
      icon: "https://api.dicebear.com/7.x/bottts/svg?seed=c7f38615-755d-4de4-a461-b36699651a39",
      hideOutput: false,
      baseClasses: ["Supervisor"],
      inputs: [
        {
          label: "Language Model",
          name: "llm",
          type: "BaseLanguageModel",
          description: "Language model used by the agent",
        },
        {
          label: "Description",
          name: "description",
          type: "string",
          default: "Need to add content",
          optional: true,
        },
      ],
    },
  ];
};
