import { Node, Edge, ReactFlowInstance } from "reactflow";

interface NodeInput {
  name: string;
  type: string;
  default?: string;
  list?: boolean;
  [key: string]: any;
}

interface NodeOutput {
  name: string;
  label: string;
  description?: string;
  type: string;
  baseClasses?: string[];
  isAnchor?: boolean;
  hidden?: boolean;
  [key: string]: any;
}

interface NodeData {
  nodeData: Record<string, string>;
  inputs?: any;
  outputs?: any;
  credential?: any;
  hideOutput?: boolean;
  name?: string;
  type?: string;
  description?: string;
  baseClasses?: string[];
  inputAnchors?: NodeInput[];
  inputParams?: InputParams[];
  outputAnchors?: NodeOutput[];
  [key: string]: any;
}

interface InputParams extends NodeInput {
  id: string;
}

interface OutputParams extends NodeOutput {
  id: string;
}

interface Connection {
  sourceHandle: string;
  targetHandle: string;
  target: string;
}

interface AgentInput {
  createdDate: string;
  credential: string;
  details: string;
  iconSrc: string;
  id: string;
  list?: boolean;
  updatedDate: string;
}

interface Details {
  id: string;
  name: string;
  description: string;
  instructions: string;
  tools: string[];
  tool_groups: object;
}

interface InputField {
  label: string;
  name: string;
  type: string;
  description?: string;
  default?: any;
  optional?: boolean;
  items?: {
    type: string;
  };
}

interface AgentOutput {
  label: string;
  name: string;
  version: number;
  type: string;
  category: string;
  icon: string;
  description: string;
  inputs: InputField[];
  filePath: string;
  baseClasses: string[];
  hideOutput: boolean;
}

const initializeDefaultNodeData = (
  nodeParams: NodeInput[]
): Record<string, string> => {
  const initialValues: Record<string, string> = {};

  for (const input of nodeParams) {
    initialValues[input.name] = input.default || "";
  }

  return initialValues;
};

export const initNode = (
  nodeData: NodeData,
  newNodeId: string,
  reactFlowInstance: any
): NodeData => {
  const inputAnchors: InputParams[] = [];
  const inputParams: InputParams[] = [];
  const outgoing = 1;

  const whitelistTypes = [
    "options",
    "multiOptions",
    "string",
    "number",
    "boolean",
    "asyncOptions",
  ];

  if (nodeData.inputs) {
    for (const input of nodeData.inputs) {
      const newInput: InputParams = {
        ...input,
        id: `${newNodeId}-input-${input.name}-${input.type}`,
      };
      if (whitelistTypes.includes(input.type)) {
        inputParams.push(newInput);
      } else {
        inputAnchors.push(newInput);
      }
    }
  }

  if (reactFlowInstance) {
    nodeData.reactFlowInstance = reactFlowInstance;
  }

  // Process credential
  if (nodeData.credential) {
    const newInput: InputParams = {
      ...nodeData.credential,
      id: `${newNodeId}-input-${nodeData.credential.name}-${nodeData.credential.type}`,
    };
    inputParams.unshift(newInput);
  }

  // Process outputs
  const outputAnchors: OutputParams[] = [];
  for (let i = 0; i < outgoing; i += 1) {
    if (nodeData.hideOutput) continue;
    const newOutput: OutputParams = {
      id: `${newNodeId}-output-${nodeData.name}-${
        nodeData?.baseClasses?.join("|") ?? ""
      }`,
      name: nodeData.name ?? "unknown",
      label: nodeData.type ?? "unknown",
      description: nodeData.description ?? "",
      type: nodeData.baseClasses?.join(" | ") ?? "unknown",
    };
    outputAnchors.push(newOutput);
  }
  if (nodeData.inputs) {
    nodeData.inputAnchors = inputAnchors;
    nodeData.inputParams = inputParams;
    nodeData.inputs = initializeDefaultNodeData(nodeData.inputs);
  } else {
    nodeData.inputAnchors = [];
    nodeData.inputParams = [];
    nodeData.inputs = {};
  }

  nodeData.outputAnchors = outputAnchors;

  // Credential
  if (nodeData.credential) {
    nodeData.credential = "";
  }

  nodeData.id = newNodeId;

  return nodeData;
};

export const isValidConnection = (
  connection: Connection,
  reactFlowInstance: ReactFlowInstance<Node<NodeData>, Edge>
): boolean => {
  const { sourceHandle, targetHandle, target } = connection;

  let sourceTypes = sourceHandle
    .split("-")
    [sourceHandle.split("-").length - 1].split("|")
    .map((s) => s.trim());
  console.log("sourceTypes", sourceTypes);

  let targetTypes = targetHandle
    .split("-")
    [targetHandle.split("-").length - 1].split("|")
    .map((t) => t.trim());
  if (targetTypes.some((t) => sourceTypes.includes(t))) {
    const targetNode = reactFlowInstance.getNode(target);

    if (!targetNode) {
      if (
        !reactFlowInstance
          .getEdges()
          .find((e) => e.targetHandle === targetHandle)
      ) {
        return true;
      }
    } else {
      const targetNodeData = targetNode.data as unknown as NodeData;
      const targetNodeInputAnchor =
        targetNodeData.inputAnchors?.find((ancr) => ancr.id === targetHandle) ||
        targetNodeData.inputParams?.find((ancr) => ancr.id === targetHandle);

      if (
        (targetNodeInputAnchor &&
          !targetNodeInputAnchor.list &&
          !reactFlowInstance
            .getEdges()
            .find((e) => e.targetHandle === targetHandle)) ||
        targetNodeInputAnchor?.list
      ) {
        return true;
      }
    }
  }

  return false;
};

export const getUniqueNodeId = (nodeData: any, nodes: any) => {
  let totalSameNodes = 0;
  for (let i = 0; i < nodes?.length; i += 1) {
    const node = nodes[i];
    if (node.data.name === nodeData.name) {
      totalSameNodes += 1;
    }
  }

  let nodeId = `${nodeData.name}_${totalSameNodes}`;
  for (let i = 0; i < nodes?.length; i += 1) {
    const node = nodes[i];
    if (node.id === nodeId) {
      totalSameNodes += 1;
      nodeId = `${nodeData.name}_${totalSameNodes}`;
    }
  }
  return nodeId;
};

export function convertToDesiredFormat(input: AgentInput): AgentOutput {
  const details: Details = JSON.parse(input.details);
  const {
    name,
    description,
    instructions,
    tools = [],
    tool_groups = [],
  } = details;
  return {
    label: name,
    name: name.toLowerCase().replace(/ /g, ""),
    version: 2,
    type: name,
    category: "Agents",
    icon: input.iconSrc,
    description: description,
    hideOutput: true,
    baseClasses: [],
    inputs: [
      {
        label: "Language Model",
        name: "llm",
        type: "BaseLanguageModel",
        description: "Language model used by the agent",
      },
      {
        label: "Document",
        name: "document",
        type: "Document",
        optional: true,
      },
      {
        label: "Supervisor",
        name: "supervisor",
        type: "Supervisor",
        optional: true,
      },
      {
        label: "Description",
        name: "description",
        type: "string",
        default: description,
        optional: true,
      },
      {
        label: "Tools",
        name: "tools",
        type: "multiOptions",
        description: "List of tools available for the agent",
        default: tools,
        items: {
          type: "string",
        },
      },
      {
        label: "Tool Groups",
        name: "tool_groups",
        type: "multiOptions",
        description: "List of tool groups available for the agent",
        default: tool_groups,
        items: {
          type: "string",
        },
      },
      {
        label: "Instructions",
        name: "instructions",
        type: "string",
        description: "Instructions for the agent",
        default: instructions,
      },
    ],
    filePath: "/path/to/your/agent/definition/file.js",
  };
}
