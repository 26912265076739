import React, { useEffect, useRef, useState } from "react";
import { Handle, Position, useUpdateNodeInternals } from "reactflow";
import { Box, Text } from "@mantine/core";
import { isValidConnection } from "./helper";
import { CustomWidthTooltip } from "./CustomTooltip";
import { Tooltip } from "@mantine/core";
interface Option {
  id: string;
  label: string;
  type?: string;
  hidden?: boolean;
  isAnchor?: boolean;
}

interface OutputAnchor {
  type?: string;
  id: string;
  label: string;
  options?: Option[];
}

interface NodeOutputHandlerProps {
  outputAnchor: OutputAnchor;
  data: any;
  disabled?: boolean;
  reactFlowInstance: any;
}

const NodeOutputHandler: React.FC<NodeOutputHandlerProps> = ({
  outputAnchor,
  data,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const updateNodeInternals = useUpdateNodeInternals();
  const [position, setPosition] = useState<number>(0);

  useEffect(() => {
    if (ref.current) {
      const offsetTop = ref.current.offsetTop;
      const clientHeight = ref.current.clientHeight;
      setPosition(offsetTop + clientHeight / 2);
      updateNodeInternals(data.id);
    }
  }, [data.id, ref, updateNodeInternals]);

  useEffect(() => {
    updateNodeInternals(data.id);
  }, [data.id, position, updateNodeInternals]);

  return (
    <div ref={ref}>
      <>
        <CustomWidthTooltip title={outputAnchor.type}>
          <Handle
            type="source"
            position={Position.Right}
            id={outputAnchor.id}
            isValidConnection={(connection: any) =>
              isValidConnection(connection, data.reactFlowInstance)
            }
            style={{
              height: 10,
              width: 10,
              backgroundColor: "rgb(33, 150, 243)",
              right: "-20px",
            }}
          />
        </CustomWidthTooltip>
        <Box p={2} style={{ textAlign: "end" }}>
          <Text>{outputAnchor.label}</Text>
        </Box>
      </>
    </div>
  );
};

export default NodeOutputHandler;
