import { Anchor, Loader, Box } from "@mantine/core";
import { useEffect, useState } from "react";
import {
  getUploadedDocumentsAdmin,
  getUploadedLinksAdmin,
} from "../lib/APIService";

const backend_url =
  process.env.REACT_APP_FRONTEND_URL || "http://localhost:3000";

export default function FilesUploadTable({ refresh, context, filter }: any) {
  const [files, setFiles] = useState<any>(null);
  const [links, setLinks] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const fetchFiles = async () => {
    setLoading(true);
    const response = await getUploadedDocumentsAdmin();
    setLoading(false);
    if (
      response.status === "ok" &&
      response.documents &&
      Object.keys(response.documents).length
    ) {
      setFiles(response.documents);
    }
  };

  const fetchLinks = async () => {
    setLoading(true);
    const response = await getUploadedLinksAdmin();
    setLoading(false);
    if (response.status === "success") {
      if (response.message) setLinks(response.message);
      if (response.links) setLinks(response.links);
    }
  };

  useEffect(() => {
    fetchFiles();
    fetchLinks();
  }, [refresh]);

const renderFiles = () => {
  const contextFiles = files?.[context] || [];
  return (
    contextFiles.length > 0 &&
    contextFiles.map((file: string, idx: number) => (
      <tr key={idx}>
        <td style={{
          wordBreak: 'break-word', 
          whiteSpace: 'normal',     
          maxWidth: '200px',        
          overflow: 'hidden'     
        }}>
          {file}
        </td>
        <td>Uploaded</td>
        <td>
          <Anchor
            variant="outline"
            size="compact-md"
            href={`${backend_url}/home/graph?file=${file}&role=${
              files?.["anypoint-trust"]?.includes(file)
                ? "anypoint-trust"
                : "trust-expert"
            }`}
            target="_blank"
            style={{
              wordBreak: 'break-word',
              whiteSpace: 'normal',
              maxWidth: '200px',
              overflow: 'hidden'
            }}
          >
            View Graph
          </Anchor>
        </td>
      </tr>
    ))
  );
};

const renderLinks = () => {
  const contextLinks = links?.[context] || [];
  return (
    contextLinks.length > 0 &&
    contextLinks.map((link: string, index: number) => (
      <tr key={index}>
        <td style={{
          wordBreak: 'break-word',
          whiteSpace: 'normal',
          maxWidth: '200px',
          overflow: 'hidden'
        }}>
          <Anchor href={link} color="teal" target="_blank" underline={true}>
            {link}
          </Anchor>
        </td>
        <td>Uploaded</td>
        <td>
          <Anchor
            variant="outline"
            size="compact-md"
            href={`${backend_url}/home/graph?file=${link}&role=${
              links?.["anypoint-trust"]?.includes(link)
                ? "anypoint-trust"
                : "trust-expert"
            }`}
            target="_blank"
            style={{
              wordBreak: 'break-word',
              whiteSpace: 'normal',
              maxWidth: '200px',
              overflow: 'hidden'
            }}
          >
            Open Graph
          </Anchor>
        </td>
      </tr>
    ))
  );
};

  const hasFiles = files?.[context]?.length > 0;
  const hasLinks = links?.[context]?.length > 0;

  return !!loading ? (
    <Loader />
  ) : (
    <>
      <div
        className="scroll surface"
        style={{
          marginTop: "1rem",
          padding: "1rem",
          boxSizing: "border-box",
          border: "1px solid #ddd",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <table
          className="border large-space"
          style={{
            width: "100%",
            borderCollapse: "collapse",
            tableLayout: "fixed",
          }}
        >
          <thead>
            <tr>
              <th> Uploaded Files</th>
              <th> Status</th>
              <th>Graph</th>
            </tr>
          </thead>
          <tbody>
            {filter === "ALL" && !hasFiles && !hasLinks ? (
              <tr>
                <td>No files and links</td>
              </tr>
            ) : filter === "DOCUMENTS" && !hasFiles ? (
              <tr>
                <td>No files</td>
              </tr>
            ) : filter === "LINKS" && !hasLinks ? (
              <tr>
                <td>No links</td>
              </tr>
            ) : (
              <>
                {filter === "ALL" || filter === "DOCUMENTS"
                  ? renderFiles()
                  : null}
                {filter === "ALL" || filter === "LINKS" ? renderLinks() : null}
              </>
            )}
          </tbody>
        </table>
      </div>
      
    </>
  );
}
