import { createStyles } from "@mantine/core";
import { Prism } from "@mantine/prism";
import Markdown from "react-markdown";
import "./MarkdownText.css";

const useStyles = createStyles((theme) => ({
  codeBlock: {},
}));

export default function MarkdownText({ text }: any) {
  const { classes } = useStyles();
  const textSplitted = text.split("```");

  return (
    <div>
      {textSplitted.map((text: string, index: any) => {
        if (index % 2 === 0) {
          return (
            <Markdown key={index} className="custom-markdown">
              {text}
            </Markdown>
          );
        } else {
          const codeBlock = text.split("\n");
          const language: any = codeBlock[0].split(" ")[0];
          codeBlock.shift();
          return (
            <>
              {codeBlock.length > 0 ? (
                <Prism
                  key={index}
                  colorScheme="dark"
                  className={classes.codeBlock}
                  language={language}
                >
                  {codeBlock.join("\n")}
                </Prism>
              ) : (
                <></>
              )}
            </>
          );
        }
      })}
    </div>
  );
}
