import { useState, useEffect } from "react";
import AddDocumentStore from "./AddDocumentStore";
import DocumentStoreUpload from "./DocumentStoreUpload";
import { documentStoresAPI } from "../lib/APIService";
import Card from "./material-design/components/Card";
import UploadFiles from "./UploadFiles";

export default function DocumentStore() {
  const [popup, setPopup] = useState<boolean>(false);
  const [documentStores, setDocumentStores] = useState<any[]>([]);
  const [selectedStore, setSelectedStore] = useState<any>(null);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("documentStore");
  const closeUploadfiles = () => {
    setSelectedStore(null);
    setUploadOpen(false);
  };

  const fetchDocumentStores = async () => {
    try {
      const res = await documentStoresAPI();
      setDocumentStores(res);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    fetchDocumentStores();
  }, []);

  const handleCardClick = (store: any) => {
    setSelectedStore(store);
    setUploadOpen(true);
  };

  const handleAddDocumentStore = async () => {
    await fetchDocumentStores();
    setPopup(false); // Close the popup
  };

  const updateDocumentStores = () => {
    fetchDocumentStores();
  };

  if (selectedStore && uploadOpen) {
    return (
      <DocumentStoreUpload
        store={selectedStore}
        uploadOpen={uploadOpen}
        onClose={closeUploadfiles}
        updateStore={updateDocumentStores}
      />
    );
  }

  return (
    <>
      <div>
        <div className="tabs">
          <a
            className={activeTab === "documentStore" ? "active" : ""}
            onClick={() => setActiveTab("documentStore")}
          >
            Document Store
          </a>
          <a
            className={activeTab === "uploadFiles" ? "active" : ""}
            onClick={() => setActiveTab("uploadFiles")}
          >
            Global Store
          </a>
        </div>
        <div
          className="page padding active"
          style={{ display: activeTab === "documentStore" ? "block" : "none" }}
        >
          <div>
            <div>
              <nav
                style={{
                  padding: "0.5rem 1.5rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h6></h6>
                <button
                  className="small-round medium"
                  onClick={() => setPopup(true)}
                >
                  <i>add</i>
                  <span>Add</span>
                </button>
              </nav>
            </div>

            {popup && (
              <AddDocumentStore
                setPopup={setPopup}
                popup={popup}
                handleAddDocumentStore={handleAddDocumentStore}
              />
            )}

            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                alignItems: "flex-start",
                alignContent: "flex-start",
                scrollBehavior: "smooth",
                paddingBottom: "100px",
                overflowY: "auto",
              }}
            >
              {documentStores.map((store) => (
                <Card
                  key={store.id}
                  id={store.id}
                  name={store.name}
                  description={store.description}
                  type={store.type}
                  subtype={store.store_subtype}
                  onClick={() => handleCardClick(store)}
                />
              ))}
            </div>
          </div>
        </div>
        <div
          className="page padding active"
          style={{ display: activeTab === "uploadFiles" ? "block" : "none" }}
        >
          <UploadFiles/>
        </div>
      </div>
    </>
  );
}
