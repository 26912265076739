import React, { useCallback, useEffect, useState } from "react";
import { deleteFlow, getFlows } from "../lib/APIService";
import { useLocation, useNavigate } from "react-router-dom";
import MdButton from "./material-design/components/MdButton";
import Card from "./material-design/components/Card";

interface FlowData {
  id?: any;
  name: string;
  flowData: string;
  deployed: boolean;
  isPublic: boolean;
  createdDate?: string;
  updatedDate?: string;
}

const Chatflows: React.FC<{}> = () => {
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [chatflows, setChatflows] = useState<FlowData[]>([]);
  const [images, setImages] = useState<Record<string, string[]>>({});
  const navigate = useNavigate();
  const location = useLocation();

  const getFlowsData = async () => {
    const response = await getFlows();
    return response;
  };

  const deleteFlowData = async (event: any, data: any) => {
    event.stopPropagation();
    const response = await deleteFlow(data.id);
    fetchData();
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getFlowsData();
      if (response) {
        setChatflows(response);
      } else {
        setChatflows([]);
      }
    } catch (e) {
      setError(e as Error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (chatflows && !!chatflows.length && chatflows?.length > 0) {
      try {
        const images: Record<string, string[]> = {};
        chatflows.forEach((chatflow) => {
          const flowData = JSON.parse(chatflow.flowData);
          const nodes = flowData.nodes || [];
          images[chatflow.id] = nodes.map(
            (node: any) =>
              node?.data?.imgSrc ||
              "https://api.dicebear.com/7.x/bottts/svg?seed=24e1b97a-fedc-4c01-be48-ddc75279200c"
          );
        });
        setImages(images);
      } catch (e) {
        console.error(e);
      }
    }
  }, [chatflows]);

  const addNew = () => {
    navigate(`${location.pathname}/new`);
  };

  const goToCanvas = (selectedChatflow: any) => {
    navigate(`${location.pathname}/${selectedChatflow.id}`);
  };

  return (
    <div>
      {error ? (
        <div>Please Try again later</div>
      ) : (
        <div>
          <div>
            <nav
            className="left-padding bottom-padding right-padding"
              style={{
                borderBottom: "1px solid #ddd",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="chat-name">ChatFlows</div>

              <button className="small-round medium" onClick={addNew}>
                <i>add</i>
                <span>Add New</span>
              </button>
            </nav>
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
              alignItems: "flex-start",
              alignContent: "flex-start",
              scrollBehavior: "smooth",
              paddingBottom: "100px",
            }}
          >
            {chatflows && chatflows.length > 0 ? (
              chatflows.map((data: any, index: number) => (
                <Card
                  key={data.id}
                  id={data.id}
                  name={data.name}
                  onClick={() => goToCanvas(data)}
                  children={
                    <div
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                      }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <button
                        className="circle transparent"
                        onClick={(ev) => deleteFlowData(ev, data)}
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          cursor: "pointer",
                        }}
                      >
                        <i>delete</i>
                      </button>
                    </div>
                  }
                />
              ))
            ) : (
              <div
                className="center-align middle-align"
                style={{ width: "100%", textAlign: "center" }}
              >
                <p>No Chatflows Yet</p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Chatflows;
