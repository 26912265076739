import { ChangeEvent, useEffect, useState } from "react";
import AddToolGroup from "./AddToolGroup";
import ViewToolGroup from "./ViewToolGroup";
import { getToolGroups } from "../lib/APIService";
import MdButton from "./material-design/components/MdButton";
import Card from "./material-design/components/Card";

interface ToolGroup {
  id?: any;
  name: string;
  description: string;
  tools: any[];
  createdDate?: any;
  updatedDate?: any;
  model: string;
  credential: string;
}

export default function ToolGroup() {
  const [popup, setPopup] = useState(false);
  const [toolgroups, setToolGroups] = useState<any[]>([]);
  const [systemToolGroups, setSystemToolGroups] = useState<any[]>([]);
  const [currentToolGroup, setCurrentToolGroup] = useState<any>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredToolGroups, setFilteredToolGroups] = useState<ToolGroup[]>([]);
  const [currentSystemToolGroup, setCurrentSystemToolGroup] = useState<
    string | null
  >(null);
  const [viewSystemToolDetails, setViewSystemToolDetails] = useState(false);
  const [currentUserToolGroup, setCurrentUserToolGroup] = useState<
    string | null
  >(null);
  const [viewUserToolDetails, setViewUserToolDetails] = useState(false);

  useEffect(() => {
    fetchToolGroups();
    fetchSystemToolGroups();
  }, []);

  const fetchToolGroups = async () => {
    const response = await getToolGroups("user");
    setToolGroups(response);
    setFilteredToolGroups(response);
  };

  const fetchSystemToolGroups = async () => {
    const response = await getToolGroups("system");
    setSystemToolGroups(response);
  };

  const openAddPopup = () => {
    setCurrentToolGroup(null);
    setPopup(true);
  };

  const openDetailsSystemView = (toolgroup: ToolGroup) => {
    setCurrentSystemToolGroup(toolgroup.id);
    setViewSystemToolDetails(true);
  };

  const openDetailsUserView = (toolgroup: ToolGroup) => {
    setCurrentUserToolGroup(toolgroup.id);
    setViewUserToolDetails(true);
  };

  const closeDetailsSystemView = () => {
    setCurrentSystemToolGroup(null);
    setViewSystemToolDetails(false);
  };

  const closeDetailsUserView = () => {
    setCurrentUserToolGroup(null);
    setViewUserToolDetails(false);
  };

  const handleSearchQuery = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value && toolgroups && !!toolgroups.length) {
      const data = toolgroups.filter((toolgroup) =>
        toolgroup.name.toLowerCase().includes(value.trim().toLowerCase())
      );
      setSearchQuery(value);
      setFilteredToolGroups(data);
    } else {
      setSearchQuery(value);
      setFilteredToolGroups(toolgroups);
    }
  };

  const getIconSrc = (toolGroupName: string) => {
    if (toolGroupName.includes("Aws")) {
      return "/aws-com.svg";
    }

    if (toolGroupName.includes("Anypoint")) {
      return "/salesforce.svg";
    }

    if (toolGroupName.includes("Data Analyst")) {
      return "/data-visualization.svg";
    }

    if (toolGroupName.includes("Insights")) {
      return "/insights.svg";
    }
    if (toolGroupName.includes("Kubernetes")) {
      return "/kubernetes.svg";
    }
    if (toolGroupName.includes("Cloud Security")) {
      return "/orca.png";
    }
    if (toolGroupName.includes("Container Security")) {
      return "/sysdig.svg";
    }
    if (toolGroupName.includes("Network Security")) {
      return "/paloalto.svg";
    }
    if (toolGroupName.includes("Endpoint Protection")) {
      return "/crowdstrike.svg";
    }
    if (toolGroupName.includes("Threat Prevention")) {
      return "/checkpoint.png";
    }
    if (toolGroupName.includes("Project")) {
      return "/jira-1.svg";
    }
  };

  if (viewSystemToolDetails && currentSystemToolGroup) {
    return (
      <ViewToolGroup
        toolGroupId={currentSystemToolGroup}
        onClose={closeDetailsSystemView}
        onUpdate={fetchSystemToolGroups}
        type={"system"}
      />
    );
  }

  if (viewUserToolDetails && currentUserToolGroup) {
    return (
      <ViewToolGroup
        toolGroupId={currentUserToolGroup}
        onClose={closeDetailsUserView}
        onUpdate={fetchToolGroups}
        type={"user"}
      />
    );
  }

  const tool_groups = [
    {
      value: "System Tool Groups",
      description: (
        <>
          {systemToolGroups &&
            !!systemToolGroups.length &&
            systemToolGroups.map((toolgroup: any) => (
              <Card
                key={toolgroup.id}
                id={toolgroup.id}
                name={toolgroup.name}
                iconSrc={getIconSrc(toolgroup.name)}
                description={toolgroup.description}
                onClick={() => openDetailsSystemView(toolgroup)}
                align="center"
              />
            ))}
        </>
      ),
    },
    {
      value: "User Tool Groups",
      description: (
        <>
          {filteredToolGroups &&
            !!filteredToolGroups.length &&
            filteredToolGroups.map((toolgroup: any) => (
              <Card
                key={toolgroup.id}
                id={toolgroup.id}
                name={toolgroup.name}
                iconSrc={getIconSrc(toolgroup.name)}
                description={toolgroup.description}
                onClick={() => openDetailsUserView(toolgroup)}
                align="center"
              />
            ))}
        </>
      ),
    },
  ];

  return (
    <>
      <div>
        <nav
          className="left-padding bottom-padding right-padding"
          style={{
            borderBottom: "1px solid #ddd",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className="chat-name">Tool Group</div>
          <div className="horizontal">
            <div className="field prefix border small">
              <i>search</i>
              <input
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => handleSearchQuery(e)}
              />
            </div>
            <button className="small-round medium" onClick={openAddPopup}>
              <i>add</i>
              <span>Add</span>
            </button>
          </div>
        </nav>
      </div>
      <div
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
        }}
      >
        <details open>
          <summary className="left-padding top-padding">
            System Tool Groups
          </summary>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
              alignItems: "flex-start",
              alignContent: "flex-start",
              scrollBehavior: "smooth",

              padding: "1rem",
            }}
          >
            {tool_groups[0]?.description || "No system tool groups available."}
          </div>
        </details>

        <details>
          <summary className="left-padding top-padding">
            User Tool Groups
          </summary>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
              alignItems: "flex-start",
              alignContent: "flex-start",
              scrollBehavior: "smooth",
              overflowY: "auto",
              padding: "1rem",
            }}
          >
            {tool_groups[1]?.description || "No user tool groups available."}
          </div>
        </details>
      </div>

      {popup && !currentToolGroup && (
        <AddToolGroup
          setPopup={setPopup}
          popup={popup}
          onAddToolgroup={fetchToolGroups}
        />
      )}
    </>
  );
}
