import { useEffect, useState } from "react";
import {
  deleteAgent,
  getToolGroups,
  getTools,
  updateAgent,
} from "../lib/APIService";
import { v4 as uuidv4 } from "uuid";

interface Tool {
  id: string;
  name: string;
}

interface ToolGroup {
  id: string;
  name: string;
  tools: Tool[];
}

export default function ViewAgents({
  editMode,
  setEditMode,
  onEditAgent,
  currentAgent,
  onDeleteAgent,
}: any) {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    instructions: "",
    tools: [] as Tool[],
    iconSrc: `https://api.dicebear.com/7.x/bottts/svg?seed=${uuidv4()}`,
    tool_groups: [] as ToolGroup[],
  });

  const [toolsList, setToolsList] = useState<Tool[]>([]);
  const [toolGroups, setToolGroups] = useState<ToolGroup[]>([]);

  useEffect(() => {
    if (editMode && currentAgent) {
      const details = JSON.parse(currentAgent.details);
      setFormData({
        ...formData,
        name: details.name,
        description: details.description,
        instructions: details.instructions,
        tools: details.tools || [],
        iconSrc: currentAgent.iconSrc,
        tool_groups: details.tool_groups || [],
      });
    }
  }, [editMode, currentAgent]);

  useEffect(() => {
    const fetchTools = async () => {
      const response = await getTools();
      setToolsList(response.map(({ id, name }: Tool) => ({ id, name })));
    };
    fetchTools();
  }, []);

  useEffect(() => {
    const fetchToolGroups = async () => {
      const userToolGroups = await getToolGroups("user");
      const systemToolGroups = await getToolGroups("system");
      setToolGroups([...userToolGroups, ...systemToolGroups]);
    };
    fetchToolGroups();
  }, []);

  const handleChange = (field: string, value: string) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleToolsChange = (toolId: string) => {
    const selectedTool = toolsList.find((tool) => tool.id === toolId);
    if (selectedTool) {
      setFormData({ ...formData, tools: [...formData.tools, selectedTool] });
    }
  };

  const handleRemoveTool = (toolId: string) => {
    setFormData({
      ...formData,
      tools: formData.tools.filter((tool) => tool.id !== toolId),
    });
  };

  const handleToolGroupChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedGroupId = event.target.value;
    const selectedGroup = toolGroups.find(
      (group) => group.id === selectedGroupId
    );
    if (selectedGroup) {
      setFormData({ ...formData, tool_groups: [selectedGroup] });
    }
  };

  const handleSubmit = async () => {
    const updatedAgent = {
      ...currentAgent,
      details: JSON.stringify({
        name: formData.name,
        description: formData.description,
        instructions: formData.instructions,
        tools: formData.tools.map(({ id, name }) => ({ id, name })),
        tool_groups: formData.tool_groups.map(({ id, name }) => ({ id, name })),
      }),
      iconSrc: formData.iconSrc,
    };

    try {
      const response = await updateAgent(currentAgent.id, updatedAgent);
      if (response) {
        onEditAgent(response);
        setEditMode(false);
      }
    } catch (error) {
      console.error("Error updating agent:", error);
    }
  };

  const handleDelete = async () => {
    if (currentAgent?.id) {
      try {
        await deleteAgent(currentAgent.id);
        onDeleteAgent(currentAgent.id);
      } catch (error) {
        console.error("Error deleting agent:", error);
      }
    }
  };

  const availableTools = toolsList.filter(
    (tool) =>
      !formData.tools.some((selectedTool) => selectedTool.id === tool.id)
  );

  return (
    <>
      <div style={{ height: "100vh", overflow: "scroll" }}>
        <nav
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "1rem",
          }}
        >
          <button
            className="circle transparent"
            onClick={() => setEditMode(false)}
          >
            <i>arrow_back</i>
          </button>
          <h6 className="max">{"View Agent"}</h6>
          <div style={{ display: "flex", gap: "0.5rem" }}>
            <button className="circle transparent" onClick={handleDelete}>
              <i>delete</i>
            </button>
          </div>
        </nav>

        <div style={{ flex: 1, overflow: "auto", paddingLeft: "3.5rem" }}>
          <div style={{ marginBottom: "1rem" }}>
            <span>Name:</span>
            <div className="field border">
              <input
                value={formData.name}
                onChange={(e) => handleChange("name", e.target.value)}
                style={{ width: "40%" }}
              />
            </div>
          </div>

          <div style={{ marginBottom: "1rem" }}>
            <span>Description:</span>
            <div className="field textarea border">
              <textarea
                value={formData.description}
                onChange={(e) => handleChange("description", e.target.value)}
                style={{ width: "40%" }}
              />
            </div>
          </div>

          <div style={{ marginBottom: "1rem" }}>
            <span>Instructions:</span>
            <div className="field textarea border">
              <textarea
                value={formData.instructions}
                onChange={(e) => handleChange("instructions", e.target.value)}
                style={{ width: "40%" }}
              />
            </div>
          </div>

          <div style={{ marginBottom: "1rem" }}>
            <span>Icon Src:</span>
            <div className="field border">
              <input
                placeholder="Agent ICON SRC"
                value={formData.iconSrc}
                onChange={(e) => handleChange("iconSrc", e.target.value)}
                style={{ width: "40%" }}
              />
            </div>
          </div>

          {/* Tools Section */}

          <div style={{ marginBottom: "1rem" }}>
            <span>Tools:</span>
            <div className="field suffix border" style={{ width: "40%" }}>
              <select
                className="select"
                value=""
                onChange={(e) => handleToolsChange(e.target.value)}
              >
                {availableTools.map((tool: Tool) => (
                  <option key={tool.id} value={tool.id}>
                    {tool.name}
                  </option>
                ))}
              </select>
              <i>arrow_drop_down</i>
            </div>
          </div>

          {/* Conditionally display the chips div */}
          {formData.tools.length > 0 && (
            <div
              style={{
                marginTop: "1rem",
                marginBottom: "2rem",
                maxHeight: "100px",
                overflowY: "auto",
                border: "1px solid #ccc",
                padding: "0.5rem",
                width: "40%",
              }}
            >
              {formData.tools.map((tool: Tool, index: number) => (
                <a
                  className="chip border"
                  key={index}
                  onClick={() => handleRemoveTool(tool.id)}
                  style={{
                    marginBottom: "1rem",
                    cursor: "pointer",
                  }}
                >
                  {tool.name}
                  <i>close</i>
                </a>
              ))}
            </div>
          )}

          <div style={{ marginBottom: "1rem" }}>
            <span>Tool Groups:</span>
            <div className="field suffix border" style={{ width: "40%" }}>
              <select
                value={
                  formData.tool_groups.length > 0
                    ? formData.tool_groups[0].id
                    : ""
                }
                onChange={handleToolGroupChange}
              >
                <option value="" disabled>
                  Please Select Tool Group
                </option>
                {toolGroups.map((group: ToolGroup) => (
                  <option key={group.id} value={group.id}>
                    {group.name}
                  </option>
                ))}
              </select>
              <i>arrow_drop_down</i>
            </div>
          </div>
          {/* Save Changes Button */}
          <div style={{ marginTop: "2rem" }}>
            <button className="button success" onClick={handleSubmit}>
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
