import { Route, Routes } from "react-router-dom";
import Home from "./modules/home/Home";
import LandingNew from "./modules/landing/LandingNew";
import SharedConversation from "./modules/share/SharedConversation";
import Graph from "./modules/graph/Graph";
import Agents from "./components/Agents";
import Chatflows from "./components/FlowList";
import ToolGroup from "./components/ToolGroup";
import FlowList from "./components/FlowList";
import AdminLayout from "./layouts/AdminLayout";
import UploadFiles from "./components/UploadFiles";
import DocumentStore from "./components/DocumentStore";
import Canvas from "./components/Canvas";
import HomeOld from "./modules/home/HomeOld";
import Tools from "./components/Tools";
import Credentials from "./components/Credentials";
import ProtectedRoute from "./ProtectedRoute";

const TrustGenieRouter = () => {
  // TODO: check for role before adding admin routes
  return (
    <Routes>
      <Route path="/" element={<LandingNew />} />

      <Route
        path="/home"
        element={
          <ProtectedRoute>
            <AdminLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/home/chat" element={<Home />} />
        <Route path="/home/chat/conversation/new" element={<Home />} />
        <Route
          path="/home/chat/conversation/:conversationId"
          element={<Home />}
        />
        <Route
          path="/home/share/conversation/:conversationId"
          element={<SharedConversation />}
        />
        {/* <Route path="/home/upload" element={<UploadFiles />} /> */}
        <Route path="/home/knowledge-bases" element={<DocumentStore />} />
        <Route path="/home/credentials" element={<Credentials />} />
        <Route path="/home/toolgroups" element={<ToolGroup />} />
        <Route path="/home/tools" element={<Tools />} />
        <Route path="/home/agents" element={<Agents />} />
        <Route path="/home/flows" element={<FlowList />} />
        <Route path="/home/flows/new" element={<Canvas />} />
        <Route path="/home/flows/:flowId" element={<Canvas />} />
        <Route path="/home/graph" element={<Graph />} />
      </Route>
    </Routes>
  );
};

export default TrustGenieRouter;
