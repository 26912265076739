//@ts-nocheck

import { useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import ForceGraph from "../../components/ForceGraph";
import { queryMap } from "../../lib/Constants";
import graphQueryAPI from "../../lib/APIService";

const colors = [
  "#418BFC",
  "#46BCC8",
  "#EA6BCB",
  "#B9AAC8",
  "#B6BE1C",
  "#F64D1A",
  "#EB5E68",
  "#BA6DE4",
  "#F08519",
  "#D6AB1B",
];

export default function Graph() {
  let [searchParams, setSearchParams] = useSearchParams();
  const file = searchParams.get("file");
  const role = searchParams.get("role");
  const [graphType, setGraphType] = useState<GraphType[]>(["entities"]);
  const [nodes, setNodes] = useState<Node[]>([]);
  const [relationships, setRelationships] = useState<Relationship[]>([]);

  const graphQuery: string =
    graphType.length === 3
      ? queryMap.DocChunkEntities
      : graphType.includes("entities") && graphType.includes("chunks")
      ? queryMap.ChunksEntities
      : graphType.includes("entities") && graphType.includes("document")
      ? queryMap.DocEntities
      : graphType.includes("document") && graphType.includes("chunks")
      ? queryMap.DocChunks
      : graphType.includes("entities") && graphType.length === 1
      ? queryMap.Entities
      : graphType.includes("chunks") && graphType.length === 1
      ? queryMap.Chunks
      : queryMap.Document;

  useEffect(() => {
    const getGraphData = async () => {
      const fetchData = async () => {
        try {
          const graphData = await graphQueryAPI(graphQuery, file, role);
          return graphData;
        } catch (error: any) {
          console.log(error);
        }
      };

      try {
        const result = await fetchData();
        if (result && result.data.nodes.length > 0) {
          const labels = [
            ...new Set(result.data.nodes.map((d) => d.labels[0])),
          ];

          result.data.nodes.forEach((d) => {
            d.id = d.element_id;
            d.radius = 20;
            d.color = colors[labels.indexOf(d.labels[0])] || "gray";
          });

          result.data.relationships.forEach((n) => {
            n.source = n.start_node_element_id;
            n.target = n.end_node_element_id;
          });

          setNodes(result.data.nodes);
          setRelationships(result.data.relationships);
          // setLoading(false);
          setScheme(schemeVal);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // setLoading(false);
      }
    };

    getGraphData();
  }, []);

  return (
    <>
      <ForceGraph {...{ nodes: nodes, relationships: relationships }} />
    </>
  );
}
