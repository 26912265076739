import React, { useEffect, useState } from "react";
import { Flex, Text, Box, Avatar } from "@mantine/core";
import NodeInputHandler from "./NodeInputHandler";
import NodeOutputHandler from "./NodeOutputHandler";

interface InputAnchor {
  id: string;
  label: string;
  description?: string;
  optional?: boolean;
}

interface CanvasNodeProps {
  data: {
    [x: string]: any;
    label: string;
    icon: string;
    inputAnchors?: InputAnchor[];
    inputParams?: any[];
    selected?: boolean;
    reactFlowInstance?: any;
  };
}

const CanvasNode: React.FC<CanvasNodeProps> = ({ data }) => {
  const [viewContent, setViewContent] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);

  useEffect(() => {
    if (data.category === "Agents" || data.category === "Supervisors") {
      setIsValid(true);
    }
  }, []);

  return (
    <div
      style={{
        width: "350px",
        borderRadius: "10px",
        maxWidth: "400px",
        border: `1px solid ${isValid ? "green" : "red"}`,
        background: "white",
      }}
    >
      <div className="flex" style={{ flexDirection: "column", gap: "15px" }}>
        <div
          className="flex"
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            padding: "10px 15px",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <img src={data.icon} alt={data.label} width={40} height={40} />
            <label
              className="large"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "18px",
                color: "gray",
                fontWeight: 600,
              }}
            >
              {data.label}
            </label>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => setViewContent(!viewContent)}
          >
            <i>settings</i>
          </div>
        </div>

        {data.inputAnchors && data.inputAnchors.length > 0 && (
          <div
            className="stack"
            style={{
              background: "rgb(250, 250, 250)",
              color: "rgb(97, 97, 97)",
              padding: "8px",
            }}
          >
            <div className="center-align no-space">
              <h6 className="small" style={{ fontWeight: 600 }}>
                Inputs
              </h6>
            </div>
          </div>
        )}
        <div style={{ padding: "15px" }}>
          <div
            className="flex"
            style={{ display: "flex", flexDirection: "column", gap: 1 }}
          >
            {data?.inputAnchors!.map((inputAnchor, index) => (
              <NodeInputHandler
                key={index}
                inputAnchor={inputAnchor!}
                data={data}
                viewContent={viewContent}
                setIsValid={setIsValid}
              />
            ))}

            {data
              ?.inputParams!.filter((inputParam) => !inputParam.hidden)
              .map((inputParam, index) => (
                <NodeInputHandler
                  key={index}
                  inputParam={inputParam}
                  data={data}
                  viewContent={viewContent}
                  setIsValid={setIsValid}
                />
              ))}

            {data.outputAnchors && data.outputAnchors.length > 0 && (
              <div
                className="stack"
                style={{
                  background: "rgb(250, 250, 250)",
                  color: "rgb(97, 97, 97)",
                  padding: "8px",
                }}
              >
                <div className="center-align no-space">
                  <h6 className="small" style={{ fontWeight: 600 }}>
                    Output
                  </h6>
                </div>
              </div>
            )}

            {data.outputAnchors.length > 0 &&
              data.outputAnchors.map((outputAnchor: any) => (
                <NodeOutputHandler
                  key={outputAnchor.id}
                  outputAnchor={outputAnchor}
                  data={data}
                  reactFlowInstance={data.reactFlowInstance}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CanvasNode;
