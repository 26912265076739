import {
    ResponsiveContainer,
    Treemap,
  } from "recharts";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

export default function TGMetric(props: any){
    const {header, rows} = props
    return (
      <div className="metric">
        <div className="metric-header">{header}</div>
        <div className="metric-content">
          <table>
            <tbody>
              {!!rows.length && rows.map((row:any, index:any) => {
                return <tr key={index}>
                  {!!row.length && row.map((col:any, idx:any) => {
                    return <td key={idx}>{col}</td>
                  })}
                </tr>
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
}