import { useState, useEffect, useRef } from "react";
import {
  IconSend,
  IconRobotFace,
  IconUserCircle,
  IconEraser,
  IconX,
  IconArrowsMaximize,
} from "@tabler/icons-react";
import MarkdownText from "../MarkdownText";
import {
  createChatConversation,
  sendChatConversationQuery,
} from "../../lib/APIService";
import { ActionIcon } from "@mantine/core";

interface Message {
  type: "user" | "ai";
  content: string;
}

function ChatBox({
  messages,
  onSendMessage,
  height,
  width,
  nodes,
  loading,
}: {
  messages: Message[];
  onSendMessage: (message: string) => void;
  height: any;
  width: any;
  nodes: any;
  loading: boolean;
}) {
  const [inputValue, setInputValue] = useState<string>("");
  const chatConversationId = localStorage.getItem("chatConversationId");
  const scrollAreaRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollAreaRef.current) {
      scrollAreaRef.current.scrollTop = scrollAreaRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSendMessage = () => {
    if (!inputValue.trim()) return;
    onSendMessage(inputValue);
    setInputValue("");
  };

  return (
    <div>
      <div
        className="scroll"
        style={{
          width: width,
          height: height,
          overflowY: "auto",
          padding: "1rem",
        }}
        ref={scrollAreaRef}
      >
        <div>
          {messages.map((message, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "0.5rem",
                backgroundColor: message.type === "ai" ? "#f0f0f0" : "inherit",
                padding: "0.5rem",
                borderRadius: "0.5rem",
              }}
            >
              {message.type === "ai" && (
                <>
                  <i>smart_toy</i>
                  <div
                    style={{
                      backgroundColor: "#f0f0f0",
                      borderRadius: "0.5rem",
                      maxWidth: width > 300 ? "100%" : "80%",
                      // fontSize: "12px",
                      whiteSpace: "pre-wrap",
                      // wordWrap: "break-word",
                      padding: "0.5rem",
                    }}
                  >
                    {/* <MarkdownText text={message.content} /> */}
                    {message.content}
                  </div>
                </>
              )}
              {message.type === "user" && (
                <>
                  <i>account_circle</i>
                  <div
                    style={{
                      backgroundColor: "inherit",
                      padding: "0.5rem",
                      borderRadius: "0.5rem",
                      maxWidth: width > 300 ? "100%" : "80%",
                      // fontSize: "12px",
                      whiteSpace: "pre-wrap",
                      wordWrap: "break-word",
                      marginLeft: "0.5rem",
                    }}
                  >
                    {message.content}
                  </div>
                </>
              )}
            </div>
          ))}
          {loading && (
            <div
              className="center"
              style={{
                marginTop: "0.5rem",
                textAlign: "center",
                background: "white",
              }}
            >
              <img src="/Loader.gif" alt="Loading" width={88} />
            </div>
          )}
        </div>
      </div>

      {nodes && !!nodes.length && chatConversationId ? (
        <div style={{ display: "flex", padding: "10px", marginTop: "1rem" }}>
          <div className="field round border chat-input-wrapper">
            <div className="textarea-container">
              <textarea
                rows={1}
                className="chat-input"
                placeholder="Ask me something"
                value={inputValue}
                onKeyDown={(event) => {
                  if (event.key === "Enter" && !event.shiftKey) {
                    event.preventDefault();
                    handleSendMessage();
                  } else if (event.key === "Enter" && event.shiftKey) {
                    event.preventDefault();
                    setInputValue((prev) => prev + "\n");
                  }
                }}
                onChange={(event) => setInputValue(event.currentTarget.value)}
              />
              <button
                className="transparent circle send-button"
                onClick={handleSendMessage}
              >
                <i>send</i>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            border: "1px solid gray",
            background: "#cccccc",
            padding: "10px",
            width: width,
            fontSize: "12px",
            textAlign: "center",
            justifyContent: "flex-start",
            wordWrap: "break-word",
            whiteSpace: "pre-wrap",
          }}
        >
          {!chatConversationId && !!nodes.length
            ? "Please save the flow to start using the chat"
            : "Without agents, LLMs, or document stores, you can't proceed with the chat."}
        </div>
      )}
    </div>
  );
}

interface ChatInterfaceProps {
  nodes: any;
  chatFlowId: any;
}

function ChatInterface({ nodes, chatFlowId }: ChatInterfaceProps) {
  const [opened, setOpened] = useState<boolean>(false);
  const [extensionVisible, setExtensionVisible] = useState<boolean>(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [confirmationOpened, setConfirmationOpened] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (opened) {
      createNewConversation();
    }
  }, [opened]);

  const handleSendMessage = async (message: string) => {
    setLoading(true);
    const newMessages: Message[] = [
      ...messages,
      { type: "user", content: message },
    ];
    setMessages(newMessages);
    await sendMessage(message);
    setLoading(false);
  };

  async function sendMessage(message: string) {
    const conversation_id = localStorage.getItem("chatConversationId");
    const workspace_id = localStorage.getItem("workspace_id");

    if (!conversation_id || !workspace_id) {
      console.error("No conversation ID or workspace ID found.");
      return;
    }

    try {
      const response = await sendChatConversationQuery(
        message,
        conversation_id!
      );
      if (!response) {
        console.error("No response received.");
        return;
      }
      let parsedResponse: any;
      if (typeof response === "string") {
        try {
          parsedResponse = JSON.parse(response);
        } catch (error) {
          parsedResponse = { output: response };
        }
      } else {
        parsedResponse = response;
      }

      if (parsedResponse && typeof parsedResponse === "object") {
        if (parsedResponse.output) {
          setMessages((prevMessages) => [
            ...prevMessages,
            { type: "ai", content: parsedResponse.output },
          ]);
        } else {
          console.error("Unexpected response format:", parsedResponse);
        }
      } else {
        console.error("Unexpected response type:", parsedResponse);
      }
    } catch (error) {
      console.error("Failed to send message:", error);
    }
  }

  async function createNewConversation() {
    if (chatFlowId) {
      const response = await createChatConversation(chatFlowId);
      localStorage.setItem("chatConversationId", response.conversation_id);
      handleSendMessage("Hi");
    }
  }

  const togglePopover = () => {
    setOpened((prev) => {
      const newOpened = !prev;
      setMessages([]);
      setExtensionVisible(newOpened);
      setModalOpened(false);
      return newOpened;
    });
  };

  const openModal = () => {
    setOpened(false);
    setExtensionVisible(false);
    setModalOpened(true);
  };

  const closeModal = () => {
    setModalOpened(false);
  };

  const clearChatWithConfirmation = () => {
    setConfirmationOpened(true);
  };

  const confirmClearChat = () => {
    localStorage.removeItem("chatConversationId");
    setMessages([]);
    setConfirmationOpened(false);
    setModalOpened(false);
  };

  return (
    <div style={{ position: "relative", height: "100vh", padding: "20px" }}>
      <div style={{ position: "absolute", right: 10, top: 10 }}>
        {opened ? (
          <a
            className="small chip action-icon"
            onClick={() => {
              setOpened(false);
              localStorage.removeItem("chatConversationId");
            }}
          >
            <i className="tiny">close</i>
          </a>
        ) : (
          <a className="small chip action-icon" onClick={togglePopover}>
            <i>message</i>
          </a>
        )}

        {extensionVisible && (
          <a
            className="small chip action-icon"
            onClick={openModal}
            style={{
              position: "absolute",
              right: 50,
              top: 0,
            }}
          >
            <i>zoom_out_map</i>
          </a>
        )}
      </div>

      {opened && (
        <div
          style={{
            position: "absolute",
            top: "3rem",
            right: "0.5rem",
            background: "white",
            border: "1px solid gray",
          }}
        >
          <ChatBox
            messages={messages}
            onSendMessage={handleSendMessage}
            width={300}
            height={400}
            nodes={nodes}
            loading={loading}
          />
        </div>
      )}

      {modalOpened && (
        <dialog
          className={`modal auto ${modalOpened ? "active" : ""}`}
          style={{ padding: "20px", marginTop: "2rem", width: "700px" }}
        >
          <div
            className="flex"
            style={{
              display: "flex",
              marginBottom: "20px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h5>Chat</h5>
            <button className="medium" onClick={clearChatWithConfirmation}>
              <i>ink_eraser</i>
              <span>Clear Chat</span>
            </button>
          </div>
          <div style={{ position: "relative" }}>
            <ChatBox
              messages={messages}
              onSendMessage={handleSendMessage}
              width={"100%"}
              height={400}
              nodes={nodes}
              loading={loading}
            />
          </div>
        </dialog>
      )}

      {confirmationOpened && (
        <dialog className={`modal auto ${confirmationOpened ? "active" : ""}`}>
          <h3>Clear Chat History</h3>
          <p>Are you sure you want to clear the chat history?</p>
          <div className="flex justify-end">
            <button
              className="btn outline"
              onClick={() => setConfirmationOpened(false)}
            >
              Cancel
            </button>
            <button className="btn primary" onClick={confirmClearChat}>
              Clear
            </button>
          </div>
        </dialog>
      )}
    </div>
  );
}

export default ChatInterface;
