import { useEffect, useState } from "react";
import AgentsPopUp from "./AgentsPopUp";
import { deleteAgent, getAgents } from "../lib/APIService";
import Card from "./material-design/components/Card";
import MdButton from "./material-design/components/MdButton";
import ViewAgents from "./ViewAgents";

export default function Agents() {
  const [popup, setPopup] = useState(false);
  const [agents, setAgents] = useState<any[]>([]);
  const [editMode, setEditMode] = useState(false);
  const [currentAgent, setCurrentAgent] = useState<any>(null);

  useEffect(() => {
    const fetchAgents = async () => {
      const response = await getAgents();
      setAgents(response);
    };

    fetchAgents();
  }, []);

  const handleAddAgent = (newAgent: any) => {
    setAgents((prevAgents: any[]) => [newAgent, ...prevAgents]);
  };

  const handleEditAgent = (updatedAgent: any) => {
    setAgents((prevAgents: any[]) =>
      prevAgents.map((agent) =>
        agent.id === updatedAgent.id ? updatedAgent : agent
      )
    );
  };

  const handleDelete = (deletedAgentId: any) => {
    setAgents((prevAgents: any[]) =>
      prevAgents.filter((agent) => agent.id !== deletedAgentId)
    );
    setEditMode(false); 
  };

  const openAddPopup = () => {
    setCurrentAgent(null);
    setPopup(true);
  };

  const openEditPopup = (agent: any) => {
    setCurrentAgent(agent);
    setEditMode(true); 
  };

  const closeEditMode = () => {
    setEditMode(false); 
  };

  return (
    <>
      {editMode ? (
        <ViewAgents
          setEditMode={setEditMode}
          onEditAgent={handleEditAgent}
          editMode={editMode}
          currentAgent={currentAgent}
          onDeleteAgent={handleDelete}
          closeEditMode={closeEditMode} 
        />
      ) : (
        <div className="scroll">
          <nav
            className="left-padding bottom-padding right-padding"
            style={{
              borderBottom: "1px solid #ddd",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div className="chat-name">Agents</div>
            <button className="small-round medium" onClick={openAddPopup}>
              <i>add</i>
              <span>Add</span>
            </button>
          </nav>

          {popup && (
            <AgentsPopUp
              setPopup={setPopup}
              popup={popup}
              onAddAgent={handleAddAgent}
              onEditAgent={handleEditAgent}
              editMode={editMode}
              currentAgent={currentAgent}
            />
          )}

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
              alignItems: "flex-start",
              alignContent: "flex-start",
              scrollBehavior: "smooth",
              paddingBottom: "100px",
              overflowY: "auto",
            }}
          >
            {agents &&
              !!agents.length &&
              agents.map((agent: any) => (
                <Card
                  key={agent.id}
                  id={agent.id}
                  name={JSON.parse(agent.details).name}
                  iconSrc={agent.iconSrc}
                  description={JSON.parse(agent.details).instructions}
                  onClick={() => openEditPopup(agent)}
                />
              ))}
          </div>
        </div>
      )}
    </>
  );
}