import React, { useEffect, useState } from "react";
import CredentialModal from "./Credential/AddCredentialModal";
import CredentialForm from "./Credential/CredentialForm";
import { DeleteMultipleEnv, getAllEnvs } from "../lib/APIService";
import {
  componentCredentials,
  credentialsIcons,
} from "./Credential/CredentialData";

interface Credential {
  [x: string]: any;
  id: string;
  env_name: string;
  value: string;
  credential_name: string | null;
  userid: number;
}

interface CredentialInput {
  label: string;
  name: string;
  type: "string" | "password" | "number";
  placeholder: string;
  description?: string;
  optional?: boolean;
  value?: string;
  id?: any;
  created_at?: any;
  updated_at?: any;
}

interface CredentialDetails {
  isEdit?: any;
  id?: any;
  label: string;
  name: string;
  version: number;
  description?: string;
  optional?: boolean;
  inputs: CredentialInput[];
  icon: string;
  created_at?: any;
  updated_at?: any;
  credential_name?: any;
}

export default function Credentials() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [credentials, setCredentials] = useState<Credential[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false);
  const [credentialFormData, setCredentialFormData] = useState<any>({});
  const [selectedCredential, setSelectedCredential] =
    useState<CredentialDetails | null>(null);

  const getAllCredentials = async () => {
    const response = await getAllEnvs();
    let groupedData = groupByCredentialName(response);
    setCredentials(response!);
    setFilteredData(groupedData);
  };

  useEffect(() => {
    getAllCredentials();
  }, []);

  const groupByCredentialName = (response: any[]) => {
    const groupedData: any = response.reduce((acc: any, item: any) => {
      const key = item.credential_name || item.env_name;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {} as any);
    return groupedData;
  };

  useEffect(() => {
    if (searchQuery) {
      const lowerCaseSearchQuery = searchQuery.toLowerCase();
      const filtered = Object.entries(filteredData).reduce<any>(
        (acc, [key, items]) => {
          const credentialNameParts = key.split("__");
          const nameToSearch =
            credentialNameParts.length > 1 ? credentialNameParts[1] : key;

          if (nameToSearch.toLowerCase().includes(lowerCaseSearchQuery)) {
            acc[key] = items;
          }
          return acc;
        },
        {}
      );
      setFilteredData(filtered);
    } else {
      const groupedData = groupByCredentialName(credentials);
      setFilteredData(groupedData);
    }
  }, [searchQuery, credentials]);

  const handleCreateCredential = () => {
    setIsOpen(true);
  };

  const mapDataToInputs = (credential: any, data: any) => {
    const updatedCredential = { ...credential };
    updatedCredential.isEdit = true;

    if (updatedCredential.name === "otherApi") {
      updatedCredential.id = data.find((item: any) => item.id)?.id;
      updatedCredential.created_at = data.find((item: any) => item.created_at);
      updatedCredential.updated_at = data.find((item: any) => item.updated_at);
      updatedCredential.inputs = [
        {
          label: "Env Name",
          name: "env_name",
          type: "string",
          placeholder: "<ENV_NAME>",
          value: data.find((item: any) => item.env_name)?.env_name,
        },
        {
          label: "Env Value",
          name: "value",
          type: "string",
          placeholder: "<VALUE>",
          value: data.find((item: any) => item.value)?.value,
        },
      ];
    } else {
      updatedCredential.credential_id = data.find(
        (item: any) => item.env_name === "CREDENTIAL_NAME"
      )?.id;
      updatedCredential.credential_name = `${
        data.find((item: any) => item.env_name === "CREDENTIAL_NAME")?.value
      }`;
      updatedCredential.inputs = updatedCredential.inputs.map((input: any) => {
        const response = data.find((item: any) => item.env_name === input.name);
        if (response) {
          return {
            ...input,
            value: response.value,
            id: response.id,
            created_at: response.created_at,
            updated_at: response.updated_at,
          };
        }

        return input;
      });
    }
    return updatedCredential;
  };

  const handleEdit = (credentialName: string, data: any) => {
    const component: any = componentCredentials.find(
      (data) => data.name === credentialName
    );
    const response = mapDataToInputs(component, data);
    setIsDetailsOpen(true);
    setSelectedCredential(response!);
  };

  const handleAdd = (credential: any) => {
    const component: any =
      componentCredentials.find((data) => data.name === credential.name) ||
      componentCredentials.find((data) => data.label === "Others");

    const updatedComponent = {
      ...component,
    };
    setIsDetailsOpen(true);
    setSelectedCredential(updatedComponent!);
  };

  const handleCloseCredentialsForm = () => {
    setIsDetailsOpen(false);
    setSelectedCredential(null);
    getAllCredentials();
    setCredentialFormData({});
  };

  const handleDelete = async (data: Credential[]) => {
    const ids = data.map((item: any) => item.id);

    try {
      const response = await DeleteMultipleEnv(ids);
      getAllCredentials();
    } catch (error) {
      console.error("Failed to delete environment variables:", error);
    }
  };

  const rows =
    filteredData &&
    Object.keys(filteredData).length > 0 &&
    Object.entries(filteredData).map(([groupKey, items]) => {
      const hasDelimiter = groupKey.includes("__");

      const [credentialAPI, credentialName] = hasDelimiter
        ? groupKey.split("__")
        : ["otherApi", groupKey];

      return (
        <React.Fragment key={groupKey}>
          <tr>
            <td>
              <div className="group credential-name">
                <img
                  className="tiny circle"
                  src={credentialsIcons[credentialAPI || "otherApi"]}
                  style={{ objectFit: "contain" }}
                  alt={credentialName ? credentialName : groupKey}
                />
                <label>{credentialName ? credentialName : groupKey}</label>
              </div>
            </td>
            <td>
              <a
                className="small chip action-icon"
                onClick={() => handleEdit(credentialAPI, items)}
              >
                <i>edit</i>
              </a>
            </td>
            <td>
              <a
                className="small chip action-icon"
                onClick={() => handleDelete(items)}
              >
                <i>delete</i>
              </a>
            </td>
          </tr>
        </React.Fragment>
      );
    });

  return (
    <div >
        <nav
          className="left-padding bottom-padding right-padding"
          style={{
            borderBottom: "1px solid #ddd",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className="chat-name">Credentials</div>
        <div className="horizontal">
         
          <div className="field prefix border small">
            <i>search</i>
            <input
              type="text"
              placeholder="Search Credentials"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.currentTarget.value)}

            />
          </div>
           <button className="small-round medium" onClick={handleCreateCredential}>
            <i>add</i>
            <span>Add Credential</span>
          </button>
          </div>
        </nav>
      <div className="medium-padding">
      <div className="large-height scroll surface">
        <table className="border large-space">
          <thead>
            <tr>
              <th>Name</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
      </div>

      {isOpen && (
        <CredentialModal
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false);
            getAllCredentials();
          }}
          handleCredentialDetails={(data: any) => handleAdd(data)}
        />
      )}
      {isDetailsOpen && (
        <CredentialForm
          credential={selectedCredential!}
          isOpen={isDetailsOpen}
          onClose={handleCloseCredentialsForm}
        />
      )}
    </div>
  );
}
