import { Tooltip } from "@mantine/core";

export const CustomWidthTooltip = (props: {
  children: React.ReactNode;
  title: React.ReactNode;
}) => (
  <Tooltip
    label={props.title}
    width={500}
    withArrow
    style={{
      whiteSpace: "normal",
      width: "90%",
      background: "gray",
      display: "inline-block",
    }}
  >
    {props.children}
  </Tooltip>
);
