// Button.tsx
import React from 'react';

interface ButtonProps {
  onClick: () => void;
  icon: string;
  label: string;
  disable?:any

}

const MdButton: React.FC<ButtonProps> = ({ onClick, icon, label,disable }) => {
  return (
    <button disabled={disable} onClick={onClick} >
      <i>{icon}</i>
      <span>{label}</span>
    </button>
  );
};

export default MdButton;