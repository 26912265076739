import { Group } from "@mantine/core";
import { IconChartInfographic } from "@tabler/icons-react";
import { useEffect, useState } from "react";

import TGBarChart from "../../components/charts/TGBarChart";
import TGLineChart from "../../components/charts/TGLineChart";
import TGMetric from "../../components/charts/TGMetric";
import TGPieChart from "../../components/charts/TGPieChart";
import TGTreeMap from "../../components/charts/TGTreeMap";
import { camelCaseToWords } from "../../lib/Utils";

const Dashboard = ({
  graphData,
  lastConversation,
  chartDetailQuery,
  isShared,
}: any) => {
  const [gData, setgData] = useState([]);

  const RenderChart = (props: any) => {
    const { chartData, dataIndex, lastGraph, isShared, numberOfCharts } = props;

    const title = camelCaseToWords(dataIndex);
    let detailData = [] as any;
    if (["bar", "line"].includes(chartData.type) && !!chartData.data?.length) {
      Object.keys(chartData.data[0]).map((dataKey) => {
        if (dataKey !== "name") {
          detailData.push({
            dataKey,
            label: dataKey.charAt(0).toUpperCase() + dataKey.slice(1),
          });
        }
      });
    }
    if (chartData.type === "metric") {
      Object.keys(chartData.data).map((dataKey) => {
        const value = chartData.data[dataKey];
        detailData.push([
          camelCaseToWords(dataKey),
          isNaN(value) ? value : `${(value * 100).toFixed(1)} %`,
        ]);
      });
    }
    switch (chartData.type) {
      case "bar":
        return (
          <div className="chart-container">
            <Group position="apart">
              <h2>{title}</h2>
              {isShared && numberOfCharts > 1 && (
                <IconChartInfographic
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    props.chartDetailQuery(`Give me more details on ${title}`)
                  }
                />
              )}
            </Group>
            <TGBarChart
              data={chartData.data}
              bars={detailData}
              allowAnimation={!!lastGraph}
            />
          </div>
        );
      case "line":
        return (
          <div className="chart-container">
            <Group position="apart">
              <h2>{title}</h2>
              {isShared && numberOfCharts > 1 && (
                <IconChartInfographic
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    props.chartDetailQuery(`Give me more details on ${title}`)
                  }
                />
              )}
            </Group>
            <TGLineChart
              data={chartData.data}
              lines={detailData}
              allowAnimation={!!lastGraph}
            />
          </div>
        );
      case "pie":
        return (
          <div className="chart-container">
            <Group position="apart">
              <h2>{title}</h2>
              {isShared && numberOfCharts > 1 && (
                <IconChartInfographic
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    props.chartDetailQuery(`Give me more details on ${title}`)
                  }
                />
              )}
            </Group>
            <TGPieChart data={chartData.data} allowAnimation={!!lastGraph} />
          </div>
        );
      case "treemap":
        return (
          <div className="chart-container">
            <Group position="apart">
              <h2>{title}</h2>
              {isShared && numberOfCharts > 1 && (
                <IconChartInfographic
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    props.chartDetailQuery(`Give me more details on ${title}`)
                  }
                />
              )}
            </Group>
            <TGTreeMap data={chartData.data} allowAnimation={!!lastGraph} />
          </div>
        );
      case "metric":
        return <TGMetric header={title} rows={detailData} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    setgData(graphData);
  }, [graphData]);

  return (
    <div className="dashboard">
      {/* {Object.keys(sampleData).map((dataIndex: any) => {
        const dataIndexTyped = dataIndex as keyof typeof sampleData;
        let chartData = sampleData[dataIndexTyped];
        return (
          <RenderChart
            key={dataIndex}
            dataIndex={dataIndex}
            chartData={chartData}
          />
        );
      })} */}

      {gData.length > 0 &&
        gData.map((graph: any, index: any) => {
          return (
            <RenderChart
              key={graph.graph_name}
              dataIndex={graph.graph_name}
              chartData={{ type: graph.type, data: graph.data }}
              lastGraph={index === gData.length - 1 && !!lastConversation}
              chartDetailQuery={chartDetailQuery}
              isShared={isShared}
              numberOfCharts={gData.length}
            />
          );
        })}
    </div>
  );
};

export default Dashboard;
