import {
  Box,
  Button,
  Table,
  Title,
  TextInput,
  Textarea,
  Select,
} from "@mantine/core";
import { IconChevronLeft, IconTrash, IconCheck } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { getToolGroup, getTools, updateToolGroup } from "../lib/APIService";
import MdButton from "./material-design/components/MdButton";

interface ToolGroup {
  id?: any;
  name: string;
  description: string;
  tools: any[];
  createdDate?: any;
  updatedDate?: any;
  model: string;
  credential: string;
}

interface ToolGroupDetails {
  toolGroupId: string;
  onClose: () => void;
  onUpdate: (data: any) => void;
  type: any;
}
interface Tool {
  id: any;
  name: string;
  description?: string;
  iconSrc?: string;
  createdDate?: any;
  updatedDate?: any;
}
export default function ViewToolGroup({
  toolGroupId,
  onClose,
  onUpdate,
  type,
}: ToolGroupDetails) {
  const [toolGroup, setToolGroup] = useState<ToolGroup>({
    name: "",
    description: "",
    tools: [],
    model: "",
    credential: "",
  });
  const [toolsList, setToolsList] = useState<Tool[]>([]);
  const [tools, setTools] = useState<Tool[]>([]);
  const [selectedTool, setSelectedTool] = useState<any | null>(null);
  const [availableTools, setAvailableTools] = useState<any[]>([]);

  useEffect(() => {
    const getToolsList = async () => {
      const response = await getTools();
      const cleanedTools = response?.map((tool: Tool) => {
        const { createdDate, updatedDate, ...rest } = tool;
        return rest;
      });
      setToolsList(cleanedTools);
    };

    getToolsList();
  }, []);

  useEffect(() => {
    if (tools && !!tools.length) {
      if (!!toolsList.length) {
        const availableTools = toolsList.filter(
          (totalTool: Tool) =>
            !tools.some((tool: Tool) => tool.name === totalTool.name)
        );
        setAvailableTools(availableTools);
      }
    } else {
      setAvailableTools(toolsList);
    }
  }, [tools, toolsList]);

  useEffect(() => {
    async function fetchToolGroupDetails() {
      const response = await getToolGroup(toolGroupId, type);
      setToolGroup(response);
      setTools(response.tools);
    }
    fetchToolGroupDetails();
  }, [toolGroupId]);

  const handleSave = async () => {
    const cleanedTools = tools?.map((tool: Tool) => {
      const { createdDate, updatedDate, ...rest } = tool;
      return rest;
    });
    const updatedToolGroup = {
      ...toolGroup,
      tools: cleanedTools,
    };
    try {
      const response = await updateToolGroup(toolGroup?.id, updatedToolGroup);
      if (response) {
        onUpdate(response);
        onClose();
      }
    } catch (error) {
      console.error("Failed to save tool group tools:", error);
    }
  };

  const handleDelete = async (toolToDelete: any) => {
    try {
      const updatedTools = tools.filter((tool: any) => tool !== toolToDelete);
      setTools(updatedTools);
      const updatedToolGroup = {
        ...toolGroup,
        tools: updatedTools,
      };
      const response = await updateToolGroup(toolGroup?.id, updatedToolGroup);
      if (response) {
        onUpdate(response);
      }
    } catch (error) {
      console.error("Failed to delete tool from tool group:", error);
    }
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setToolGroup((prevDetails: any) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleAddTool = () => {
    if (
      selectedTool &&
      !tools.some((tool) => tool.name === selectedTool.name)
    ) {
      const value: any = toolsList.filter(
        (tool: Tool) => tool.name == selectedTool
      );
      setTools([...tools, value[0]]);
      setSelectedTool(null);
    }
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <nav
        style={{ paddingBottom: "2rem", display: "flex", alignItems: "center" }}
      >
        <button className="circle transparent" onClick={onClose}>
          <i>arrow_back</i>
        </button>
        <h6 className="max">Tool Group</h6>
      </nav>

      <div style={{ flex: 1, overflow: "auto", paddingLeft: "2rem" }}>
        <div style={{ marginBottom: "1rem" }}>
          <span>Name:</span>
          <div className="field border">
            <input
              type="text"
              name="name"
              value={toolGroup.name || ""}
              onChange={handleInputChange}
              readOnly={type === "system"}
              style={{ width: "500px" }}
            />
          </div>
        </div>

        <div style={{ marginBottom: "1rem" }}>
          <span>Description:</span>
          <div className="field textarea border">
            <textarea
              name="description"
              value={toolGroup.description || ""}
              onChange={handleInputChange}
              readOnly={type === "system"}
              style={{ width: "500px" }}
            ></textarea>
          </div>
        </div>

        {type === "user" && (
          <>
            <h6 className="small">Add Tool:</h6>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <button
                className="dropdown-trigger"
                style={{ flex: "1 1 50%", maxWidth: "50%" }}
              >
                <span>{selectedTool || "Select a tool"}</span>
                <i>arrow_drop_down</i>
                <menu className="dropdown-menu">
                  {availableTools &&
                    availableTools.length > 0 &&
                    availableTools.map((tool) => (
                      <a
                        key={tool.name}
                        onClick={() => setSelectedTool(tool.name)}
                      >
                        {tool.name}
                      </a>
                    ))}
                </menu>
              </button>
              <button className="primary-button" onClick={handleAddTool}>
                Add
              </button>
            </div>
          </>
        )}

        <h6 className="small">Added Tools:</h6>
        <div style={{ marginTop: "2rem", flexGrow: 1, overflow: "auto" }}>
          {tools && tools.length > 0 ? (
            tools.map((tool, index) => (
              <a
                className="chip"
                key={index}
                onClick={type === "user" ? () => handleDelete(tool) : undefined}
                style={{
                  marginBottom: "1rem",
                  cursor: type === "user" ? "pointer" : "not-allowed",
                }}
              >
                <span>{tool.name}</span>
                <i>close</i>
              </a>
            ))
          ) : (
            <p>No tools added yet.</p>
          )}
        </div>

        <div style={{ marginTop: "2rem", display: "flex" }}>
          <MdButton
            onClick={handleSave}
            icon={"check"}
            label={"Save"}
            disable={type === "system"}
          />
        </div>
      </div>
    </div>
  );
}
