interface MessageData {
  type: string;
  content: string;
  showGraph?: boolean;
  graphType?: string;
  newReply?: boolean;
  sources?: string[];
  additional_kwargs?: any;
}

export const messagesDemo: MessageData[] = [
  {
    type: "human",
    content:
      "Can you iterate the weather trends of Christmas over the last 10 years",
  },
  {
    type: "ai",
    content:
      "Sure based on the data I can get hold of, following is the weather trends during Christmas week over the last 10 years. Please note that the information may not be accurate.",
    showGraph: true,
    graphType: "pie",
  },
  {
    type: "human",
    content: "I want the temperatures to be in centigrade",
  },
  {
    type: "ai",
    content:
      "Alright, I have converted the temparatures in centigrade/celsius.",
    showGraph: true,
    graphType: "bar",
  },
];
export const data01 = [
  {
    name: "Group A",
    value: 400,
  },
  {
    name: "Group B",
    value: 300,
  },
  {
    name: "Group C",
    value: 300,
  },
  {
    name: "Group D",
    value: 200,
  },
  {
    name: "Group E",
    value: 278,
  },
  {
    name: "Group F",
    value: 189,
  },
];
export const data02 = [
  {
    name: "Group A",
    value: 2400,
  },
  {
    name: "Group B",
    value: 4567,
  },
  {
    name: "Group C",
    value: 1398,
  },
  {
    name: "Group D",
    value: 9800,
  },
  {
    name: "Group E",
    value: 3908,
  },
  {
    name: "Group F",
    value: 4800,
  },
];
export const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
  },
];

const awsData = [
  {
    TimePeriod: { Start: "2024-01-01", End: "2024-01-02" },
    Total: { BlendedCost: { Amount: "0.2272012464", Unit: "USD" } },
    Groups: [],
    Estimated: false,
  },
  {
    TimePeriod: { Start: "2024-01-02", End: "2024-01-03" },
    Total: { BlendedCost: { Amount: "0.2272012469", Unit: "USD" } },
    Groups: [],
    Estimated: false,
  },
  {
    TimePeriod: { Start: "2024-01-03", End: "2024-01-04" },
    Total: { BlendedCost: { Amount: "0.2272012459", Unit: "USD" } },
    Groups: [],
    Estimated: false,
  },
  {
    TimePeriod: { Start: "2024-01-04", End: "2024-01-05" },
    Total: { BlendedCost: { Amount: "0.2272012465", Unit: "USD" } },
    Groups: [],
    Estimated: false,
  },
  {
    TimePeriod: { Start: "2024-01-05", End: "2024-01-06" },
    Total: { BlendedCost: { Amount: "0.2272012473", Unit: "USD" } },
    Groups: [],
    Estimated: false,
  },
  {
    TimePeriod: { Start: "2024-01-06", End: "2024-01-07" },
    Total: { BlendedCost: { Amount: "0.2272012462", Unit: "USD" } },
    Groups: [],
    Estimated: false,
  },
  {
    TimePeriod: { Start: "2024-01-07", End: "2024-01-08" },
    Total: { BlendedCost: { Amount: "0.227201246", Unit: "USD" } },
    Groups: [],
    Estimated: false,
  },
  {
    TimePeriod: { Start: "2024-01-08", End: "2024-01-09" },
    Total: { BlendedCost: { Amount: "0.2272012466", Unit: "USD" } },
    Groups: [],
    Estimated: false,
  },
  {
    TimePeriod: { Start: "2024-01-09", End: "2024-01-10" },
    Total: { BlendedCost: { Amount: "0.2272012463", Unit: "USD" } },
    Groups: [],
    Estimated: false,
  },
  {
    TimePeriod: { Start: "2024-01-10", End: "2024-01-11" },
    Total: { BlendedCost: { Amount: "0.0013948459", Unit: "USD" } },
    Groups: [],
    Estimated: false,
  },
  {
    TimePeriod: { Start: "2024-01-11", End: "2024-01-12" },
    Total: { BlendedCost: { Amount: "-0.0953793939", Unit: "USD" } },
    Groups: [],
    Estimated: false,
  },
  {
    TimePeriod: { Start: "2024-01-12", End: "2024-01-13" },
    Total: { BlendedCost: { Amount: "-0.0953793938", Unit: "USD" } },
    Groups: [],
    Estimated: false,
  },
  {
    TimePeriod: { Start: "2024-01-13", End: "2024-01-14" },
    Total: { BlendedCost: { Amount: "-0.0953793933", Unit: "USD" } },
    Groups: [],
    Estimated: false,
  },
  {
    TimePeriod: { Start: "2024-01-14", End: "2024-01-15" },
    Total: { BlendedCost: { Amount: "-0.0953793939", Unit: "USD" } },
    Groups: [],
    Estimated: false,
  },
  {
    TimePeriod: { Start: "2024-01-15", End: "2024-01-16" },
    Total: { BlendedCost: { Amount: "-0.0953793936", Unit: "USD" } },
    Groups: [],
    Estimated: false,
  },
  {
    TimePeriod: { Start: "2024-01-16", End: "2024-01-17" },
    Total: { BlendedCost: { Amount: "-0.0953793933", Unit: "USD" } },
    Groups: [],
    Estimated: false,
  },
  {
    TimePeriod: { Start: "2024-01-17", End: "2024-01-18" },
    Total: { BlendedCost: { Amount: "-0.0953793937", Unit: "USD" } },
    Groups: [],
    Estimated: false,
  },
  {
    TimePeriod: { Start: "2024-01-18", End: "2024-01-19" },
    Total: { BlendedCost: { Amount: "-0.0953793937", Unit: "USD" } },
    Groups: [],
    Estimated: false,
  },
  {
    TimePeriod: { Start: "2024-01-19", End: "2024-01-20" },
    Total: { BlendedCost: { Amount: "-0.0953793936", Unit: "USD" } },
    Groups: [],
    Estimated: false,
  },
  {
    TimePeriod: { Start: "2024-01-20", End: "2024-01-21" },
    Total: { BlendedCost: { Amount: "-0.0953793934", Unit: "USD" } },
    Groups: [],
    Estimated: false,
  },
  {
    TimePeriod: { Start: "2024-01-21", End: "2024-01-22" },
    Total: { BlendedCost: { Amount: "-0.0953793939", Unit: "USD" } },
    Groups: [],
    Estimated: false,
  },
  {
    TimePeriod: { Start: "2024-01-22", End: "2024-01-23" },
    Total: { BlendedCost: { Amount: "-0.0953793944", Unit: "USD" } },
    Groups: [],
    Estimated: false,
  },
  {
    TimePeriod: { Start: "2024-01-23", End: "2024-01-24" },
    Total: { BlendedCost: { Amount: "-0.0953793939", Unit: "USD" } },
    Groups: [],
    Estimated: false,
  },
  {
    TimePeriod: { Start: "2024-01-24", End: "2024-01-25" },
    Total: { BlendedCost: { Amount: "-0.0953793939", Unit: "USD" } },
    Groups: [],
    Estimated: false,
  },
  {
    TimePeriod: { Start: "2024-01-25", End: "2024-01-26" },
    Total: { BlendedCost: { Amount: "-0.0953793942", Unit: "USD" } },
    Groups: [],
    Estimated: false,
  },
  {
    TimePeriod: { Start: "2024-01-26", End: "2024-01-27" },
    Total: { BlendedCost: { Amount: "-0.1004662979", Unit: "USD" } },
    Groups: [],
    Estimated: false,
  },
  {
    TimePeriod: { Start: "2024-01-27", End: "2024-01-28" },
    Total: { BlendedCost: { Amount: "-0.1030097497", Unit: "USD" } },
    Groups: [],
    Estimated: false,
  },
  {
    TimePeriod: { Start: "2024-01-28", End: "2024-01-29" },
    Total: { BlendedCost: { Amount: "-0.1030097495", Unit: "USD" } },
    Groups: [],
    Estimated: false,
  },
  {
    TimePeriod: { Start: "2024-01-29", End: "2024-01-30" },
    Total: { BlendedCost: { Amount: "-0.1030097506", Unit: "USD" } },
    Groups: [],
    Estimated: false,
  },
  {
    TimePeriod: { Start: "2024-01-30", End: "2024-01-31" },
    Total: { BlendedCost: { Amount: "-0.10300975", Unit: "USD" } },
    Groups: [],
    Estimated: false,
  },
];

export const awsDataFormatted = awsData.map((item) => {
  return {
    timeperiod: item.TimePeriod.Start,
    amount: item.Total.BlendedCost.Amount,
  };
});

export const DefaultQuestions = [
  {
    label: "Trust Assurance Agent",
    text: "Validate tech stack security and get remediation.",
    role: "k8s-analyst",
    query: "Hi",
    icon: "/trust_assurance.svg",
    // height: 18,
    // width: 18,
  },
  {
    label: "Cloud Analyst",
    text: "Analyse cloud resources, incidents and costs",
    role: "awsexpert",
    query: "Hi",
    height: 18,
    width: 26,
    icon: "/cloud_analyst.svg",
  },
  {
    label: "Security Analytics Agent",
    text: "Get Visibility on your Security Posture Reporting",
    role: "executive-assistant",
    query: "Hi",
    icon: "/security_analyst.svg",
    // height: 18,
    // width: 18,
  },
  // {
  //   label: "Incident Analyst",
  //   text: "Analyze Incidents and get insights on RCAs and PRBs",
  //   role: "incident-analyst",
  //   query: "Hi",
  //   // height: 20,
  //   // width: 16,
  // },
  {
    label: "Supervisor",
    text: "Get Security Posture and Track Issues across platform",
    role: "multiagent-graph",
    query: "Hi",
    icon: "/multi-agent.svg",
    // height: 18,
    // width: 18,
  },
  {
    label: "Sql Agent",
    text: "Get Security Posture and Track Issues across platform",
    role: "sql-graph",
    query: "Hi",
    icon: "/data-analyst.svg",
    // height: 18,
    // width: 18,
  },
  // {
  //   label: "Anypoint Trust Agent",
  //   text: "Ask any question related to Anypoint Platform",
  //   role: "anypoint-trust",
  //   icon: "/anypoint_trust.svg",
  //   query: "Hi",
  //   height: 26,
  //   width: 26,
  // },
  {
    label: "Bug Triage",
    text: "Help with bug triage and resolution",
    role: "bug-triage",
    icon: "/bug.png",
    query: "Hi",
    // height: 18,
    // width: 18,
  },
  // {
  //   label: "Anypoint Trust Agent",
  //   text: "Ask any question related to Anypoint Platform",
  //   role: "anypoint-trust",
  //   icon: "/anypoint_trust.svg",
  //   query: "Hi",
  //   height: 26,
  //   width: 26,
  // },
  // {
  //   label: "Trust Expert",
  //   text: "Get information from the Graph Knowledge Base ",
  //   role: "trust-expert-graph-rag",
  //   query: "Hi",
  //   icon: "/graph.svg",
  // },
];
