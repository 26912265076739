import { useState, useEffect } from "react";
import {
  deleteDocumentStoreAPI,
  editDocumentStoreAPI,
  getDocumentStoreAPI,
  uploadDocumentsStoreAPI,
  getDocumentsStoreAPI,
  deleteDocumentsStoreAPI,
  updateStoreforDocumentStore,
  uploadLink,
  deleteLink,
  getLinks,
  getDocumentsFromS3,
  uploadDocumentToS3,
  deleteDocumentFromS3,
} from "../lib/APIService";

import axios from "axios";
import { Anchor, createStyles, useMantineTheme } from "@mantine/core";
import { FileWithPath } from "@mantine/dropzone";
import Card from "./material-design/components/Card";
import StoreLoaderPopup from "./StoreLoaderPopup";

const USER_ENDPOINT =
  process.env.REACT_APP_BACKEND_URL || "http://localhost:4000";

const useStyles = createStyles((theme) => ({
  text: {
    fontWeight: 400,
    size: 16,
    fontSize: 14,
  },
}));

const PdfViewer = ({ pdfName, store_id, storetype }: any) => {
  const handleOpenPdf = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const baseUrl = storetype === "system" 
      ? `${USER_ENDPOINT}/api/v1/docsapi/docs` 
      : `${USER_ENDPOINT}/api/v1/docsapi/s3/docs`;
    const pdfUrl = `${baseUrl}/${store_id}/${pdfName}`;

    try {
      const response = await axios.get(pdfUrl, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      // Create a Blob from the PDF Stream
      const file = new Blob([response.data], { type: "application/pdf" });
      // Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      // Open the URL in a new window
      const pdfWindow = window.open();
      if (pdfWindow) {
        pdfWindow.location.href = fileURL;
      }
    } catch (error) {
      console.error("Error opening PDF:", error);
    }
  };

  return (
    <button onClick={handleOpenPdf} style={{ cursor: "pointer" }}>
      View
    </button>
  );
};

const getIconSrc = (toolName: string) => {
  if (toolName === "s3") {
    return "/s3.svg";
  }
  if (toolName === "gcp") {
    return "/gcp.svg";
  }
};

export default function DocumentStoreUpload({
  store,
  onClose,
  uploadOpen,
  updateStore,
}: any) {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editedName, setEditedName] = useState(store.name);
  const [editedType, setEditedType] = useState(store.type);
  const [editedDescription, setEditedDescription] = useState(store.description);
  const [uploadfileOpen, setUploadfileOpen] = useState(false);
  const [UploadUrlOpen, setUploadUrlOpen] = useState(false);
  const [pdfFiles, setPdfFiles] = useState<FileWithPath[]>([]);
  const [googleDocsLinks, setGoogleDocsLinks] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const [uploadedLinks, setUploadedLinks] = useState<any[]>([]);
  const [loaderOpen, setLoaderOpen] = useState(false);
  const [selectedLoaders, setSelectedLoaders] = useState<string[]>([]);
  const [s3DialogOpen, setS3DialogOpen] = useState(false);
  const [gcpDialogOpen, setGcpDialogOpen] = useState(false);
  const [s3BucketUrl, setS3BucketUrl] = useState("");
  const [gcpBucketUrl, setGcpBucketUrl] = useState("");
  const [s3BucketName, setS3BucketName] = useState("");
  const [gcpBucketName, setGcpBucketName] = useState("");
  const [editedSubType, setEditedSubType] = useState(store.subtype || "");

  const handleLogoClick = (loader: string) => {
    OnCloseLoader();
    if (loader === "s3") {
      setS3DialogOpen(true);
    } else {
      setGcpDialogOpen(true);
    }
  };

  const OnCloseLoader = () => {
    setLoaderOpen(false);
  };

  const addLoader = (loader: string) => {
    setSelectedLoaders((prevLoaders) => [...prevLoaders, loader]);
  };
  useEffect(() => {
    const fetchStore = async () => {
      try {
        const updatedStore = await getDocumentStoreAPI(store.id);
        setEditedName(updatedStore.name);
        setEditedType(updatedStore.type);
        setEditedDescription(updatedStore.description);
        setEditedSubType(updatedStore.subtype || "");
      } catch (error) {
        console.log("Error fetching store data:", error);
      }
    };

    const fetchDocuments = async () => {
      try {
        if (store.type === "s3") {
          const documents = await getDocumentsFromS3(store.id);
          setUploadedFiles(documents);
        } else {
          const documents = await getDocumentsStoreAPI(store.id);
          setUploadedFiles(documents);
        }
      } catch (error) {
        console.log("Error fetching documents:", error);
      }
    };

    if (store && uploadOpen) {
      fetchStore();
      fetchDocuments();
    }
  }, [store, uploadOpen, refresh]);

  const handleEdit = () => {
    setEditModalOpen(true);
  };

  const handleDelete = async () => {
    try {
      await deleteDocumentStoreAPI(store.id);
      updateStore();
      onClose();
    } catch (error) {
      console.log("Error deleting document store:", error);
    }
  };

  const handleUpdate = async () => {
    try {
      const editParams: {
        store_id: any;
        name: any;
        type: any;
        description: any;
        store_subtype?: string;
      } = {
        store_id: store.id,
        name: editedName,
        type: editedType,
        description: editedDescription,
      };

      if (editedType === "system") {
        editParams.store_subtype = editedSubType;
      }
      await editDocumentStoreAPI(editParams);
      updateStore();
      await updateStoreforDocumentStore({
        storeType: editedSubType,
        storeId: store.id,
      });
      setEditModalOpen(false);
    } catch (error) {
      console.log("Error updating document store:", error);
    }
  };

   const uploadFiles = async () => {
  setLoading(true);
  try {
    let response;

    if (store.type === "s3") {
      response = await uploadDocumentToS3({
        file: pdfFiles[0],
        store_id: store.id,
      });
      if (response.status === "success") {
        setPdfFiles([]);
        setUploadSuccess(true);

        setTimeout(() => {
          setUploadfileOpen(false);
          setRefresh((prev) => prev + 1);
        }, 2000);
      }
    } else {
      response = await uploadDocumentsStoreAPI({
        file: pdfFiles[0],
        store_id: store.id,
      });
      if (response.status === "success") {
        setPdfFiles([]);
        setUploadSuccess(true);

        await updateStoreforDocumentStore({
          storeType: store.store_subtype,
          storeId: store.id,
        });

        setTimeout(() => {
          setUploadfileOpen(false);
          setRefresh((prev) => prev + 1);
        }, 2000);
      }
    }
  } catch (error) {
    console.log("Error uploading document:", error);
  } finally {
    setLoading(false);
  }
};

  const handleDeleteFile = async (storeId: string, documentId: string) => {
    try {
      if (store.type === "s3") {
        await deleteDocumentFromS3(store.id, documentId);
      } else {
        await deleteDocumentsStoreAPI({
          store_id: store.id,
          document_id: documentId,
        });
      }
      setRefresh((prev) => prev + 1);
    } catch (error) {
      console.log("Error deleting document:", error);
    }
  };
  const isConfirmDisabled = editedType === "system" && !editedSubType;
  // Fetch uploaded links
  useEffect(() => {
    const fetchLinks = async () => {
      try {
        const links = await getLinks(store.id);
        setUploadedLinks(links);
      } catch (error) {
        console.log("Error fetching links:", error);
      }
    };

    if (store && uploadOpen) {
      fetchLinks();
    }
  }, [store, uploadOpen, refresh]);

  const uploadurl = async () => {
    setLoading(true);
    try {
      const linksArray = googleDocsLinks.split(/[\s,]+/).filter((link) => link);

      for (const link of linksArray) {
        const response = await uploadLink({
          storeId: store.id,
          link: link.trim(),
        });

        if (response.status === "success") {
          setUploadSuccess(true);
        }
      }

      setGoogleDocsLinks("");
      setRefresh((prev) => prev + 1);
      await updateStoreforDocumentStore({
        storeType: store.type,
        storeId: store.id,
      });

      setTimeout(() => {
        setUploadUrlOpen(false);
      }, 100);
    } catch (error) {
      console.log("Error uploading links:", error);
    }

    setLoading(false);
  };

  const handleDeleteLink = async (linkId: string) => {
    try {
      await deleteLink({ storeId: store.id, linkId: linkId });
      setRefresh((prev) => prev + 1);
    } catch (error) {
      console.log("Error deleting link:", error);
    }
  };

  return (
    <div style={{ overflow: "auto" }}>
      <nav
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "1rem",
        }}
      >
        <button className="circle transparent" onClick={onClose}>
          <i>arrow_back</i>
        </button>
        <h6 className="max">{editedName || store.name}</h6>
        <div style={{ display: "flex", gap: "0.5rem" }}>
          <button className="circle transparent" onClick={handleEdit}>
            <i>edit</i>
          </button>
          <button className="circle transparent" onClick={handleDelete}>
            <i>delete</i>
          </button>

          <button
            className="small-round medium"
            onClick={() => setLoaderOpen(true)}
          >
            <i>add</i>
            <span>Add</span>
          </button>
        </div>
      </nav>

      <dialog
        open={loaderOpen}
        className="auto blue-grey1"
        style={{ width: "50%", overflow: "hidden" }}
      >
        <div className="credential-header" style={{ alignItems: "center" }}>
          <h6>Add Document Loader</h6>
          <button className="transparent square" onClick={OnCloseLoader}>
            <i>close</i>
          </button>
        </div>
        <div className="px-10">
          <div className="field prefix border small">
            <i>search</i>
            <input type="text" placeholder="Search" />
          </div>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: "10px",
            borderRadius: "10px",
          }}
        >
          <div
            style={{ height: "20vh", overflow: "auto", borderRadius: "0px" }}
          >
            <article
              onClick={() => handleLogoClick("s3")}
              className="env-card-wrapper"
            >
              <div
                className="group align-items-center"
                style={{
                  gap: "10px",
                  padding: "10px",
                }}
              >
                <img
                  className="small"
                  src={getIconSrc("s3")}
                  style={{
                    objectFit: "contain",
                  }}
                />
                <h6
                  className="small align-items-center"
                  style={{
                    marginBlockStart: "0px",
                  }}
                >
                  S3 Bucket
                </h6>
              </div>
            </article>
          </div>

          <article
            onClick={() => handleLogoClick("gcp")}
            className="env-card-wrapper"
          >
            <div
              className="group align-items-center"
              style={{
                gap: "10px",
                padding: "10px",
              }}
            >
              <img
                className="small"
                src={getIconSrc("gcp")}
                style={{
                  objectFit: "contain",
                }}
              />
              <h6
                className="small align-items-center"
                style={{
                  marginBlockStart: "0px",
                }}
              >
                GCP Bucket
              </h6>
            </div>
          </article>
        </div>
      </dialog>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem",
          flexWrap: "wrap",
        }}
      >
        <article
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",

            height: "140px",
            width: "280px",
          }}
        >
          <text>Upload Files here</text>
          <text>Limit 10MB per file, .pdf, .doc, .txt</text>

          <button
            className="small-round medium"
            onClick={() => setUploadfileOpen(true)}
          >
            <i>add</i>
            <span>Upload Files</span>
          </button>
        </article>

        <article
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            height: "140px",
            width: "280px",
            padding: "1rem",
            margin: "0.5rem",
          }}
        >
          <text>Paste Links here</text>
          <text>Google Drive, Web Links</text>

          <button
            className="small-round medium"
            onClick={() => setUploadUrlOpen(true)}
          >
            <i>add</i>
            <span>Upload Url</span>
          </button>
        </article>
        {selectedLoaders.includes("s3") && (
          <Card name={s3BucketName} iconSrc={getIconSrc("s3")} />
        )}
        {selectedLoaders.includes("gcp") && (
          <Card name={gcpBucketName} iconSrc={getIconSrc("gcp")} />
        )}
      </div>

      <dialog
        open={editModalOpen}
        className="auto blue-grey1"
        style={{
          overflow: "hidden",
          width: "90vw",
          maxWidth: "500px",
          padding: "2rem",
          boxSizing: "border-box",
        }}
      >
        <h6>Edit Document Store</h6>
        <div style={{ marginTop: "2rem" }}>
          <div className="field border label" style={{ width: "100%" }}>
            <input
              type="text"
              value={editedName}
              onChange={(event) => setEditedName(event.currentTarget.value)}
              style={{ width: "100%" }}
            />
            <label className="small-opacity">NAME</label>
          </div>

          <div
            className="field textarea border label"
            style={{ width: "100%" }}
          >
            <textarea
              // placeholder="Document store description"
              value={editedDescription}
              onChange={(event) =>
                setEditedDescription(event.currentTarget.value)
              }
              style={{ width: "100%", minHeight: "100px" }}
            ></textarea>
            <label className="small-opacity">DESCRIPTION</label>
          </div>

          <div className="field label suffix border">
            <select
              onChange={(e) => setEditedType(e.target.value)}
              value={editedType}
            >
              <option value="system">System</option>
              <option value="s3">S3</option>
            </select>
            <label className="small-opacity">TYPE</label>
            <i>arrow_drop_down</i>
          </div>

          {editedType === "system" && (
            <div className="field label suffix border">
              <select
                value={editedSubType}
                onChange={(e) => setEditedSubType(e.target.value)}
              >
                <option value="">Select Subtype</option>
                <option value="vector store">Vector Store</option>
                <option value="graph store">Graph Store</option>
              </select>
              <label>Subtype</label>
              <i>arrow_drop_down</i>
            </div>
          )}
        </div>

        <nav
          className="right-align no-space"
          style={{
            marginTop: "1rem",
            display: "flex",
            justifyContent: "flex-end",
            gap: "1rem",
          }}
        >
          <button
            className="transparent link"
            onClick={() => setEditModalOpen(false)}
          >
            Cancel
          </button>
          <button
            className="transparent link"
            onClick={handleUpdate}
            disabled={isConfirmDisabled}
          >
            Confirm
          </button>
        </nav>
      </dialog>

      {uploadfileOpen && (
        <>
          <dialog
            open={uploadfileOpen}
            className="auto blue-grey1"
            style={{
              display: "flex",
              flexDirection: "column",
              width: "80%",
              maxWidth: "600px",
              margin: "auto",
              padding: "16px",
              boxSizing: "border-box",
            }}
          >
            <text style={{ fontSize: "18px" }}>Upload Files</text>

            <article
              className="border medium no-padding middle-align center-align"
              style={{ height: "15rem", overflowY: "auto" }}
            >
              <div className="padding">
                <p style={{ color: "#919399" }}>
                  Upload PDF, DOC, TXT files to add context to chat
                </p>
                <p>Limit 10MB per file, .pdf, .doc, .txt file formats</p>
                <input
                  type="file"
                  accept=".pdf,.doc,.docx,.txt"
                  onChange={(e) => {
                    if (e.target.files) {
                      setPdfFiles(Array.from(e.target.files));
                    }
                  }}
                  multiple
                  style={{ width: "100%" }} // Make the input full width
                />

                {!!pdfFiles?.length && (
                  <div style={{ marginTop: "16px" }}>
                    {pdfFiles.map((file) => (
                      <p key={file.name}>{file.name}</p>
                    ))}
                  </div>
                )}
              </div>
            </article>

            {!!uploadSuccess && (
              <article
                className="border light padding"
                style={{ marginTop: "16px" }}
              >
                <p>Document was uploaded successfully!</p>
              </article>
            )}

            <nav className="right-align no-space">
              <button
                className="transparent link"
                onClick={() => {
                  setUploadfileOpen(false);
                  setUploadSuccess(false);
                }}
              >
                Cancel
              </button>
              <button
                className="transparent link"
                onClick={uploadFiles}
                disabled={!pdfFiles.length || loading}
              >
                {loading ? "Uploading..." : "Upload files"}
              </button>
            </nav>
          </dialog>
        </>
      )}

      {UploadUrlOpen && (
        <>
          <div className="overlay blur"></div>
          <dialog
            className="auto blue-grey1"
            open={UploadUrlOpen}
            style={{
              display: "flex",
              flexDirection: "column",
              width: "80%",
              maxWidth: "600px",
              margin: "auto",
              padding: "16px",
              boxSizing: "border-box",
            }}
          >
            <text style={{ fontSize: "18px" }}>Upload URLs</text>
            <p style={{ color: "#919399" }}>
              Paste the Google Drive, Web links here
            </p>
            <div className="field textarea border">
              <textarea
                value={googleDocsLinks}
                placeholder="link1, link2, link3"
                onChange={(e) => setGoogleDocsLinks(e.target.value)}
                style={{ width: "100%", height: "100px" }}
              ></textarea>
            </div>

            {!!uploadSuccess && (
              <article
                className="border light padding"
                style={{ marginTop: "16px" }}
              >
                <p>Document was uploaded successfully!</p>
              </article>
            )}

            <nav className="right-align no-space">
              <button
                className="transparent link"
                onClick={() => {
                  setUploadUrlOpen(false);
                  setUploadSuccess(false);
                }}
              >
                Cancel
              </button>
              <button
                className="transparent link"
                onClick={uploadurl}
                disabled={!googleDocsLinks || loading}
              >
                {loading ? "Uploading..." : "Upload URLs"}
              </button>
            </nav>
          </dialog>
        </>
      )}

      <div
        style={{
          margin: "2rem",
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <text style={{ fontSize: "1.125rem" }}>Uploaded Files</text>
        <div
          className="scroll surface"
          style={{
            marginTop: "1rem",
            height: "calc(70vh - 3rem)",
            padding: "1rem",
            boxSizing: "border-box",
            overflowY: "auto",
            border: "1px solid #ddd",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <table
            className="border large-space"
            style={{
              width: "100%",
              borderCollapse: "collapse",
              tableLayout: "fixed",
            }}
          >
            <thead>
              <tr>
                <th>File Name</th>
                <th>View File</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {uploadedFiles.map((file) => (
                <tr key={file.id}>
                  <td>{file.name}</td>
                  <td>
                    {file.name && file.name.includes("http") ? (
                      <Anchor
                        href={file.name}
                        target="_blank"
                        className={classes.text}
                      >
                        {file.name}
                      </Anchor>
                    ) : (
                      <PdfViewer
                        pdfName={file.name}
                        store_id={file.store_id}
                        index={file.id}
                        className={classes.text}
                        storetype={file.storeType}
                      />
                    )}
                  </td>
                  <td>
                    <a
                      className="small chip action-icon"
                      onClick={() => handleDeleteFile(file.store_id, file.id)}
                    >
                      <i>delete</i>
                    </a>
                  </td>
                </tr>
              ))}

              {uploadedLinks.map((link) => (
                <tr key={link.id}>
                  <td>{link.url}</td>
                  <td>
                    <button
                      onClick={() => window.open(link.url, "_blank")}
                      style={{ cursor: "pointer" }}
                    >
                      View
                    </button>
                  </td>
                  <td>
                    <a
                      className="small chip action-icon"
                      onClick={() => handleDeleteLink(link.id)}
                    >
                      <i>delete</i>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <StoreLoaderPopup
        open={s3DialogOpen}
        onClose={() => setS3DialogOpen(false)}
        onSubmit={() => {
          addLoader("s3");
          setS3DialogOpen(false);
        }}
        title="Add S3 Bucket"
        value={s3BucketUrl}
        onValueChange={(e: any) => setS3BucketUrl(e.target.value)}
        placeholder="Enter S3 bucket URL"
        name={s3BucketName}
        onNameChange={(e: any) => setS3BucketName(e.target.value)}
        namePlaceholder="Enter S3 bucket name"
        buttonText="Add"
      />

      <StoreLoaderPopup
        open={gcpDialogOpen}
        onClose={() => setGcpDialogOpen(false)}
        onSubmit={() => {
          addLoader("gcp");
          setGcpDialogOpen(false);
        }}
        title="Add GCP Bucket"
        value={gcpBucketUrl}
        onValueChange={(e: any) => setGcpBucketUrl(e.target.value)}
        placeholder="Enter GCP bucket URL"
        name={gcpBucketName}
        onNameChange={(e: any) => setGcpBucketName(e.target.value)}
        namePlaceholder="Enter GCP bucket name"
        buttonText="Add"
      />
    </div>
  );
}
