import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  createAgent,
  getToolGroups,
  getTools,
  updateAgent,
} from "../lib/APIService";
import MultiSelect from "./material-design/components/MdMultiselect";

interface Tool {
  id: any;
  name: string;
  description?: string;
  iconSrc?: string;
  createdDate?: any;
  updatedDate?: any;
}

interface ToolGroup {
  id?: any;
  name: string;
  description: string;
  tools: Tool[];
}
export default function AgentsPopUp({
  popup,
  setPopup,
  onAddAgent,
  onEditAgent,
  editMode,
  currentAgent,
}: any) {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    instructions: "",
    tools: [],
    iconSrc: `https://api.dicebear.com/7.x/bottts/svg?seed=${uuidv4()}`,
    tool_groups: [],
  });
  const [toolsList, setToolsList] = useState<Tool[]>([]);
  const [toolGroups, setToolGroups] = useState<ToolGroup[]>([]);

  useEffect(() => {
    if (editMode && currentAgent) {
      const details = JSON.parse(currentAgent.details);
      setFormData({
        name: details.name,
        description: details.description,
        instructions: details.instructions,
        tools: details.tools,
        iconSrc: currentAgent.iconSrc,
        tool_groups: details.tool_groups,
      });
    }
  }, [editMode, currentAgent]);

  useEffect(() => {
    const getToolsList = async () => {
      const response = await getTools();
      const cleanedTools = response?.map((tool: Tool) => {
        const { createdDate, updatedDate, ...rest } = tool;
        return rest;
      });
      setToolsList(cleanedTools);
    };

    getToolsList();
  }, []);

  useEffect(() => {
    const getToolGroupsList = async () => {
      try {
        const userToolGroups = await getToolGroups("user");

        const systemToolGroups = await getToolGroups("system");

        const mergedToolGroups = [...userToolGroups, ...systemToolGroups];

        setToolGroups(mergedToolGroups);
      } catch (error) {
        console.log("Error fetching tool groups:", error);
      }
    };

    getToolGroupsList();
  }, []);

  const handleChange = (field: any, value: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleToolsChange = (
    field: keyof typeof formData,
    value: any,
    list: any[] = []
  ) => {
    const selectedTools = list?.filter((tool) => value.includes(tool.id));

    setFormData((prevState) => ({
      ...prevState,
      [field]: selectedTools,
    }));
  };

  const handleSubmit = async () => {
    const newAgent = {
      details: JSON.stringify({
        id: `asst_${Date.now()}`,
        name: formData.name,
        description: formData.description,
        model: "gpt-4o",
        instructions: formData.instructions,
        tools: formData.tools,
        tool_groups: formData.tool_groups,
      }),
      credential: "9e2a01e2-ceeb-4da2-81e4-8a70627f23b1",
      iconSrc: formData.iconSrc,
    };
    const response = await createAgent(newAgent);
    if (response) {
      onAddAgent(response);
      setPopup(false);
    }
  };

  return (
    <>
      <dialog
        open={popup}
        className="auto scroll modal large-padding surface"
        style={{
          width: "40%",
        }}
      >
        <div className="credential-header">
          <h5 className="small">{editMode ? "Edit Agent" : "Add Agent"}</h5>
          <button
            className="transparent square"
            onClick={() => setPopup(false)}
          >
            <i>close</i>
          </button>
        </div>

        <div className="top-padding">
          <div className="field border label">
            <input
              value={formData.name}
              onChange={(e) => handleChange("name", e.target.value)}
            />
            <label className="small-opacity">Name</label>
          </div>

          <div className="field textarea border label">
            <textarea
              value={formData.description}
              onChange={(e) => handleChange("description", e.target.value)}
            />

            <label className="small-opacity"> Description</label>
          </div>

          <div className="field textarea border label">
            <textarea
              value={formData.instructions}
              onChange={(e) => handleChange("instructions", e.target.value)}
            />
            <label className="small-opacity">Instruction</label>
          </div>

          <MultiSelect
            label="Tools"
            options={toolsList.map((tool: Tool) => ({
              label: tool.name,
              value: tool.id,
            }))}
            selectedValues={formData.tools.map((tool: Tool) => tool.id)}
            onChange={(value: any) =>
              handleToolsChange("tools", value, toolsList)
            }
          />

          <div className="field label suffix border">
            <select
              value={formData.tool_groups.map((group: ToolGroup) => group.id)}
              onChange={(e) => {
                const selectedIds = Array.from(
                  e.target.selectedOptions,
                  (option) => option.value
                );
                const selectedGroups = toolGroups.filter((group) =>
                  selectedIds.includes(group.id)
                );
                handleChange("tool_groups", selectedGroups);
              }}
            >
              {toolGroups.map((group: ToolGroup) => (
                <option key={group.id} value={group.id}>
                  {group.name}
                </option>
              ))}
            </select>
            <label>Tool Groups</label>
            <i>arrow_drop_down</i>
          </div>

          <div className="field border label">
            <input
              placeholder="Agent ICON SRC"
              value={formData.iconSrc}
              onChange={(e) => handleChange("iconSrc", e.target.value)}
            />
            <label>Icon Src</label>
          </div>
        </div>

        <div className="flex right-align">
          <button onClick={handleSubmit} disabled={!formData.name}>
            Add
          </button>
        </div>
      </dialog>
    </>
  );
}
