import React from "react";

interface CardProps {
  id?: string;
  name: string;
  description?: string;
  type?: string;
  subtype?:string
  iconSrc?: string;
  onClick?: () => void;
  children?: React.ReactNode;
  align?: "center" | "left";
}

const Card: React.FC<CardProps> = ({
  id,
  name,
  description,
  type,
  subtype,
  onClick,
  children,
  iconSrc,
  align = "left",
}) => {
  const isCenterAligned = align === "center";
  return (
    <div className="margin" style={{ width: "280px", position: "relative" }}>
      <article
        className="surface"
        style={{
          height: isCenterAligned ? "180px" : "140px",
          cursor: "pointer",
          position: isCenterAligned ? "relative" : undefined,
        }}
        key={id}
        onClick={onClick}
      >
        <div className={isCenterAligned ? "column center-align" : "row"}>
          {iconSrc ? (
            <img className="large" src={iconSrc} alt="Agent Icon" />
          ) : isCenterAligned ? (
            <div
              className="large"
              style={{
                visibility: "hidden",
                minWidth: "48px",
                height: "48px",
              }}
            />
          ) : null}
          <div className="max" style={{ marginTop: "8px"}}>
            <h6
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 1,
                WebkitBoxOrient: "vertical",
                textAlign: isCenterAligned ? "center" : "left",
                width:isCenterAligned ? "" : "90%"
              }}
              className="small"
            >
              {name}
            </h6>
            <p
              style={{
                whiteSpace: "normal",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                fontSize: "0.78rem",
                textAlign: isCenterAligned ? "center" : "left",
                marginTop: isCenterAligned ? "8px" : "0.5rem",
                lineHeight: "1.5",
                width:isCenterAligned ? "" : "90%"
              }}
            >
              {description}
            </p>
          </div>
        </div>
        {children}
        {type && (
          <a
            className="chip fill"
            style={{
              position: "absolute",
              bottom: "8px",
              left: "8px",
              fontSize: "10px",
              padding: "2px 8px",
              minHeight: "20px",
              lineHeight: "1",
            }}
          >
            {type}
          </a>
        )}
        {type === "system" && subtype && (
          <a
            className="chip fill"
            style={{
              position: "absolute",
              bottom: "8px",
              left: "calc(8px + 60px)", 
              fontSize: "10px",
              padding: "2px 8px",
              minHeight: "20px",
              lineHeight: "1",
            }}
          >
            {subtype}
          </a>
        )}
      </article>
    </div>
  );
};

export default Card;
